import { Box, IconButton, Link, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchCondition, { toQueryString } from "../Types/SearchCondition";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SearchConditionEditor } from "./SearchConditionEditor";
import TuneIcon from "@mui/icons-material/Tune";
import { maxios } from "../MaxiosProvider";
import GenreGetResponseModel from "../Types/GenreGetResponseModel";
import SelectorItem from "../Types/SelectorItem";
import Area from "../Types/Area";
import { DeletableChip } from "../Components/DeletableChip";
import MatchoStyle from "../Types/MatchoStyle";
import { getMatchoTypeName } from "../Types/MatchoType";
import { useNavigate } from "react-router-dom";

type Props = {
	searchCondition: SearchCondition;
};

export const SearchConditionView: React.FC<Props> = (props) => {
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [areas, setAreas] = useState<SelectorItem[]>([]);
	const [genres, setGenres] = useState<SelectorItem[]>([]);

	useEffect(() => {
		let promises: Promise<void>[] = [];
		let genres: SelectorItem[] = [];

		props.searchCondition.genreIds?.forEach((genreId) => {
			promises.push(
				maxios.get<GenreGetResponseModel>(`/genres/${genreId}`).then((res) => {
					genres.push({ value: res.data.id, label: res.data.name });
				})
			);
		});

		Promise.all(promises).then(() => {
			setGenres(genres);
		});
	}, [props.searchCondition.genreIds]);

	useEffect(() => {
		let promises: Promise<void>[] = [];
		let areas: SelectorItem[] = [];

		props.searchCondition.areaIds?.forEach((areaId) => {
			promises.push(
				maxios.get<Area>(`/areas/${areaId}`).then((res) => {
					areas.push({ value: res.data.id, label: res.data.name });
				})
			);
		});

		Promise.all(promises).then(() => {
			setAreas(areas);
		});
	}, [props.searchCondition.areaIds]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClickClose = () => {
		setOpen(false);
	};

	const handleDeleteSearchWord = () => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				...props.searchCondition,
				searchWord: undefined,
			}),
		});
	};

	const handleDeleteMatchoType = () => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				...props.searchCondition,
				type: undefined,
			}),
		});
	};

	const handleDeleteArea = (areaId: string) => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				...props.searchCondition,
				areaIds: areas.filter((a) => a.value !== areaId).map((a) => a.value),
			}),
		});
	};

	const handleDeleteGenre = (genreId: string) => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				...props.searchCondition,
				genreIds: genres.filter((g) => g.value !== genreId).map((g) => g.value),
			}),
		});
	};

	const handleDeleteOnline = () => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				...props.searchCondition,
				online: undefined,
			}),
		});
	};

	const handleDeleteOnsite = () => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				...props.searchCondition,
				onsite: undefined,
			}),
		});
	};

	const handleDeleteFeePerOnce = () => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				...props.searchCondition,
				feePerOnceLowerLimit: undefined,
				feePerOnceUpperLimit: undefined,
			}),
		});
	};

	const handleDeleteFeePerMonth = () => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				...props.searchCondition,
				feePerMonthLowerLimit: undefined,
				feePerMonthUpperLimit: undefined,
			}),
		});
	};

	const handleDeleteTrialFee = () => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				...props.searchCondition,
				trialFeeLowerLimit: undefined,
				trialFeeUpperLimit: undefined,
			}),
		});
	};

	const createFeeText = (lowerValue: number | undefined, upperValue: number | undefined) => {
		let lowerFeeText = lowerValue === undefined ? "" : `￥${lowerValue}`;
		let upperFeeText = upperValue === undefined ? "" : `￥${upperValue}`;
		return `${lowerFeeText}～${upperFeeText}`;
	};

	return (
		<Paper
			variant="outlined"
			sx={{ textAlign: "center", p: "20px", mb: "20px", borderRadius: "16px" }}
		>
			<Box sx={{ display: "inline-block" }}>
				<Link
					underline="none"
					onClick={handleClickOpen}
					sx={{
						":hover": {
							cursor: "pointer",
						},
					}}
				>
					<Typography
						sx={{ display: "flex", fontWeight: "bold", color: MatchoStyle.color.matchoPink }}
					>
						<TuneIcon />
						検索条件
					</Typography>
				</Link>
			</Box>
			<Box display="flex" flexWrap="wrap" justifyContent="center">
				{/* TODO: DeletableChipsにstringを直接渡せるようにする */}
				<DeletableChip
					variant="outlined"
					item={
						props.searchCondition.searchWord
							? { label: props.searchCondition.searchWord, value: "" }
							: undefined
					}
					onDelete={handleDeleteSearchWord}
				/>
				{/* TODO: MatchoTypeをenumからオブジェクト等に変更する */}
				<DeletableChip
					variant="outlined"
					item={
						props.searchCondition.type !== undefined
							? { label: getMatchoTypeName(props.searchCondition.type), value: "" }
							: undefined
					}
					onDelete={handleDeleteMatchoType}
				/>
				{areas.map((area, index) => (
					<DeletableChip variant="outlined" item={area} onDelete={handleDeleteArea} key={index} />
				))}
				{genres.map((genre, index) => (
					<DeletableChip variant="outlined" item={genre} onDelete={handleDeleteGenre} key={index} />
				))}
				<DeletableChip
					variant="outlined"
					item={props.searchCondition.online ? { label: "オンライン", value: "" } : undefined}
					onDelete={handleDeleteOnline}
				/>
				<DeletableChip
					variant="outlined"
					item={props.searchCondition.onsite ? { label: "出張", value: "" } : undefined}
					onDelete={handleDeleteOnsite}
				/>
				<DeletableChip
					variant="outlined"
					item={
						props.searchCondition.feePerOnceLowerLimit !== undefined ||
						props.searchCondition.feePerOnceUpperLimit !== undefined
							? {
									label: `1回:${createFeeText(
										props.searchCondition.feePerOnceLowerLimit,
										props.searchCondition.feePerOnceUpperLimit
									)}`,
									value: "",
							  }
							: undefined
					}
					onDelete={handleDeleteFeePerOnce}
				/>
				<DeletableChip
					variant="outlined"
					item={
						props.searchCondition.feePerMonthLowerLimit !== undefined ||
						props.searchCondition.feePerMonthUpperLimit !== undefined
							? {
									label: `1ヶ月:${createFeeText(
										props.searchCondition.feePerMonthLowerLimit,
										props.searchCondition.feePerMonthUpperLimit
									)}`,
									value: "",
							  }
							: undefined
					}
					onDelete={handleDeleteFeePerMonth}
				/>
				<DeletableChip
					variant="outlined"
					item={
						props.searchCondition.trialFeeLowerLimit !== undefined ||
						props.searchCondition.trialFeeUpperLimit !== undefined
							? {
									label: `体験:${createFeeText(
										props.searchCondition.trialFeeLowerLimit,
										props.searchCondition.trialFeeUpperLimit
									)}`,
									value: "",
							  }
							: undefined
					}
					onDelete={handleDeleteTrialFee}
				/>
			</Box>
			<Box sx={{ position: "relative" }}>
				<Box
					sx={{
						textAlign: "center",
						position: "absolute",
						left: 0,
						right: 0,
						margin: "auto",
						top: 6,
					}}
				>
					<IconButton
						onClick={handleClickOpen}
						size="small"
						sx={{
							border: "2px solid",
							color: "lightgray",
							backgroundColor: "white",
							padding: 0,
							">svg": { color: "gray" },
							":hover": { backgroundColor: "lightgray" },
						}}
					>
						<KeyboardArrowDownIcon />
					</IconButton>
				</Box>
			</Box>
			<SearchConditionEditor
				searchCondition={props.searchCondition}
				open={open}
				onClose={handleClickClose}
			/>
		</Paper>
	);
};
