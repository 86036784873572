import { Grid } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useEffect, useState } from "react";
import GenreGetResponseModel from "../Types/GenreGetResponseModel";
import { HorizonalCard } from "../Atoms/HorizonalCard";
import { getGenreImageUrl } from "../Utilty";

type Props = {
	onClick?: (genreId: string) => void;
};

const GenreButtons: React.FC<Props> = (props) => {
	const [items, setItems] = useState<GenreGetResponseModel[]>([]);

	useEffect(() => {
		let ignore = false;

		maxios.get<GenreGetResponseModel[]>("/Genres").then((res) => {
			if (!ignore) setItems(res.data);
		});

		return () => {
			ignore = true;
		};
	}, []);

	return (
		<Grid container spacing={1}>
			{items.map((item, index) => {
				return (
					<Grid item key={index} xs={6} sm={4}>
						<HorizonalCard
							label={item.name}
							image={getGenreImageUrl(item.image)}
							onClick={() => {
								if (props.onClick) props.onClick(item.id);
							}}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default GenreButtons;
