import React, { useState, useEffect, useRef } from "react";
import {
	User,
	getAuth,
	signOut as fbSignOut,
	deleteUser,
	reauthenticateWithCredential,
	EmailAuthProvider,
	sendPasswordResetEmail,
	GoogleAuthProvider,
	signInWithPopup,
	signInWithEmailAndPassword,
	UserCredential,
	reauthenticateWithPopup,
	OAuthProvider,
} from "firebase/auth";
import { MatchoUser } from "./Types/MatchoUser";
import { RegistrationStatus } from "./Types/RegistrationStatus";
import axios from "axios";
import { maxios } from "./MaxiosProvider";
import { ApiResponse } from "./Types/ApiResponse";
import { ApiResponseStatus } from "./Types/ApiResponseStatus";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Form } from "./Components/Form/Form";
import { FormTextInput } from "./Components/Form/FormTextInput";
import { LongTimeProcessingButton } from "./Components/LongTimeProcessingButton";
import { Validator } from "./Types/Validator";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase";

type IAuthContext = {
	user: User | null;
	claim: MatchoUser | null;
	name: string | undefined;
	registrationStatus: RegistrationStatus;
	refresh: () => Promise<{
		matchoId: string | undefined;
		registrationStatus: RegistrationStatus | undefined;
	}>;
	signInWithEmail: (
		email: string,
		password: string
	) => Promise<{
		isError: boolean;
		message?: string;
	}>;
	signInWithGoogle: () => Promise<UserCredential | undefined>;
	signOut: () => void;
	getIdToken: () => Promise<string> | undefined;
	withdrawal: () => void;
	submitPasswordResetEmail: (email: string) => Promise<void>;
	isReady: boolean;
	isSignedIn: boolean;
};

export const AuthContext = React.createContext<IAuthContext>({
	user: null,
	claim: null,
	name: undefined,
	registrationStatus: RegistrationStatus.Unregistered,
	refresh: undefined!,
	signInWithGoogle: undefined!,
	signInWithEmail: undefined!,
	signOut: undefined!,
	getIdToken: undefined!,
	withdrawal: undefined!,
	submitPasswordResetEmail: undefined!,
	isReady: false,
	isSignedIn: false,
});

export const AuthProvider = (props: any) => {
	const navigate = useNavigate();

	const [matcho, setMatcho] = useState<{
		user: User | null;
		claim: MatchoUser | null;
	}>({ user: null, claim: null });

	const [isReadyForFirebase, setIsReadyForFirebase] = useState(false);

	const ref = useRef<Validator>(null);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [openWithdrawal, setOpenWithdrawal] = useState(false);
	const [password, setPassword] = useState("");

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	const navigatedTopRef = useRef(false);

	useEffect(() => {
		// https://stackoverflow.com/questions/72574943/are-you-supposed-to-pass-the-firebase-app-to-getauth-or-leave-the-arguments-as
		initializeApp(firebaseConfig);

		// Firebase Authのメソッド。ログイン状態が変化すると呼び出される
		getAuth().onAuthStateChanged((user) => {
			constructMatcho(user).then((context) => {
				// navigateで移動するタイミングはコールされた瞬間ではなくnavigateをコールしている関数の終了時。
				// 複数回コールされている場合は後勝ちになる。
				if (
					context?.registrationStatus !== undefined &&
					context?.registrationStatus !== RegistrationStatus.Completed
				) {
					// 登録状況が中途半端な時はユーザ登録画面に遷移します。
					navigate("/registration");
				} else {
					if (navigatedTopRef.current) navigate("/");
				}

				navigatedTopRef.current = false;

				// navigateよりも後ろに記述しないとMapが描画されてmemory leakの警告が出ます。
				setIsReadyForFirebase(true);
			});
		});
		// https://qiita.com/asa1084/items/c39e2d2472b992383f50
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const constructMatcho = (user: User | null) => {
		if (user) {
			return user.getIdToken(true).then((token) => {
				return axios
					.get<MatchoUser>(`${process.env.REACT_APP_API_URL}/Users/me`, {
						headers: { Authorization: "bearer " + token },
					})
					.then((resUser) => {
						setMatcho({ user: user, claim: resUser.data });
						return {
							matchoId: resUser.data.matchoId,
							registrationStatus: judgeRegistrationStatus({ user: user, claim: resUser.data }),
						};
					});
			});
		} else {
			return (async () => setMatcho({ user: null, claim: null }))().then(() => {
				return {
					matchoId: undefined,
					registrationStatus: undefined,
				};
			});
		}
	};

	const signInWithEmail = (email: string, password: string) => {
		return signInWithEmailAndPassword(getAuth(), email, password)
			.then(() => {
				navigatedTopRef.current = true;
				return { isError: false, message: undefined };
			})
			.catch(() => {
				return { isError: true, message: "Emailまたはパスワードが違います。" };
			});
	};

	const signInWithGoogle = () => {
		// https://firebase.google.com/docs/auth/web/google-signin?hl=ja
		const provider = new GoogleAuthProvider();
		const auth = getAuth();

		return signInWithPopup(auth, provider)
			.then((result) => {
				// Matchoユーザの登録有無に関わらずトップに遷移させます。
				// Firebaseのログイン状態変更イベントでMatchoユーザの登録画面に遷移します。
				// ログイン状態変更イベントによる遷移とトップへの遷移のどちらが優先されるか怪しいですが、
				// 動かしてテストした結果、ログイン状態変更イベントが後勝ちするのでいいことにします。
				navigatedTopRef.current = true;
				return result;
			})
			.catch((error) => {
				// TODO：ログ送信
				if (error.code === "auth/popup-closed-by-user") {
					return undefined;
				} else {
					throw new Error("Google認証で予期しないエラーが発生しました。");
				}
			});
	};

	const refresh = () => {
		let auth = getAuth();
		return constructMatcho(auth.currentUser);
	};

	const signOut = () => {
		navigatedTopRef.current = true;
		fbSignOut(getAuth());
	};

	const getIdToken = () => {
		// https://zenn.dev/natuuu0831/articles/a356be3533d299
		return getAuth().currentUser?.getIdToken();
	};

	const withdrawal = () => {
		setOpenConfirm(true);
	};

	const handleClickYes = async () => {
		let result: { isError: boolean; message?: string };

		if (matcho.user) {
			if (getAuthMethodHighPriority() === "password") {
				setOpenWithdrawal(true);
				result = { isError: false };
			} else {
				result = await deleteMatchoWithGoogle();
			}
		} else {
			result = { isError: false, message: "ユーザーが存在しません。" };
		}

		if (!result.isError) setOpenConfirm(false);
		return result;
	};

	const handleClickWithdrawal = async () => {
		const result = await deleteMatchoWithPassword();
		if (!result.isError) setOpenWithdrawal(false);
		return result;
	};

	const handleClickNo = () => {
		setOpenConfirm(false);
	};

	const handleClickCancel = () => {
		setOpenWithdrawal(false);
	};

	const deleteMatchoWithPassword = async () => {
		if (!ref.current?.validate()) {
			return (async () => {
				return { isError: true };
			})();
		}

		try {
			if (!matcho.user?.email) throw new Error("メールアドレスが取得できません。");
			const credential = EmailAuthProvider.credential(matcho.user.email, password);

			// 削除の直前に再認証する必要があります。
			await reauthenticateWithCredential(matcho.user, credential).catch((error: any) => {
				if (error.code === "auth/wrong-password") {
					throw new Error("パスワードが間違っています。");
				} else if (error.code === "auth/too-many-requests") {
					throw new Error("しばらく時間をおいてからお試しください。");
				} else {
					throw new Error("予期しないエラーが発生しました。");
				}
			});

			await deleteMatcho(matcho.user);

			return { isError: false };
		} catch (e: any) {
			return { isError: true, message: e.message };
		}
	};

	const deleteMatchoWithGoogle = async () => {
		try {
			if (!matcho.user) throw new Error("ユーザーが取得できません。");

			// 削除の直前に再認証する必要があります。
			// https://cloud.google.com/identity-platform/docs/web/reauth?hl=ja
			const provider = new OAuthProvider("google.com");
			await reauthenticateWithPopup(matcho.user, provider).catch((error: any) => {
				if (error.code === "auth/user-mismatch") {
					throw new Error("アカウントが違います。");
				} else {
					throw new Error("予期しないエラーが発生しました。");
				}
			});

			await deleteMatcho(matcho.user);

			return { isError: false };
		} catch (e: any) {
			return { isError: true, message: e.message };
		}
	};

	const deleteMatcho = async (user: User) => {
		// ユーザーよりも先にMatchoデータを削除しないと認証エラーになります。
		await maxios.delete<ApiResponse>(`/users/${user!.uid}`).then((res) => {
			if (res.data.status === ApiResponseStatus.Error) {
				throw new Error("予期しないエラーが発生しました。");
			}
		});

		await deleteUser(user!).then(() => {
			setMatcho({ user: null, claim: null });
		});
	};

	const submitPasswordResetEmail = async (email: string) => {
		return await sendPasswordResetEmail(getAuth(), email).catch((error) => {
			if (error.code === "auth/user-not-found") {
				throw new Error("メールアドレスが間違っています。");
			} else {
				throw new Error("予期しないエラーが発生しました。");
			}
		});
	};

	const getAuthMethodHighPriority = () => {
		if (matcho.user?.providerData.find((p) => p.providerId === "google.com")) {
			return "google";
		} else {
			return "password";
		}
	};

	const judgeRegistrationStatus = (matcho: { user: User | null; claim: MatchoUser | null }) => {
		return matcho.user && !matcho.user.emailVerified
			? RegistrationStatus.EmailUnverified
			: matcho.user && !matcho.claim
			? RegistrationStatus.MatchoUserUnregstered
			: matcho.user && matcho.claim?.id && !matcho.claim?.matchoId
			? RegistrationStatus.MatchoUnregstered
			: matcho.user && matcho.claim?.id && matcho.claim?.matchoId
			? RegistrationStatus.Completed
			: RegistrationStatus.Unregistered;
	};

	return (
		<AuthContext.Provider
			value={{
				user: matcho.user,
				claim: matcho.claim,
				name: matcho.claim?.name,
				registrationStatus: judgeRegistrationStatus(matcho),
				refresh: refresh,
				signInWithEmail: signInWithEmail,
				signInWithGoogle: signInWithGoogle,
				signOut: signOut,
				getIdToken: getIdToken,
				withdrawal: withdrawal,
				submitPasswordResetEmail: submitPasswordResetEmail,
				isReady: isReadyForFirebase,
				isSignedIn: matcho.user ? true : false,
			}}
		>
			{isReadyForFirebase && props.children}
			<Dialog open={openConfirm} onClose={handleClickNo}>
				<DialogContent>
					<DialogContentText>本当に退会しますか？</DialogContentText>
				</DialogContent>
				<DialogActions>
					<LongTimeProcessingButton
						caption="はい"
						onClick={handleClickYes}
						variant="contained"
						sx={{ mt: 1 }}
					/>
					<Button onClick={handleClickNo}>いいえ</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={openWithdrawal} onClose={handleClickCancel}>
				<DialogContent>
					<DialogContentText>パスワードを入力し退会処理を完了させてください。</DialogContentText>
					<Form ref={ref}>
						<FormTextInput
							label=""
							name="password"
							type="password"
							value={password}
							onChange={handleChange}
							sx={{ mt: 1 }}
							minLength={6}
							required
							invisibleCaption
							placeholder="Password"
							variant="dialog"
						/>
					</Form>
				</DialogContent>
				<DialogActions>
					<LongTimeProcessingButton
						caption="退会"
						onClick={handleClickWithdrawal}
						variant="contained"
						sx={{ mt: 1 }}
					/>
					<Button onClick={handleClickCancel}>キャンセル</Button>
				</DialogActions>
			</Dialog>
		</AuthContext.Provider>
	);
};
