import { FormControl, FormControlLabel, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { getCastedValue } from "../Utilty";
import { BpRadio } from "./BpRadio";

type Props = {
	onChange?: (value?: any) => void;
	onBlur?: () => void;
	name?: string;
	disabled?: boolean;
	items?: { label: string; value: any }[];
	value?: any;
};

export const RadioButtons: React.FC<Props> = (props) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!props.onChange) return;

        let value = e.target.value === "no value" ? undefined : getCastedValue(e);
		props.onChange(value);
	};

	return (
		<FormControl>
			<RadioGroup
				row
				name={props.name}
				onChange={handleChange}
				onBlur={props.onBlur}
				value={props.value ?? "no value"}
			>
				{props.items?.map((item, index) => (
					<FormControlLabel
						key={index}
						value={item.value ?? "no value"}
						control={<BpRadio />}
						disabled={props.disabled}
						label={<Typography variant="caption">{item.label}</Typography>}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};
