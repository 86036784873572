import { Paper, SxProps, Theme } from "@mui/material";
import React from "react";
import { GymType } from "../../Types/GymType";
import { MatchoType } from "../../Types/MatchoType";
import { TrainerType } from "../../Types/TrainerType";
import { Card } from "./Card";

type Props = {
	type: MatchoType;
	matchoSubType?: TrainerType | GymType;
	image?: string;
	name?: string;
	handleClick: () => void;
	size?: "large" | "medium" | "small";
	sx?: SxProps<Theme>;
};

export const ClickableCard: React.FC<Props> = (props) => {
	return (
		<Paper
			onClick={props.handleClick}
			sx={{
				...props.sx,
				pt: 1,
				pb: 1,
				":hover": {
					bgcolor: "#eeeeee",
					cursor: "pointer",
				},
				borderRadius: "16px",
			}}
			elevation={0}
		>
			<Card
				type={props.type}
				matchoSubType={props.matchoSubType}
				image={props.image}
				name={props.name}
				size={props.size}
				sx={{ p: 1 }}
			>
				{props.children}
			</Card>
		</Paper>
	);
};

ClickableCard.defaultProps = {
	size: "medium",
};
