import { Box, SxProps, Theme, Typography } from "@mui/material";
import React, { forwardRef, ReactNode, useCallback, useImperativeHandle, useState } from "react";
import { Notifier } from "../../Types/Notifier";
import { TableItem } from "../TableItem";

type Props = {
	label: string;
	children: ReactNode;
	sx?: SxProps<Theme>;
	bodyPadding?: number | string;
	border?: boolean;
	invisibleCaption?: boolean;
	variant?: "dialog" | "page";
	captionWidth?: number | string;
};

const FormItemRef: React.ForwardRefRenderFunction<Notifier, Props> = (props, ref) => {
	const [errorMessage, setErrorMessage] = useState("");
	const [displayMessage, setDisplayMessage] = useState(true);

	const blink = useCallback(() => {
		setDisplayMessage(false);
		setTimeout(() => {
			setDisplayMessage(true);
		}, 1000);
	}, []);

	// 親から呼ばれる関数
	useImperativeHandle(
		ref,
		() => ({
			setErrorMessage: setErrorMessage,
			blink: blink,
		}),
		[setErrorMessage, blink]
	);

	const createBodyElement = () => {
		return (
			<>
				<Box sx={props.sx}>{props.children}</Box>
				<Typography
					color="error"
					variant="body2"
					sx={{ transition: "1s", opacity: displayMessage ? 1 : 0 }}
				>
					{errorMessage}
				</Typography>
			</>
		);
	};

	return (
		<TableItem
			label={props.label}
			bodyPadding={props.bodyPadding}
			border={props.border}
			variant={props.variant}
			invisibleCaption={props.invisibleCaption}
			captionWidth={props.captionWidth}
		>
			{createBodyElement()}
		</TableItem>
	);
};

export const FormItem = forwardRef(FormItemRef);
