import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { getAuth, sendEmailVerification, User } from "firebase/auth";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";

type Props = {
	onCompleted: () => void;
};

const EmailVerification: React.FC<Props> = (props) => {
	const [user, setUser] = useState<User | null>(null);

	useEffect(() => {
		let user = getAuth().currentUser;
		if (!user) throw new Error("認証されていません。");

		sendEmailVerification(user!).catch(() => {
			// too-many-requestsが発生する可能性があるが無視
		});

		setUser(user);
	}, []);

	const handleClickNext = () => {
		return user!.reload().then(() => {
			if (user!.emailVerified) {
				return { isError: false };
			} else {
				return { isError: true, message: "メールアドレスが有効化されていません。" };
			}
		});
	};

	const handleClickResend = () => {
		return sendEmailVerification(user!)
			.then(() => {
				return { isError: false, message: "メールを送信しました。" };
			})
			.catch(() => {
				return { isError: true, message: "しばらく時間をおいてもう一度お試しください。" };
			});
	};
	return (
		<Box textAlign="center">
			<Box display="inline-flex" flexDirection="column" textAlign="initial">
				<Typography display="inline-block" variant="body2">
					{user?.email}にメールを送信しました。
					<br />
					メール内のリンクをクリックしメールアドレスを有効化してください。
					<br />
					<br />
					メールアドレスを有効化したら次に進みます。
					<br />
					<br />
					メールが届かない場合、フィルタされていたり迷惑メールに振り分けられている可能性があります。
					<br />
					「@matcho.jp」を許可するようメール設定の変更をご検討ください。
				</Typography>
				<Box mt={3}>
					<LongTimeProcessingButton
						caption="次へ"
						onClick={handleClickNext}
						callback={props.onCompleted}
						variant="contained"
						sx={{ mr: 1 }}
					/>
					<LongTimeProcessingButton
						caption="メールを再送"
						onClick={handleClickResend}
						// message={message}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default EmailVerification;
