import { Link, Typography } from "@mui/material";
import React from "react";
import line from "../Resources/line.svg";
import { LineUrl } from "../Resources/Strings";

type Props = {
	lineId?: string;
};

export const LineInformation: React.FC<Props> = (props) => {
	const url = `${LineUrl}${props.lineId}`;

	return (
		<Typography variant="body2" sx={{ display: "flex", alignItems: "center", mr: 4 }}>
			<Link
				target={"_blank"}
				rel="noopener noreferrer"
				href={encodeURI(url)}
			>
				{url}
			</Link>
			<img src={line} alt={"line icon"} style={{ height: 25, width: 25, marginLeft: 5 }} />
		</Typography>
	);
};
