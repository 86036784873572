import { Card, CardMedia, CardContent, Box, Typography } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, {  } from "react";
import { Youtube } from "../Types/YouTube";
import youTubeIcon from "../Resources/youtube.svg";

type Props = {
	posts?: Youtube[];
};

export const YouTubeGallery: React.FC<Props> = (props) => {
	return (
		<Splide
			options={{
				perMove: 1,
				gap: "1rem",
				rewind: false,
				pagination: false,
				fixedWidth: 300,
				speed: 500,
			}}
		>
			{props.posts?.map((post, index) => {
				return (
					<SplideSlide key={index}>
						{/* Cardのボーダーは影のためmbで隙間を入れないと境界がなくなる */}
						<Card sx={{ width: 300, mb: "2px" }}>
							<CardMedia
								component={"iframe"}
								allowFullScreen
								image={`https://www.youtube-nocookie.com/embed/${post.video}`}
							/>
							<CardContent>
								<Box display={"flex"} justifyContent={"space-between"}>
									<Box>
										<Typography variant="body2" fontWeight={600}>
											{post.title}
										</Typography>
									</Box>
									<Box>
										<a
											href={`https://www.youtube.com/watch?v=${post.video}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												src={youTubeIcon}
												alt={"youTubeIcon"}
												style={{ height: 30, width: 30 }}
											/>
										</a>
									</Box>
								</Box>
							</CardContent>
						</Card>
					</SplideSlide>
				);
			})}
		</Splide>
	);
};
