import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useEffect, useState } from "react";
import GenreGetResponseModel from "../Types/GenreGetResponseModel";
import SelectorItem from "../Types/SelectorItem";

type Props = {
	values?: string[];
	onChange: (values: string[]) => void;
};

export const GenreCheckboxes: React.FC<Props> = ({ onChange, ...props }) => {
	const [values, setValues] = useState(props.values ?? []);
	const [items, setItems] = useState<SelectorItem[]>([]);

	useEffect(() => {
		maxios.get<GenreGetResponseModel[]>("/Genres").then((res) => {
			let items = res.data.map((item) => {
				return {
					value: item.id,
					label: item.name,
				};
			});

			setItems(items);
		});
	}, []);

	const isIncludedInValues = (target: string) => {
		let found = values.find((value) => value === target);
		return found ? true : false;
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		let found = values.find((value) => value === event.target.name);

		if (checked) {
			if (!found) {
				let cloned = values.slice();
				cloned.push(event.target.name);
				setValues(cloned);
			}
		} else {
			if (found) {
				let clonedValues = values.slice();
				let index = clonedValues.findIndex((value) => value === found);
				clonedValues.splice(index, 1);
				setValues(clonedValues);
			}
		}
	};

	useEffect(() => {
		onChange(values);
	}, [values, onChange]);

	return (
		<FormGroup>
			<Grid container>
				{items.map((item, index) => {
					return (
						<Grid item md={4} sm={6} xs={12} key={index}>
							<FormControlLabel
								control={
									<Checkbox
										checked={isIncludedInValues(item.value)}
										onChange={handleChange}
										name={item.value}
										sx={{ py: 0.5 }}
									/>
								}
								label={<Typography variant="caption">{item.label}</Typography>}
							/>
						</Grid>
					);
				})}
			</Grid>
		</FormGroup>
	);
};
