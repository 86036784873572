import { Box } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormTextInput } from "./Form/FormTextInput";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";
import { Form } from "./Form/Form";
import { GymRegistrationModel } from "../Types/GymRegistrationModel";
import { GymStaff } from "../Types/GymStaff";
import { getCastedValue } from "../Utilty";
import { FormImageSelector } from "./Form/FormImageSelector";
import { FormGenreSelector } from "./Form/FormGenreSelector";
import { Validator } from "../Types/Validator";
import { FormAreaSelector } from "./Form/FormAreaSelector";
import { FormMultipleImageSelector } from "./Form/FormMultipleImageSelector";
import { FormGymStaff } from "./Form/FormGymStaff";
import { FormRange } from "./Form/FormRange";
import { GymResponseModel, toGymRegistrationModel } from "../Types/GymResponseModel";
import { GymType } from "../Types/GymType";
import { FormGymTypeRadioButton } from "./Form/FormGymTypeRadioButton";
import { FormCheckbox } from "./Form/FormCheckbox";
import { LineUrl } from "../Resources/Strings";

type Props = {
	mode: "new" | "edit";
	id?: string;
};

// フォームモデル⇒ポストモデルの誤差を埋めるために一部プロパティの型を上書きします。
// https://zenn.dev/fuqda/articles/4aba53fbb3d95b
interface GymFormModel extends Omit<GymRegistrationModel, "gymType"> {
	gymType: GymType | undefined;
}

export const GymForm: React.FC<Props> = (props) => {
	const navigate = useNavigate();
	const maxNumberOfGalleryImage = 10;
	const [gym, setGym] = useState<GymFormModel>({
		id: "",
		ownerId: "",
		gymType: undefined,
		name: "",
		image: undefined,
		areaId: "",
		town: "",
		phoneNumber: "",
		introduction: "",
		genreIds: [],
		gallery: [],
		service: "",
		online: false,
		onsite: false,
		feePerOnceLowerLimit: undefined,
		feePerOnceUpperLimit: undefined,
		feePerMonthLowerLimit: undefined,
		feePerMonthUpperLimit: undefined,
		trialFeeLowerLimit: undefined,
		trialFeeUpperLimit: undefined,
		station: "",
		businessHours: "",
		facebook: "",
		twitter: "",
		instagram: "",
		website: "",
		youTube: "",
		line: "",
		staffs: [],
	});
	const [gallery, setGallery] = useState<(string | undefined)[]>([
		...Array(maxNumberOfGalleryImage),
	]);
	const [loaded, setLoaded] = useState(false);
	const ref = useRef<Validator>(null);

	const updateImage = (base64: string) => {
		setGym({ ...gym, image: base64 });
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setGym({ ...gym, [event.target.name]: getCastedValue(event) });
	};

	const handleChangeAreaId = (areaIds: string[]) => {
		if (areaIds.length >= 2) throw new Error("エリアIDは0または1つである必要があります");
		setGym({ ...gym, areaId: areaIds.length === 0 ? "" : areaIds[0] });
	};

	const handleChangeGenres = (genreIds: string[]) => {
		setGym({ ...gym, genreIds: genreIds });
	};

	const handleChangeStaffs = (staffs: GymStaff[]) => {
		setGym({ ...gym, staffs: staffs });
	};

	const handleChangeFeePerOnce = (
		lowerValue: number | undefined,
		upperValue: number | undefined
	) => {
		setGym({ ...gym, feePerOnceLowerLimit: lowerValue, feePerOnceUpperLimit: upperValue });
	};

	const handleChangeFeePerMonth = (
		lowerValue: number | undefined,
		upperValue: number | undefined
	) => {
		setGym({ ...gym, feePerMonthLowerLimit: lowerValue, feePerMonthUpperLimit: upperValue });
	};

	const handleChangeTrialFee = (lowerValue: number | undefined, upperValue: number | undefined) => {
		setGym({ ...gym, trialFeeLowerLimit: lowerValue, trialFeeUpperLimit: upperValue });
	};

	const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		setGym({ ...gym, [event.target.name]: checked });
	};

	const updateGallery = (index: number, base64: string) => {
		setGallery(gallery.map((image, currentIndex) => (currentIndex === index ? base64 : image)));
	};

	const removeFromGallery = (index: number) => {
		// ギャラリーの配列は常に10個である必要があるため要素を削除してはいけません。
		setGallery(gallery.map((image, currentIndex) => (currentIndex === index ? undefined : image)));
	};

	useEffect(() => {
		if (props.mode === "new") {
			setLoaded(true);
		} else if (props.mode === "edit") {
			maxios.get<GymResponseModel>(`/gymowners/me/gyms/${props.id}`).then((res) => {
				if (res.data) {
					setGym(toGymRegistrationModel(res.data));

					setGallery(
						res.data.gallery.concat([...Array(maxNumberOfGalleryImage - res.data.gallery.length)])
					); // 要素数を10個に合わせる
				} else {
					navigate("/notfound");
				}

				setLoaded(true);
			});
		}
	}, [props.id, props.mode, navigate]);

	const create = () => {
		return maxios
			.post<string>("/gyms", {
				...gym,
				gallery: gallery.filter((x) => x),
			})
			.then((gymId) => {
				setGym({ ...gym, id: gymId.data });
			});
	};

	const update = () => {
		return maxios.put(`/gyms/${gym.id}`, {
			...gym,
			gallery: gallery.filter((x) => x),
		});
	};

	const register = () => {
		if (!ref.current?.validate()) {
			return (async () => {
				return { isError: true };
			})();
		}

		switch (props.mode) {
			case "new":
				return create().then(() => {
					return { isError: false, message: "登録しました" };
				});
			case "edit":
				return update().then(() => {
					return { isError: false, message: "登録しました" };
				});
			default:
				throw new Error("");
		}
	};

	const navigateToMe = () => {
		navigate(`/gyms/${gym.id}`);
	};

	return (
		<>
			{loaded ? (
				<>
					<Box sx={{ textAlign: "center" }}>
						<Form style={{ width: "100%" }} ref={ref}>
							<FormImageSelector onSelected={updateImage} src={gym.image} />
							<FormGymTypeRadioButton
								name={"gymType"}
								value={gym.gymType}
								onChange={handleChange}
								required
							/>
							<FormTextInput
								label="ジム名"
								name="name"
								value={gym.name}
								onChange={handleChange}
								sx={{ maxWidth: "20rem" }}
								minLength={1}
								maxLength={50}
								required
							/>
							<FormAreaSelector
								areaIds={[gym.areaId]}
								onChange={handleChangeAreaId}
								minAreas={1}
								maxAreas={1}
							/>
							<FormTextInput
								label="住所詳細"
								name="town"
								value={gym.town}
								onChange={handleChange}
								sx={{ maxWidth: "30rem" }}
								maxLength={50}
								required
							/>
							<FormGenreSelector genreIds={gym.genreIds} onChange={handleChangeGenres} />
							<FormTextInput
								label="電話番号"
								name="phoneNumber"
								value={gym.phoneNumber}
								onChange={handleChange}
								sx={{ maxWidth: "9rem" }}
								placeholder="03-1234-5678"
								maxLength={13}
								regExp={/^[-0-9]+$/}
							/>
							<FormTextInput
								label="自己紹介"
								name="introduction"
								value={gym.introduction}
								onChange={handleChange}
								multiline
								maxLength={2000}
							/>
							<FormTextInput
								label="サービス"
								name="service"
								value={gym.service}
								onChange={handleChange}
								multiline
								maxLength={2000}
							/>
							<FormRange
								label="一回当たりの料金"
								lowerValue={gym.feePerOnceLowerLimit}
								upperValue={gym.feePerOnceUpperLimit}
								onChange={handleChangeFeePerOnce}
								minValue={0}
								maxValue={999999}
							/>
							<FormRange
								label="月額料金"
								lowerValue={gym.feePerMonthLowerLimit}
								upperValue={gym.feePerMonthUpperLimit}
								onChange={handleChangeFeePerMonth}
								minValue={0}
								maxValue={999999}
							/>
							<FormRange
								label="体験料金"
								lowerValue={gym.trialFeeLowerLimit}
								upperValue={gym.trialFeeUpperLimit}
								onChange={handleChangeTrialFee}
								minValue={0}
								maxValue={999999}
							/>
							<FormTextInput
								label="最寄り駅"
								name="station"
								value={gym.station}
								onChange={handleChange}
								maxLength={30}
							/>
							<FormCheckbox
								label="オンライン"
								name="online"
								checked={gym.online}
								onChange={handleChangeCheckbox}
							/>
							<FormCheckbox
								label="出張"
								name="onsite"
								checked={gym.onsite}
								onChange={handleChangeCheckbox}
							/>
							<FormTextInput
								label="営業時間"
								name="businessHours"
								value={gym.businessHours}
								onChange={handleChange}
								maxLength={40}
							/>
							<FormGymStaff
								gymId={props.mode === "new" ? undefined : gym.id}
								staffs={gym.staffs}
								onChange={handleChangeStaffs}
								maxNumberOfStaffs={4}
							/>
							<FormTextInput
								label="Webサイト"
								name="website"
								value={gym.website}
								onChange={handleChange}
								placeholder="http://matcho.com/"
								maxLength={128}
								regExp={/^https?:\/\/[-_.!~*()a-zA-Z0-9;/?@&=+$,%#]+$/}
							/>
							<FormTextInput
								label="Facebook"
								name="facebook"
								value={gym.facebook}
								onChange={handleChange}
								prefix="https://www.facebook.com/"
								suffix="/"
								minLength={5}
								maxLength={50}
								// 英数字（a～z、0～9）とピリオドだけ
								regExp={/^[a-zA-Z0-9.]+$/}
							/>
							<FormTextInput
								label="Twitter"
								name="twitter"
								value={gym.twitter}
								onChange={handleChange}
								prefix="https://twitter.com/"
								suffix="/"
								minLength={5}
								maxLength={15}
								sx={{ maxWidth: "12rem" }}
								// 文字、数字、アンダースコアのみ
								regExp={/^[a-zA-Z0-9_]+$/}
							/>
							<FormTextInput
								label="Instagram"
								name="instagram"
								value={gym.instagram}
								onChange={handleChange}
								prefix="https://www.instagram.com/"
								suffix="/"
								maxLength={30}
								sx={{ maxWidth: "20rem" }}
								// 半角英数字、ピリオド、アンダーバー
								regExp={/^[a-zA-Z0-9._]+$/}
								explain={`※入力するとInstagramの投稿があなたのページに表示されます。(プロアカウント(ビジネスアカウントまたはクリエイターアカウント)の場合のみ。個人アカウントでは表示されません。)表示したくない場合は入力しないでください。`}
							/>
							<FormTextInput
								label="YouTube"
								name="youTube"
								value={gym.youTube}
								onChange={handleChange}
								prefix="https://www.youtube.com/channel/"
								maxLength={128}
								regExp={/^[-_a-zA-Z0-9]+$/}
								explain={`※入力するとYouTubeの投稿があなたのページに表示されます。表示したくない場合は入力しないでください。`}
							/>
							<FormTextInput
								label="LINE"
								name="line"
								value={gym.line}
								onChange={handleChange}
								prefix={LineUrl}
								suffix="/"
								maxLength={30}
								sx={{ maxWidth: "20rem" }}
								regExp={/^[-_.@a-zA-Z0-9]+$/}
							/>
							<FormMultipleImageSelector
								onSelected={updateGallery}
								onRemove={removeFromGallery}
								src={gallery}
							/>
						</Form>
					</Box>
					<LongTimeProcessingButton
						caption="登録"
						onClick={register}
						callback={navigateToMe}
						variant="contained"
						sx={{ mt: 1 }}
					/>
				</>
			) : (
				<></>
			)}
		</>
	);
};
