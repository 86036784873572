import { GoogleMap, LoadScript, OverlayView } from "@react-google-maps/api";
import { maxios } from "../MaxiosProvider";
import React, { useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Gym } from "../Types/Gym";
import NumberOfMatchoByArea from "../Types/NumberOfMatchoByArea";
import { getMatchoImageSource } from "../Utilty";
import { MatchoType } from "../Types/MatchoType";
import MarkerGym from "../Resources/marker_gym.png";
import MarkerGymAlter from "../Resources/marker_gym_alter.png";
import MarkerTrainer from "../Resources/marker_trainer.png";
import { Box, IconButton, Typography } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { toQueryString } from "../Types/SearchCondition";
import { AuthContext } from "../AuthContext";

type Props = {
	height?: string | number;
	width?: string | number;
};

export const Map: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();

	const [map, setMap] = useState<google.maps.Map>();
	const [mapTypeId, setMapTypeId] = useState<google.maps.MapTypeId>();
	const [areas, setAreas] = useState<NumberOfMatchoByArea[]>([]);
	const [gyms, setGyms] = useState<Gym[]>([]);
	const [bounds, setBounds] = useState<{
		loLat?: number;
		loLng?: number;
		hiLat?: number;
		hiLng?: number;
	}>();

	const mapRef = useRef<GoogleMap>(null);
	const lat = useRef(35.69575);
	const lng = useRef(139.77521);

	const ignoreRefreshRef = React.useRef(false);
	const refreshRef = React.useRef<NodeJS.Timeout>(null!);
	const controllerRef = React.useRef<AbortController>(null!);

	React.useEffect(() => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				lat.current = position.coords.latitude;
				lng.current = position.coords.longitude;
			},
			(err) => {
				console.log(err);
			}
		);
	}, []);

	React.useEffect(() => {
		if (!bounds) return;
		if (!bounds.loLat || !bounds.loLng || !bounds.hiLat || !bounds.hiLng) return;

		controllerRef.current = new AbortController();

		maxios
			.get<NumberOfMatchoByArea[]>(
				`/areas/number-of-matcho?lolat=${bounds?.loLat}&lolng=${bounds?.loLng}&hilat=${bounds?.hiLat}&hilng=${bounds?.hiLng}`,
				{
					signal: controllerRef.current.signal,
				}
			)
			.then((res) => {
				if (res) {
					setAreas(res.data);
				}
			});

		maxios
			.get<Gym[]>(
				`/gyms?lolat=${bounds?.loLat}&lolng=${bounds?.loLng}&hilat=${bounds?.hiLat}&hilng=${bounds?.hiLng}`,
				{
					signal: controllerRef.current.signal,
				}
			)
			.then((res) => {
				if (res) {
					setGyms(res.data);
				}
			});

		return () => {
			controllerRef.current.abort();
		};
	}, [bounds]);

	// https://dev.classmethod.jp/articles/showing-marker-and-balloon-position-on-react-google-maps-api-map-app-in-a-nice-way/
	const handleLoad = () => {
		setMapTypeId(google.maps.MapTypeId.ROADMAP);
	};

	const refresh = () => {
		lat.current = map?.getCenter()?.lat()!;
		lng.current = map?.getCenter()?.lng()!;

		let zoom = map?.getZoom();
		if (zoom && zoom < 14) return;

		// 表示範囲が変わらなければ処理をスキップする
		// 無限ループを回避するため必要
		if (
			map?.getBounds()?.getSouthWest().lat() === bounds?.loLat &&
			map?.getBounds()?.getSouthWest().lng() === bounds?.loLng &&
			map?.getBounds()?.getNorthEast().lat() === bounds?.hiLat &&
			map?.getBounds()?.getNorthEast().lng() === bounds?.hiLng
		)
			return;

		if (refreshRef.current) {
			clearTimeout(refreshRef.current!);
		}

		if (controllerRef.current) {
			controllerRef.current.abort();
		}

		// リクエストが過剰にならないようウエイトを設けます
		refreshRef.current = setTimeout(() => {
			if (!ignoreRefreshRef.current)
				setBounds({
					loLat: map?.getBounds()?.getSouthWest().lat(),
					loLng: map?.getBounds()?.getSouthWest().lng(),
					hiLat: map?.getBounds()?.getNorthEast().lat(),
					hiLng: map?.getBounds()?.getNorthEast().lng(),
				});
		}, 300);
	};

	const setIgnoreRefresh = () => {
		ignoreRefreshRef.current = true;
	};

	const handleMarkerClick = (areaId: string) => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				areaIds: [areaId],
				type: MatchoType.Trainer,
			}).toString(),
		});
	};

	const handleGymMarkerClick = (id: string) => {
		navigate(`/gyms/${id}`);
	};

	const moveToMyLocation = () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				map?.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
			},
			(err) => {
				console.log(err);
			}
		);
	};

	return (
		<>
			{authContext.isReady && (
				<LoadScript
					googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? ""}
					onLoad={handleLoad}
				>
					<GoogleMap
						ref={mapRef}
						zoom={14}
						mapContainerStyle={{
							height: props.height,
							width: props.width,
						}}
						center={{
							lat: lat.current,
							lng: lng.current,
						}}
						mapTypeId={mapTypeId}
						options={{
							// styles: mapStyles,
							disableDefaultUI: true,
							zoomControl: true,
							gestureHandling: "greedy",
							keyboardShortcuts: false,
						}}
						onLoad={(map) => {
							setMap(map);
						}}
						onBoundsChanged={refresh}
						onUnmount={setIgnoreRefresh}
					>
						{areas.map((area, index) => {
							return (
								<OverlayView
									key={index}
									position={{ lat: area.latitude, lng: area.longitude }}
									mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
									getPixelPositionOffset={(width, height) => ({
										x: -20,
										y: -60,
									})}
								>
									<Box position={"relative"} sx={{ cursor: "pointer" }}>
										<img
											alt="trainer-marker"
											src={MarkerTrainer}
											width={40}
											height={60}
											onClick={() => {
												handleMarkerClick(area.id);
											}}
											style={{ position: "absolute" }}
										/>
										<Box
											width={40}
											height={40}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												color: "white",
												pointerEvents: "none",
											}}
										>
											<Typography sx={{ position: "absolute" }} variant="body1">
												{area.numberOfMatcho.toString()}
											</Typography>
										</Box>
									</Box>
								</OverlayView>
							);
						})}
						{gyms.map((gym, index) => {
							return (
								<OverlayView
									key={index}
									position={{ lat: gym.latitude, lng: gym.longitude }}
									mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
									getPixelPositionOffset={(width, height) => ({
										x: -20,
										y: -60,
									})}
								>
									<Box position={"relative"} sx={{ cursor: "pointer" }}>
										<img
											alt={gym.name}
											src={getMatchoImageSource(gym.image, 40) ?? MarkerGymAlter}
											style={{
												position: "absolute",
												top: 2,
												left: 2,
												height: 37,
												width: 37,
												borderRadius: 50,
											}}
											// hack:srcプロパティが認識できないためanyにキャスト
											onError={(e: any) => (e.target.src = MarkerGymAlter)}
										/>
										<img
											alt="gym-marker"
											src={MarkerGym}
											width={40}
											height={60}
											onClick={() => {
												handleGymMarkerClick(gym.id);
											}}
											style={{ position: "absolute" }}
										/>
									</Box>
								</OverlayView>
							);
						})}
						<IconButton
							onClick={moveToMyLocation}
							sx={{ width: 40, right: 10, bottom: 110, position: "absolute" }}
						>
							<MyLocationIcon />
						</IconButton>
					</GoogleMap>
				</LoadScript>
			)}
		</>
	);
};

Map.defaultProps = {
	height: "100vh",
	width: "100%",
};
