import { Box, IconButton } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { MAvatar } from "./MAvatar";
import Resizer from "react-image-file-resizer";
import CancelIcon from "@mui/icons-material/Cancel";

type Props = {
	src?: string;
	onSelect: (base64: string) => void;
	onRemove?: () => void;
	variant?: "square" | "circular" | "rounded";
};

const ImageSelector: React.FC<Props> = (props) => {
	const resizeFile = (file: Blob, width: number, height: number) => {
		let compressFormat = "";
		switch (file.type) {
			case "image/jpeg":
				compressFormat = "JPEG";
				break;
			case "image/png":
				compressFormat = "PNG";
				break;
			default:
				throw new Error("対応していない形式です。");
		}

		return new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				width,
				height,
				compressFormat,
				75, // 100%の場合元のファイルサイズより大きくなることがあります
				0,
				(uri) => {
					resolve(uri);
				},
				"base64"
			);
		});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let fr = new FileReader();
		let file = e.target?.files![0];

		fr.onload = () => {
			let image = new Image();

			image.onload = async function () {
				let width = image.width;
				let height = image.height;
				let maxSize = 1024;

				if (width > height) {
					if (width >= maxSize) {
						// 横長の画像は横のサイズを指定値にあわせる
						let ratio = height / width;
						width = maxSize;
						height = maxSize * ratio;
					}
				} else {
					if (height >= maxSize) {
						// 縦長の画像は縦のサイズを指定値にあわせる
						let ratio = width / height;
						width = maxSize * ratio;
						height = maxSize;
					}
				}

				let resizedImage = await resizeFile(file, width, height);
				props.onSelect(resizedImage as string);
			};

			image.src = fr.result as string;
		};

		fr.readAsDataURL(e.target?.files![0]);
	};

	return (
		<Box style={{ position: "relative", width: "fit-content", display: "inline-block" }}>
			<IconButton aria-label="edit" component="label" size="small">
				<input accept="image/png,image/jpeg" hidden type="file" onChange={handleChange} />
				<MAvatar src={props.src} variant={props.variant}>
					<EditIcon />
				</MAvatar>
			</IconButton>
			{props.onRemove && props.src && (
				<IconButton
					aria-label="delete"
					size="small"
					onClick={props.onRemove}
					style={{ position: "absolute", right: -8, top: -8 }}
				>
					<CancelIcon fontSize="small" />
				</IconButton>
			)}
		</Box>
	);
};

export default ImageSelector;
