import { TextField, Typography } from "@mui/material";
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { Notifier } from "../../Types/Notifier";
import { Validator } from "../../Types/Validator";
import { FormItem } from "./FormItem";
import { NumberFormatValues, NumericFormat, SourceInfo } from "react-number-format";

type Props = {
	label: string;
	lowerValue?: number;
	upperValue?: number;
	onChange?: (lowerValue: number | undefined, upperValue: number | undefined) => void;
	minValue?: number;
	maxValue?: number;
	explain?: string;
};

const FormRangeRef: React.ForwardRefRenderFunction<Validator, Props> = (
	{ onChange, ...props },
	ref
) => {
	const [lowerValue, setLowerValue] = useState(props.lowerValue);
	const [upperValue, setUpperValue] = useState(props.upperValue);

	const formItemRef = useRef<Notifier>(null);

	const _validate = useCallback(
		(doBlink: boolean) => {
			let valid = true;

			if (lowerValue !== undefined) {
				if (props.minValue !== undefined && lowerValue < props.minValue) {
					formItemRef.current?.setErrorMessage("0以上で入力してください");
					valid = false;
				} else if (props.maxValue && lowerValue > props.maxValue) {
					formItemRef.current?.setErrorMessage("大きすぎます");
					valid = false;
				}
			}

			if (upperValue !== undefined) {
				if (props.minValue !== undefined && upperValue < props.minValue) {
					formItemRef.current?.setErrorMessage("0以上で入力してください");
					valid = false;
				} else if (props.maxValue !== undefined && upperValue > props.maxValue) {
					formItemRef.current?.setErrorMessage("大きすぎます");
					valid = false;
				}
			}

			if (lowerValue !== undefined && upperValue !== undefined && lowerValue > upperValue) {
				formItemRef.current?.setErrorMessage("大小が逆になっています");
				valid = false;
			}

			if (
				(lowerValue === undefined && upperValue !== undefined) ||
				(lowerValue !== undefined && upperValue === undefined)
			) {
				formItemRef.current?.setErrorMessage("片方だけ指定することはできません。");
				valid = false;
			}

			if (valid) {
				formItemRef.current?.setErrorMessage("");
			} else {
				if (doBlink) formItemRef.current?.blink();
			}

			return valid;
		},
		[lowerValue, upperValue, props.minValue, props.maxValue]
	);

	// 親から呼ばれる関数
	useImperativeHandle(
		ref,
		() => ({
			validate() {
				return _validate(true);
			},
		}),
		[_validate]
	);

	const parseInt = (value: string) => {
		let parsedValue: number | undefined;

		if (value === undefined || value === null || value === "" || value === "-") {
			parsedValue = undefined;
		} else {
			if (isNaN(Number.parseInt(value))) {
				throw new TypeError("数字ではありません。");
			} else {
				parsedValue = Number.parseInt(value);
			}
		}

		return parsedValue;
	};

	const handleChangeLowerValue = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
		setLowerValue(parseInt(values.value));
	};

	const handleChangeUpperValue = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
		setUpperValue(parseInt(values.value));
	};

	const handleBlur = () => {
		if (_validate(false)) {
			onChange && onChange(lowerValue, upperValue);
		}
	};

	return (
		<FormItem label={props.label} ref={formItemRef} sx={{ display: "flex", alignItems: "center" }}>
			<NumericFormat
				value={lowerValue}
				suffix={"円"}
				allowLeadingZeros
				thousandSeparator=","
				decimalScale={0}
				onValueChange={handleChangeLowerValue}
				onBlur={handleBlur}
				customInput={TextField}
				sx={{ backgroundColor: "white" }}
			/>
			<Typography>～</Typography>
			<NumericFormat
				value={upperValue}
				suffix={"円"}
				allowLeadingZeros
				thousandSeparator=","
				decimalScale={0}
				onValueChange={handleChangeUpperValue}
				onBlur={handleBlur}
				customInput={TextField}
				sx={{ backgroundColor: "white" }}
			/>
			<Typography variant="caption" fontSize={4} whiteSpace={"pre-wrap"}>{props.explain}</Typography>
		</FormItem>
	);
};

export const FormRange = forwardRef(FormRangeRef);
