import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Typography,
} from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { Headline } from "../Components/Headline";
import { Content } from "../Types/Content";
import { Gym } from "../Types/Gym";
import { GymOwner } from "../Types/GymOwner";
import { MatchoType } from "../Types/MatchoType";
import { SearchResultItem } from "../Components/SearchResultItem";
import { Frame } from "../Components/Frame";
import { LabeledIconButton } from "../Atoms/LabeledIconButton";
import { MyPageMarker } from "../Atoms/MyPageMarker";

type Props = {};

export const GymOwnerView: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const [gymOwner, setGymOwner] = useState<GymOwner>(null!);
	const [gyms, setGyms] = useState<Gym[]>([]);
	const [open, setOpen] = React.useState(false);

	type Params = {
		id?: string;
	};

	const params: Params = useParams<Params>();

	useEffect(() => {
		maxios.get<GymOwner>(`/gymowners/${params.id}`).then((res) => {
			if (!res.data) navigate("/notfound");
			setGymOwner(res.data);
		});

		maxios.get<Gym[]>(`/gymowners/${params.id}/gyms`).then((res) => {
			setGyms(res.data);
		});
	}, [navigate, params.id]);

	const toContent = (gym: Gym): Content => {
		return {
			id: gym.id,
			type: MatchoType.Gym,
			subType: gym.gymType,
			name: gym.name,
			image: gym.image,
			areas: [{ id: gym.area.id, name: gym.area.name }],
			genres: gym.genres,
			rating: gym.rating,
			selfIntroduction: gym.introduction,
			images: [],
		};
	};

	const handleClickEdit = () => {
		navigate(`/gymowners/me/edit`);
	};

	const handleClickAddGym = () => {
		if (gyms.length > 10) {
			setOpen(true);
			return;
		}

		navigate(`/gymowners/me/gyms/new`);
	};

	const handleClick = (gymId: string) => {
		navigate(`/gyms/${gymId}`);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Frame>
			{gymOwner ? (
				<>
					{authContext.claim?.matchoId === gymOwner.id ? (
						<MyPageMarker>
							<LabeledIconButton label="編集" icon="edit" onClick={handleClickEdit} />
							<LabeledIconButton label="ジムを追加" icon="add" onClick={handleClickAddGym} />
						</MyPageMarker>
					) : (
						<></>
					)}
					<Headline
						matchoType={MatchoType.GymOwner}
						image={gymOwner.image}
						name={gymOwner.name}
						numberOfGyms={gymOwner.numberOfGyms}
					/>
					<Divider sx={{ mt: 2, mb: 2 }} />
				</>
			) : (
				<></>
			)}
			{gyms
				.map((g) => toContent(g))
				.map((content, index) => {
					return (
						<SearchResultItem
							content={content}
							key={index}
							onClick={() => handleClick(content.id)}
						/>
					);
				})}
			<Dialog open={open} onClose={handleClose} fullWidth>
				<DialogContent>
					<Typography>これ以上ジムを追加できません。</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleClose}>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</Frame>
	);
};
