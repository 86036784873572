import React from "react";
import MatchoStyle from "../Types/MatchoStyle";

type Props = {
	onClick: (prefecture: string) => void;
};

export const JapanMapSVG: React.FC<Props> = (props) => {
	const handleMouseEnter = (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
		e.currentTarget.style.cursor = "pointer";
		e.currentTarget.style.fill = MatchoStyle.color.matchoOrange;
	};

	const handleMouseOut = (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
		e.currentTarget.style.fill = "#7F440E";
	};

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="176mm"
			height="110mm"
			version="1.1"
			viewBox="0 13 206 146"
			style={{ width: "100%", height: "100%", maxWidth: MatchoStyle.size.contentMaxWidth }}
		>
			<g>
				<g
					fill="#7F440E"
					fillOpacity="1"
					fillRule="nonzero"
					stroke="none"
					strokeWidth="0.035"
					transform="matrix(.79869 0 0 .79869 218.309 64.116)"
				>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-249.205 114.723a3.166 3.166 0 01-3.156 3.157h-3.248a3.166 3.166 0 01-3.156-3.157v-10.896a3.165 3.165 0 013.156-3.156h3.248a3.166 3.166 0 013.156 3.156v10.896"
						onClick={() => props.onClick("470007")} // 沖縄県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-32.859-28.676a3.166 3.166 0 003.156-3.157v-27.373a3.165 3.165 0 00-3.156-3.158h-51.997a3.166 3.166 0 00-3.156 3.158v33.95a3.166 3.166 0 003.156 3.156h8.264a3.166 3.166 0 003.157-3.157v-.263a3.166 3.166 0 013.157-3.156h37.42"
						onClick={() => props.onClick("010006")} // 北海道
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-221.583 113.347a3.165 3.165 0 003.156 3.157h18.675a3.166 3.166 0 003.157-3.157V99.94h-24.981l-.007 13.407"
						onClick={() => props.onClick("460001")} // 鹿児島県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-232.49 52.298V72.2h7.99c.74 0 1.421.26 1.96.69V52.298h-9.95"
						onClick={() => props.onClick("420000")} // 長崎県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-241.034 52.298a3.166 3.166 0 00-3.156 3.158v13.588a3.166 3.166 0 003.156 3.156h7.603V52.298h-7.603"
						onClick={() => props.onClick("410004")} // 佐賀県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-210.962 68.858h-10.624l.01 30.138h10.614V68.858"
						onClick={() => props.onClick("430005")} // 熊本県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-196.595 67.91V55.455a3.166 3.166 0 00-3.157-3.158h-21.834V67.91h24.99"
						onClick={() => props.onClick("400009")} // 福岡県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-210 98.996h13.405v-14.29h-13.406v14.29"
						onClick={() => props.onClick("450006")} // 宮崎県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-210 83.756h13.405V68.858h-13.406v14.898"
						onClick={() => props.onClick("440001")} // 大分県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-65.55 10.206h22.154V-4.89h-22.153v15.097"
						onClick={() => props.onClick("030007")} // 岩手県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-66.504-4.891h-22.171v15.097h22.171V-4.89"
						onClick={() => props.onClick("050008")} // 秋田県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-65.55 28.288h22.154V11.159h-22.153v17.129"
						onClick={() => props.onClick("040002")} // 宮城県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-80.018 44.143V23.454h-8.657v2.017a3.168 3.168 0 01-3.156 3.159l-6.624.004v15.51h18.437"
						onClick={() => props.onClick("150002")} // 新潟県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-79.043 23.454h-.003v4.834h12.542V11.159h-22.171v11.345h9.632v.95"
						onClick={() => props.onClick("060003")} // 山形県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-99.426 44.143V28.636l-11.975.01a3.142 3.142 0 01-2.14-.845v16.342h14.115"
						onClick={() => props.onClick("160008")} // 富山県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-79.046 44.143h35.65V29.241h-35.65v14.902"
						onClick={() => props.onClick("070009")} // 福島県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-187.118 52.695a3.166 3.166 0 00-3.157 3.157V83.34a3.166 3.166 0 003.157 3.157h7.245v-33.8h-7.245"
						onClick={() => props.onClick("350001")} // 山口県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-114.559 23.782a3.165 3.165 0 00-3.156-3.156h-3.98a3.166 3.166 0 00-3.156 3.156v20.361h10.361l-.069-20.36"
						onClick={() => props.onClick("170003")} // 石川県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-156.329 67.906v-15.21h-10.807v15.21h10.807"
						onClick={() => props.onClick("310000")} // 鳥取県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-43.396-13.716a3.166 3.166 0 00-3.156-3.156h-38.966a3.166 3.166 0 00-3.157 3.156v7.872h45.279v-7.872"
						onClick={() => props.onClick("020001")} // 青森県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-155.374 52.695v33.8h8.545c.853 0 1.628.344 2.197.897V52.695h-10.742"
						onClick={() => props.onClick("280003")} // 兵庫県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-178.92 68.862v17.634h10.83V68.862h-10.83"
						onClick={() => props.onClick("340006")} // 広島県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-168.09 67.906v-15.21h-10.83v15.21h10.83"
						onClick={() => props.onClick("320005")} // 島根県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-167.136 68.862v17.634h10.807V68.862h-10.807"
						onClick={() => props.onClick("330001")} // 岡山県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-113.542 62.574h.006V90.13h10.78V45.092h-10.785v17.482"
						onClick={() => props.onClick("210005")} // 岐阜県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-114.486 91.09V77.81h-9.88v29.355h.022v.943h-.022v9.087h6.535c1.736 0 3.287-1.498 3.287-3.233l.058-22.872"
						onClick={() => props.onClick("240001")} // 三重県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-126.239 76.854h11.753V62.906h-11.753v13.948"
						onClick={() => props.onClick("250007")} // 滋賀県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-73.212 45.092H-88.43V61.61h15.218V45.092"
						onClick={() => props.onClick("100005")} // 群馬県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-56.607 88.387s.003 1.036.003 1.634v11.726c0 1.745 1.419 3.17 3.155 3.17h6.897c1.735 0 3.156-1.425 3.156-3.17V68.453h-13.211v19.934"
						onClick={() => props.onClick("120006")} // 千葉県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-113.536 91.09v17.786a3.138 3.138 0 012.018-.736h12.303V91.09h-14.321"
						onClick={() => props.onClick("230006")} // 愛知県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-98.26 91.09v17.05h15.263c1.454 0 2.682-.906 3.045-2.127V91.09H-98.26"
						onClick={() => props.onClick("220001")} // 静岡県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-78.997 103.308c.578-.57 1.4-.93 2.315-.93h8.926c1.736 0 3.157-1.427 3.157-3.171V91.09h-14.398v12.218"
						onClick={() => props.onClick("140007")} // 神奈川県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-78.997 77.8v12.331h14.398v-.11c0-1.743 1.42-3.17 3.156-3.17h1.417c.993 0 1.88.467 2.46 1.192V77.8h-21.431"
						onClick={() => props.onClick("130001")} // 東京都
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-133.82 107.165h8.505V77.81h-8.505v29.355"
						onClick={() => props.onClick("290009")} // 奈良県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-72.25 61.61h14.684V45.091H-72.25V61.61"
						onClick={() => props.onClick("090000")} // 栃木県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-88.43 76.834h30.864V62.56H-88.43v14.275"
						onClick={() => props.onClick("110001")} // 埼玉県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-127.194 76.854V62.906h-7.354v-.957h.019v-9.254h-9.143v24.159h16.478"
						onClick={() => props.onClick("260002")} // 京都府
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-56.607 45.092v22.409h13.211V45.092h-13.211"
						onClick={() => props.onClick("080004")} // 茨城県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-114.49 61.95V45.091h-10.361v4.447a3.167 3.167 0 01-3.157 3.156h-5.562v9.254h19.08"
						onClick={() => props.onClick("180009")} // 福井県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-89.385 45.092h-12.421v45.039h7.514V76.834h4.907V45.092"
						onClick={() => props.onClick("200000")} // 長野県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-134.763 77.81h-8.909V99.195h8.909V77.81"
						onClick={() => props.onClick("270008")} // 大阪府
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-134.763 108.108v-7.958h-8.909v13.89a3.165 3.165 0 003.157 3.155h15.2v-9.087h-9.448"
						onClick={() => props.onClick("300004")} // 和歌山県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-93.326 90.131h13.374v-12.33h-13.374v12.33"
						onClick={() => props.onClick("190004")} // 山梨県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-190.537 113.347a3.165 3.165 0 003.155 3.157h34.715a3.166 3.166 0 003.157-3.157v-4.602h-41.027v4.602"
						onClick={() => props.onClick("390003")} // 高知県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-149.51 99.685v-4.426a3.167 3.167 0 00-3.157-3.157h-17.125v7.583h20.282"
						onClick={() => props.onClick("370002")} // 香川県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-169.792 100.54v7.348h20.282v-7.348h-20.282"
						onClick={() => props.onClick("360007")} // 徳島県
					></path>
					<path
						onMouseEnter={handleMouseEnter}
						onMouseOut={handleMouseOut}
						d="M-170.648 92.102h-16.734a3.166 3.166 0 00-3.155 3.157v12.63h19.89V92.101"
						onClick={() => props.onClick("380008")} // 愛媛県
					></path>
				</g>
			</g>
		</svg>
	);
};
