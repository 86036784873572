import { IconButton, Typography } from "@mui/material";
import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type Props = {
    onClick?: () => void,
};

export const BackIcon: React.FC<Props> = (props) => {
	return (
		<IconButton onClick={props.onClick} sx={{display: "flex", alignItems: "center"}}>
			<ArrowBackIcon />
            <Typography>Back</Typography>
		</IconButton>
	);
};