import { Box, SxProps, TextField, Theme, Typography } from "@mui/material";
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { Notifier } from "../../Types/Notifier";
import { Validator } from "../../Types/Validator";
import { isEmail } from "../../Utilty";
import { FormItem } from "./FormItem";

type Props = {
	label: string;
	name: string;
	value?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	sx?: SxProps<Theme>;
	multiline?: boolean;
	type?: string;
	disabled?: boolean;
	minLength?: number;
	maxLength?: number;
	required?: boolean;
	prefix?: string;
	suffix?: string;
	regExp?: RegExp;
	invisibleCaption?: boolean;
	variant?: "dialog" | "page";
	captionWidth?: number;
	explain?: string;
};

const FormTextInputRef: React.ForwardRefRenderFunction<Validator, Props> = (
	{
		label,
		name,
		value,
		onChange,
		placeholder,
		sx,
		multiline,
		type,
		disabled,
		minLength,
		maxLength,
		required,
		prefix,
		suffix,
		regExp,
		invisibleCaption,
		variant,
		captionWidth,
		explain,
	},
	ref
) => {
	const formItemRef = useRef<Notifier>(null);

	const _validate = useCallback(
		(doBlink: boolean) => {
			let valid = false;

			if (required && !value) {
				formItemRef.current?.setErrorMessage("必須です");
			} else if (!value) {
				formItemRef.current?.setErrorMessage("");
				valid = true;
			} else if (type === "email" && !isEmail(value)) {
				formItemRef.current?.setErrorMessage(`メールアドレスの形式ではありません`);
			} else if (regExp && !value.match(regExp)) {
				formItemRef.current?.setErrorMessage(`不正な形式です`);
			} else if (minLength && minLength > value.length) {
				formItemRef.current?.setErrorMessage(`${minLength}文字以上必要です`);
			} else if (maxLength && maxLength < value.length) {
				formItemRef.current?.setErrorMessage(`${maxLength}文字以内にしてください`);
			} else {
				formItemRef.current?.setErrorMessage("");
				valid = true;
			}

			if (doBlink && !valid) formItemRef.current?.blink();

			return valid;
		},
		[maxLength, minLength, required, value, type, regExp]
	);

	// 親から呼ばれる関数
	useImperativeHandle(
		ref,
		() => ({
			validate() {
				return _validate(true);
			},
		}),
		[_validate]
	);

	return (
		<FormItem
			label={label}
			ref={formItemRef}
			invisibleCaption={invisibleCaption}
			variant={variant}
			captionWidth={captionWidth}
		>
			<Box sx={{ display: "flex" }}>
				<Typography display="flex" alignItems="center" variant="body2">
					{prefix}
				</Typography>
				<TextField
					name={name}
					value={value}
					onChange={onChange}
					sx={{ ...sx, backgroundColor: "white" }}
					placeholder={placeholder}
					multiline={multiline}
					rows={5}
					fullWidth
					type={type}
					disabled={disabled}
					onBlur={() => _validate(false)}
				/>
				<Typography display="flex" alignItems="center" variant="body2">
					{suffix}
				</Typography>
			</Box>
			<Typography variant="caption" fontSize={9} whiteSpace={"pre-wrap"}>{explain}</Typography>
		</FormItem>
	);
};

export const FormTextInput = forwardRef(FormTextInputRef);
