import { Typography } from "@mui/material";
import React from "react";

type Props = {
	label?: string;
	lowerLimit?: number;
	upperLimit?: number;
};

export const RangeDisplay: React.FC<Props> = (props) => {
	const createDisplayValue = (lowerLimit?: number, upperLimit?: number) => {
		if (props.lowerLimit === undefined && props.upperLimit === undefined) return "";
		if (props.lowerLimit === props.upperLimit) return `￥${props.upperLimit!.toString()}`;

		let displayLowerLimit = lowerLimit!.toString();

		let displayUpperLimit = upperLimit!.toString();

		return `￥${displayLowerLimit}～${displayUpperLimit}`;
	};

	return (
		<>
			{(props.lowerLimit !== undefined || props.upperLimit !== undefined) && (
				<Typography variant="body2">
					{/* {props.label}: {createDisplayValue(props.lowerLimit, props.upperLimit)} */}
					{createDisplayValue(props.lowerLimit, props.upperLimit)}
				</Typography>
			)}
		</>
	);
};
