import { Divider, Typography } from "@mui/material";
import React from "react";
import { CaptionedItem } from "./CaptionedItem";
import { RangeDisplay } from "./RangeDisplay";

type Props = {
	feePerOnceLowerLimit?: number;
	feePerOnceUpperLimit?: number;
	feePerMonthLowerLimit?: number;
	feePerMonthUpperLimit?: number;
	trialFeeLowerLimit?: number;
	trialFeeUpperLimit?: number;
	dividerAtBottom?: boolean;
};

export const FeeTable: React.FC<Props> = (props) => {
	return (
		<>
			{(props.feePerOnceLowerLimit !== undefined ||
				props.feePerOnceUpperLimit !== undefined ||
				props.feePerMonthLowerLimit !== undefined ||
				props.feePerMonthUpperLimit !== undefined ||
				props.trialFeeLowerLimit !== undefined ||
				props.trialFeeUpperLimit !== undefined) && (
				<>
					<CaptionedItem caption="料金">
						<table style={{ borderSpacing: 0 }}>
							<tbody>
								<FeeTableItem
									caption="1回"
									lowerLimit={props.feePerOnceLowerLimit}
									upperLimit={props.feePerOnceUpperLimit}
								/>
								<FeeTableItem
									caption="1ヶ月"
									lowerLimit={props.feePerMonthLowerLimit}
									upperLimit={props.feePerMonthUpperLimit}
								/>
								<FeeTableItem
									caption="体験"
									lowerLimit={props.trialFeeLowerLimit}
									upperLimit={props.trialFeeUpperLimit}
								/>
							</tbody>
						</table>
					</CaptionedItem>
					{props.dividerAtBottom && <Divider />}
				</>
			)}
		</>
	);
};

type FeeTableItemProps = {
	caption: string;
	lowerLimit?: number;
	upperLimit?: number;
};

const FeeTableItem: React.FC<FeeTableItemProps> = (props) => {
	return (
		<>
			{(props.lowerLimit !== undefined || props.upperLimit !== undefined) && (
				<tr>
					<td>
						<Typography variant="body2">{props.caption}</Typography>
					</td>
					<td>
						<RangeDisplay lowerLimit={props.lowerLimit} upperLimit={props.upperLimit} />
					</td>
				</tr>
			)}
		</>
	);
};
