import { Typography } from "@mui/material";
import React from "react";
import { CaptionedItem } from "./CaptionedItem";

type Props = {
	caption: string;
	text?: string;
	dividerAtTop?: boolean;
	dividerAtBottom?: boolean;
};

export const CaptionedText: React.FC<Props> = (props) => {
	return (
		<>
			{props.text && (
				<CaptionedItem
					caption={props.caption}
					dividerAtTop={props.dividerAtTop}
					dividerAtBottom={props.dividerAtBottom}
				>
					<Typography variant="body2" sx={{ whiteSpace: "pre-line", overflowWrap: "break-word" }}>
						{props.text}
					</Typography>
				</CaptionedItem>
			)}
		</>
	);
};
