import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
	Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { RadioButtons } from "../Atoms/RadioButtons";
import { CaptionedItem } from "../Components/CaptionedItem";
import SearchCondition, { toQueryString } from "../Types/SearchCondition";
import { MatchoType, getMatchoTypeName } from "../Types/MatchoType";
import { getCastedValue } from "../Utilty";
import AreaSelector from "./AreaSelector";
import { GenreCheckboxes } from "./GenreCheckboxes";
import { useNavigate } from "react-router-dom";
import { Range } from "../Atoms/Range";

type Props = {
	open: boolean;
	onClose: () => void;
	searchCondition: SearchCondition;
};

export const SearchConditionEditor: React.FC<Props> = (props) => {
	const navigate = useNavigate();

	const getAreaId = (index: number) => {
		return searchCondition.areaIds && searchCondition.areaIds.length > index
			? searchCondition.areaIds[index]
			: undefined;
	};

	const [searchCondition, setSearchCondition] = useState(props.searchCondition);
	const [area0, setArea0] = useState(getAreaId(0));
	const [area1, setArea1] = useState(getAreaId(1));
	const [area2, setArea2] = useState(getAreaId(2));

	useEffect(() => {
		setSearchCondition(props.searchCondition);
	}, [props.searchCondition]);

	const handleChangeSearchWord = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchCondition({ ...searchCondition, [event.target.name]: getCastedValue(event) });
	};

	const handleChangeAreaSelector0 = (areaId: string | undefined) => {
		setArea0(areaId);
	};

	const handleChangeAreaSelector1 = (areaId: string | undefined) => {
		setArea1(areaId);
	};

	const handleChangeAreaSelector2 = (areaId: string | undefined) => {
		setArea2(areaId);
	};

	const handleChangeMatchoType = (value: any) => {
		setSearchCondition({ ...searchCondition, type: value });
	};

	const handleChangeGenreCheckboxes = useCallback((values: string[]) => {
		setSearchCondition((previous) => {
			return { ...previous, genreIds: values };
		});
	}, []);

	const handleChangeFeePerOnce = (
		lowerValue: number | undefined,
		upperValue: number | undefined
	) => {
		setSearchCondition({
			...searchCondition,
			feePerOnceLowerLimit: lowerValue,
			feePerOnceUpperLimit: upperValue,
		});
	};

	const handleChangeFeePerMonth = (
		lowerValue: number | undefined,
		upperValue: number | undefined
	) => {
		setSearchCondition({
			...searchCondition,
			feePerMonthLowerLimit: lowerValue,
			feePerMonthUpperLimit: upperValue,
		});
	};

	const handleChangeTrialFee = (lowerValue: number | undefined, upperValue: number | undefined) => {
		setSearchCondition({
			...searchCondition,
			trialFeeLowerLimit: lowerValue,
			trialFeeUpperLimit: upperValue,
		});
	};

	const handleClick = () => {
		props.onClose();

		navigate({
			pathname: "/matchos",
			search: toQueryString({
				...searchCondition,
				// 未選択を除外し重複を削除します
				areaIds: Array.from(new Set([area0, area1, area2].filter((area) => area))) as string[],
			}),
		});
	};

	const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		setSearchCondition({
			...searchCondition,
			[event.target.name]: checked,
		});
	};

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="lg">
			<DialogTitle>検索条件</DialogTitle>
			<DialogContent>
				<CaptionedItem caption="マッチョタイプ" dividerAtBottom>
					<RadioButtons
						name="matchotype"
						items={[
							{ label: getMatchoTypeName(MatchoType.Trainer), value: MatchoType.Trainer },
							{ label: getMatchoTypeName(MatchoType.Gym), value: MatchoType.Gym },
							{ label: "すべて", value: undefined },
						]}
						onChange={handleChangeMatchoType}
						value={searchCondition.type}
					/>
				</CaptionedItem>
				<CaptionedItem caption="フリーワード" dividerAtBottom>
					<TextField
						value={searchCondition.searchWord ?? ""}
						onChange={handleChangeSearchWord}
						name="searchWord"
					/>
				</CaptionedItem>
				<CaptionedItem caption="エリア" dividerAtBottom>
					<Box className="spaced-children vertical">
						<AreaSelector areaId={getAreaId(0)} onChange={handleChangeAreaSelector0} />
						<AreaSelector areaId={getAreaId(1)} onChange={handleChangeAreaSelector1} />
						<AreaSelector areaId={getAreaId(2)} onChange={handleChangeAreaSelector2} />
					</Box>
				</CaptionedItem>
				<CaptionedItem caption="ジャンル" dividerAtBottom>
					<GenreCheckboxes
						values={searchCondition.genreIds}
						onChange={handleChangeGenreCheckboxes}
					/>
				</CaptionedItem>
				<CaptionedItem caption="授業方法" dividerAtBottom>
					<FormControlLabel
						control={
							<Checkbox
								checked={searchCondition.online ?? false}
								onChange={handleChangeCheckbox}
								name="online"
								sx={{ py: 0.5 }}
							/>
						}
						label={<Typography variant="caption">オンライン</Typography>}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={searchCondition.onsite ?? false}
								onChange={handleChangeCheckbox}
								name="onsite"
								sx={{ py: 0.5 }}
							/>
						}
						label={<Typography variant="caption">出張</Typography>}
					/>
				</CaptionedItem>
				<CaptionedItem caption="料金目安" className="spaced-children vertical">
					<table>
						<tbody>
							<tr>
								<td width={35}>
									<Typography variant="caption">1回</Typography>
								</td>
								<td>
									<Range
										lowerValue={searchCondition.feePerOnceLowerLimit}
										upperValue={searchCondition.feePerOnceUpperLimit}
										onChange={handleChangeFeePerOnce}
									/>
								</td>
							</tr>
							<tr>
								<td>
									<Typography variant="caption">1ヶ月</Typography>
								</td>
								<td>
									<Range
										lowerValue={searchCondition.feePerMonthLowerLimit}
										upperValue={searchCondition.feePerMonthUpperLimit}
										onChange={handleChangeFeePerMonth}
									/>
								</td>
							</tr>
							<tr>
								<td>
									<Typography variant="caption">体験</Typography>
								</td>
								<td>
									<Range
										lowerValue={searchCondition.trialFeeLowerLimit}
										upperValue={searchCondition.trialFeeUpperLimit}
										onChange={handleChangeTrialFee}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</CaptionedItem>
				<Button onClick={handleClick}>この条件で絞り込む</Button>
			</DialogContent>
		</Dialog>
	);
};
