import React from "react";
import { Box, Grid, Link, Rating, Typography } from "@mui/material";
import { SnsIconButton } from "../Components/SnsIconButton";
import { MatchoType } from "../Types/MatchoType";
import RoomIcon from "@mui/icons-material/Room";
import PhoneIcon from "@mui/icons-material/Phone";
import { Card } from "../Atoms/Card/Card";
import { GymType } from "../Types/GymType";
import { TrainerType } from "../Types/TrainerType";

type Props = {
	matchoType: MatchoType;
	matchoSubType?: TrainerType | GymType;
	image?: string;
	name?: string;
	rating?: number;
	numberOfComments?: number;
	numberOfGyms?: number;
	facebook?: string;
	twitter?: string;
	instagram?: string;
	website?: string;
	youTube?: string;
	line?: string;
	phoneNumber?: string;
	address?: string;
};

export const Headline: React.FC<Props> = (props) => {
	return (
		<Card
			image={props.image}
			name={props.name}
			size="large"
			type={props.matchoType}
			matchoSubType={props.matchoSubType}
		>
			{props.rating !== undefined && (
				<Box display="flex" mb={2}>
					<Rating defaultValue={props.rating} precision={0.5} readOnly />
					<Typography fontWeight="bold" ml={1}>
						{props.rating ? Math.round(props.rating * 100) / 100 : "0.00"}
					</Typography>
				</Box>
			)}
			<Grid container sx={{ display: "flex", alignItems: "center" }}>
				{props.address ? (
					<Grid item>
						<Typography variant="body2" sx={{ display: "flex", alignItems: "center", mr: 4 }}>
							<RoomIcon />
							<Link
								target={"_blank"}
								rel="noopener noreferrer"
								href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(props.address)}`}
							>
								{props.address}
							</Link>
						</Typography>
					</Grid>
				) : (
					<></>
				)}
				{props.phoneNumber ? (
					<Grid item>
						<Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
							<PhoneIcon />
							{props.phoneNumber}
						</Typography>
					</Grid>
				) : (
					<></>
				)}
			</Grid>
			<Grid container sx={{ marginTop: "auto", display: "flex" }}>
				{props.numberOfComments ? (
					<Grid item sx={{ display: "flex", alignItems: "center", mr: "auto" }}>
						<Typography variant="caption" sx={{ mr: 1 }}>
							コメント数
						</Typography>
						<Typography sx={{ fontWeight: "bold" }}>{props.numberOfComments}</Typography>
					</Grid>
				) : (
					<></>
				)}
				{props.numberOfGyms ? (
					<Grid item sx={{ display: "flex", alignItems: "center", mr: "auto" }}>
						<Typography variant="caption" sx={{ mr: 1 }}>
							店舗数
						</Typography>
						<Typography sx={{ fontWeight: "bold" }}>{props.numberOfGyms}</Typography>
					</Grid>
				) : (
					<></>
				)}
				<Grid item sx={{ ml: "auto" }}>
					<SnsIconButton sns="website" value={props.website} size={25} />
					<SnsIconButton sns="facebook" value={props.facebook} size={25} />
					<SnsIconButton sns="twitter" value={props.twitter} size={25} />
					<SnsIconButton sns="instagram" value={props.instagram} size={25} />
					<SnsIconButton sns="youTube" value={props.youTube} size={25} />
					<SnsIconButton sns="line" value={props.line} size={25} />
				</Grid>
			</Grid>
		</Card>
	);
};
