import { FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { BpRadio } from "../../Atoms/BpRadio";
import { Gender } from "../../Types/Gender";
import { Notifier } from "../../Types/Notifier";
import { Validator } from "../../Types/Validator";
import { FormItem } from "./FormItem";

type Props = {
	name?: string;
	value?: Gender;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	required?: boolean;
};

const FormGenderRadioButtonRef: React.ForwardRefRenderFunction<Validator, Props> = (
	{ name, value, onChange, disabled, required },
	ref
) => {
	const formItemRef = useRef<Notifier>(null);

	const _validate = useCallback(
		(doBlink: boolean) => {
			let valid = false;

			if (required && value === undefined) {
				formItemRef.current?.setErrorMessage("必須です");
			} else if (!value) {
				formItemRef.current?.setErrorMessage("");
				valid = true;
			} else {
				formItemRef.current?.setErrorMessage("");
				valid = true;
			}

			if (doBlink && !valid) formItemRef.current?.blink();

			return valid;
		},
		[required, value]
	);

	// 親から呼ばれる関数
	useImperativeHandle(
		ref,
		() => ({
			validate() {
				return _validate(true);
			},
		}),
		[_validate]
	);

	return (
		<FormItem label="性別" ref={formItemRef}>
			<FormControl>
				<RadioGroup row name={name} onChange={onChange} onBlur={() => _validate(false)}>
					<FormControlLabel
						value={1}
						checked={value === Gender.Female}
						control={<BpRadio />}
						disabled={disabled}
						label="女性"
					/>
					<FormControlLabel
						value={0}
						checked={value === Gender.Male}
						control={<BpRadio />}
						disabled={disabled}
						label="男性"
					/>
					<FormControlLabel
						value={2}
						checked={value === Gender.Other}
						control={<BpRadio />}
						disabled={disabled}
						label="その他"
					/>
				</RadioGroup>
			</FormControl>
		</FormItem>
	);
};

export const FormGenderRadioButton = forwardRef(FormGenderRadioButtonRef);
