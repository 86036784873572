import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Divider,
	Paper,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../matcho-logo.png";
import { LongTimeProcessingButton } from "../Components/LongTimeProcessingButton";
import { Form } from "../Components/Form/Form";
import { FormTextInput } from "../Components/Form/FormTextInput";
import { Validator } from "../Types/Validator";
import { AuthContext } from "../AuthContext";
import google from "../Resources/google.png";

type Props = {};

const SignIn: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const ref = useRef<Validator>(null);

	const [emailAndPassword, setEmailAndPassword] = useState({
		email: "",
		password: "",
	});

	const [email, setEmail] = useState("");
	const [open, setOpen] = useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmailAndPassword({ ...emailAndPassword, [event.target.name]: event.target.value });
	};

	const signInWithEmail = () => {
		if (!ref.current?.validate()) {
			return (async () => {
				return { isError: true };
			})();
		}

		return authContext.signInWithEmail(emailAndPassword.email, emailAndPassword.password);
	};

	const signInWithGoogle = () => {
		authContext.signInWithGoogle();
	};

	const navigateToRegitration = () => {
		navigate("/registration");
	};

	const navigateToTop = () => {
		navigate("/");
	};

	const handleResetPassword = () => {
		return authContext
			.submitPasswordResetEmail(email)
			.then(() => {
				return {
					isError: false,
					message: "メールを送信しました。メール内のリンクからパスワードを再設定してください。",
				};
			})
			.catch((error: any) => {
				return { isError: true, message: error.message };
			});
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleComplete = () => {
		setOpen(false);
	};

	return (
		<Box
			sx={{
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundAttachment: "fixed",
				mx: 2,
			}}
		>
			<Paper sx={{ p: 2, width: "400px", textAlign: "center" }}>
				<img
					src={Logo}
					onClick={navigateToTop}
					alt="Matcho logo"
					style={{ cursor: "pointer" }}
					width={198}
					height={55}
				/>
				<Form style={{ width: "100%", marginTop: 16 }} ref={ref}>
					<FormTextInput
						label=""
						name="email"
						type="email"
						value={emailAndPassword.email}
						onChange={handleChange}
						required
						invisibleCaption
						placeholder="Email"
						variant="dialog"
					/>
					<FormTextInput
						label=""
						name="password"
						type="password"
						value={emailAndPassword.password}
						onChange={handleChange}
						sx={{ mt: 1 }}
						minLength={6}
						required
						invisibleCaption
						placeholder="Password"
						variant="dialog"
					/>
				</Form>
				<Button onClick={handleOpen} sx={{ my: 0 }}>
					パスワードを忘れた方はこちら
				</Button>
				<LongTimeProcessingButton
					caption="ログイン"
					onClick={signInWithEmail}
					sx={{
						mt: 1,
						width: 200,
					}}
					className="pink"
				/>
				<br />
				<Button
					onClick={navigateToRegitration}
					// variant="contained"
					sx={{ my: 2, width: 200 }}
					className="orange"
				>
					無料会員登録
				</Button>
				<Divider variant="middle" sx={{ m: 0 }} />
				<Button
					onClick={signInWithGoogle}
					// variant="contained"
					sx={{ my: 2, width: 200, textTransform: "none" }}
					startIcon={<img src={google} height={24} alt="google" />}
					className="white"
				>
					Googleでログイン
				</Button>
				<Dialog open={open} onClose={handleClose}>
					<DialogContent>
						<DialogContentText>
							メールアドレスを入力しボタンをクリックしてください。
							<br />
						</DialogContentText>
						<Form ref={ref}>
							<FormTextInput
								label=""
								name="email"
								type="email"
								value={email}
								onChange={handleChangeEmail}
								sx={{ mt: 1 }}
								required
								invisibleCaption
								placeholder="Email"
								variant="dialog"
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<LongTimeProcessingButton
							caption="OK"
							onClick={handleResetPassword}
							callback={handleComplete}
							variant="contained"
							sx={{ mt: 1 }}
						/>
						<Button onClick={handleClose}>キャンセル</Button>
					</DialogActions>
				</Dialog>
			</Paper>
		</Box>
	);
};

export default SignIn;
