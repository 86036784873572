import { Chip, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { maxios } from "../MaxiosProvider";
import Area from "../Types/Area";
import { toQueryString } from "../Types/SearchCondition";

type Props = {
	prefectureId?: string;
	areas?: Area[];
	onClick?: (areaId: string) => void;
	size?: "small" | "medium" | undefined;
};

export const AreaChips: React.FC<Props> = (props) => {
	const navigate = useNavigate();
	const [areas, setAreas] = useState(props.areas);

	useEffect(() => {
		props.prefectureId && maxios.get<Area[]>(`/Areas?prefectureId=${props.prefectureId}`).then((res) => {
			setAreas(res.data);
		});
	}, [props.prefectureId]);

	const handleClick = (areaId: string) => () => {
		if (props.onClick) {
			props.onClick(areaId);
		} else {
			navigate({
				pathname: "/matchos",
				search: toQueryString({
					areaIds: [areaId],
				}).toString(),
			});
		}
	};

	return (
		<>
			<Grid container spacing={1}>
				{areas && areas.map((area, index) => {
					return (
						<Grid item key={index}>
							<Chip
								label={<Typography variant="caption">{area.name}</Typography>}
								onClick={handleClick(area.id)}
								variant="outlined"
								size={props.size}
							/>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};
