import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import AreaSelector from "../../Pages/AreaSelector";
import { Notifier } from "../../Types/Notifier";
import { Validator } from "../../Types/Validator";
import { hasDuplicates } from "../../Utilty";
import { FormItem } from "./FormItem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
	areaIds: string[];
	onChange: (areaIds: string[]) => void;
	required?: boolean;
	minAreas?: number;
	maxAreas: number;
};

const FormAreaSelectorRef: React.ForwardRefRenderFunction<Validator, Props> = (
	{ areaIds, onChange, required, minAreas, maxAreas },
	ref
) => {
	const formItemRef = useRef<Notifier>(null);

	const [values, setValues] = useState<(string | undefined)[]>(
		areaIds.length === 0 ? [undefined] : areaIds
	);

	const handleChange = (index: number, value?: string) => {
		let clonedValues = [...values];

		if (values.length === index) {
			if (!value) return;

			clonedValues.push(value);
		} else {
			clonedValues[index] = value;
		}

		setValues(clonedValues);
		onChange(clonedValues.filter((value) => value) as string[]);
	};

	const _validate = useCallback(
		(doBlink: boolean) => {
			let valid = false;

			if (required && values.filter((value) => value).length === 0) {
				formItemRef.current?.setErrorMessage("必須です");
			} else if (hasDuplicates(values)) {
				formItemRef.current?.setErrorMessage("重複しています");
			} else if (minAreas && values.filter((value) => value).length < minAreas) {
				formItemRef.current?.setErrorMessage(`エリアは${minAreas}個以上必要です`);
			} else if (values.filter((value) => value).length > maxAreas) {
				formItemRef.current?.setErrorMessage(`エリアは${maxAreas}個までです`);
			} else {
				formItemRef.current?.setErrorMessage("");
				valid = true;
			}

			if (doBlink && !valid) formItemRef.current?.blink();

			return valid;
		},
		[required, minAreas, maxAreas, values]
	);

	useEffect(() => {
		_validate(false);
	}, [_validate]);

	// 親から呼ばれる関数
	useImperativeHandle(
		ref,
		() => ({
			validate() {
				return _validate(true);
			},
		}),
		[_validate]
	);

	const handleClickAdd = () => {
		setValues(values.concat([undefined]));
	};

	const handleClickRemove = (index: number) => () => {
		let removedValues = values.filter((_value, _index) => _index !== index);
		setValues(removedValues);
		onChange(removedValues.filter((value) => value) as string[]);
	};

	return (
		<FormItem label="エリア" ref={formItemRef}>
			<Stack spacing={1}>
				{values.map((value, index) => {
					return (
						<Box display="flex" key={index}>
							<AreaSelector
								onChange={(areaId?: string) => {
									handleChange(index, areaId);
								}}
								areaId={value}
							/>
							<IconButton onClick={handleClickRemove(index)}>
								<DeleteIcon />
							</IconButton>
						</Box>
					);
				})}
			</Stack>
			{values.length < maxAreas && (
				<IconButton onClick={handleClickAdd}>
					<AddCircleOutlineIcon />
					<Typography>追加</Typography>
				</IconButton>
			)}
		</FormItem>
	);
};

export const FormAreaSelector = forwardRef(FormAreaSelectorRef);
