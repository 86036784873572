import React, { useContext, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import { UserPostModel } from "../Types/UserPostModel";
import { formatDate, getCastedValue } from "../Utilty";
import { AuthContext } from "../AuthContext";
import { Form } from "./Form/Form";
import { FormTextInput } from "./Form/FormTextInput";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";
import { FormGenderRadioButton } from "./Form/FormGenderRadioButton";
import { Validator } from "../Types/Validator";

type Props = {
	onCompleted?: () => void;
};

const UserRegistration: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const [user, setUser] = useState<UserPostModel>({
		name: authContext.user?.displayName ?? "",
		email: authContext.user?.email ?? "",
		gender: undefined,
		birthday: null,
	});
	const [loaded, setLoaded] = useState(false);
	const [mode, setMode] = useState<"create" | "update">("create");
	const ref = useRef<Validator>(null);

	useEffect(() => {
		if (authContext.claim) {
			setUser({
				name: authContext.claim.name,
				email: authContext.claim.email,
				gender: authContext.claim.gender,
				birthday: authContext.claim.birthday ? new Date(authContext.claim.birthday) : null,
			});

			setMode("update");
		}

		setLoaded(true);
	}, [authContext.claim]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUser({ ...user, [event.target.name]: getCastedValue(event) });
	};

	const register = () => {
		if (!ref.current?.validate()) {
			return (async () => {
				return { isError: true };
			})();
		}

		switch (mode) {
			case "create":
				return maxios.post("/Users", user).then(() => {
					return { isError: false };
				});
			case "update":
				return maxios.put("/Users", user).then(() => {
					return { isError: false, message: "登録しました" };
				});
			default:
				throw new Error("");
		}
	};

	return (
		<>
			{loaded ? (
				<Box sx={{ textAlign: "center" }}>
					<Form style={{ width: "100%" }} ref={ref}>
						<FormTextInput
							label="お名前"
							name="name"
							value={user.name}
							onChange={handleChange}
							sx={{ maxWidth: "20rem" }}
							minLength={1}
							maxLength={20}
							required
							captionWidth={100}
						/>
						<FormTextInput
							label="メールアドレス"
							name="email"
							type="email"
							value={user.email}
							onChange={handleChange}
							sx={{ maxWidth: "20rem" }}
							disabled
							required
						/>
						<FormGenderRadioButton
							name="gender"
							onChange={handleChange}
							value={user.gender}
							required
						/>
						<FormTextInput
							label="お誕生日"
							type="date"
							name="birthday"
							value={formatDate(user.birthday)}
							onChange={handleChange}
							sx={{ maxWidth: "20rem" }}
							required
						/>
					</Form>
					<LongTimeProcessingButton
						caption="登録"
						onClick={register}
						callback={props.onCompleted}
						variant="contained"
						sx={{ mt: 1 }}
					/>
				</Box>
			) : (
				<></>
			)}
		</>
	);
};

export default UserRegistration;
