import {
	Box,
	Divider,
	Grid,
	IconButton,
	InputBase,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import GenreButtons from "./GenreButtons";
import { Header } from "../Components/Header";
import { Map } from "../Components/Map";
import backGround from "../Resources/top-background.png";
import appImage from "../Resources/app-image.png";
import feeFree from "../Resources/fee-free.png";
import people from "../Resources/people.png";
import pictgram from "../Resources/pictgram.png";
import SearchIcon from "@mui/icons-material/Search";
import { JapanMap } from "../Components/JapanMap";
import MatchoStyle from "../Types/MatchoStyle";
import Logo from "../matcho-logo.png";
import { SnsIconButton } from "../Components/SnsIconButton";
import { useState } from "react";
import { toQueryString } from "../Types/SearchCondition";
import { RegionButtons } from "../Components/RegionButtons";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from "react-share";

type Props = {};

const Top: React.FC<Props> = (props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));
	const navigate = useNavigate();
	const [searchWord, setSearchWord] = useState("");

	const handleChangeSearchWord = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchWord(e.target.value);
	};

	const handleClickSearch = () => {
		if (!searchWord) return;

		navigate({
			pathname: "/matchos",
			search: toQueryString({
				searchWord: searchWord,
			}).toString(),
		});
	};

	const handleClickGenreButton = (genreId: string) => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				genreIds: [genreId],
			}).toString(),
		});
	};

	const handleClickArea = (areaId: string) => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				areaIds: [areaId],
			}).toString(),
		});
	};

	return (
		<>
			<Box
				style={{
					overflowX: "hidden",
				}}
			>
				<Box
					sx={{
						height: 550,
						textAlign: "center",
						backgroundImage: `url(${backGround})`,
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						position: "relative",
					}}
				>
					<Header />
					<Typography
						variant="h4"
						color="white"
						sx={{
							fontWeight: "bold",
							textAlign: "center",
							fontSize: 32,
							pt: 5,
							color: MatchoStyle.color.matchoPink,
							// textShadow: "2px 2px 15px #999999",
						}}
					>
						自分にぴったりのトレーニングを見つけよう!
						<br />
					</Typography>
					<Typography
						variant="h4"
						color="white"
						sx={{
							fontWeight: "bold",
							textAlign: "center",
							pb: 2,
							fontSize: 18,
							color: MatchoStyle.color.matchoPink,
							// textShadow: "2px 2px 15px #999999",
						}}
					>
						MATCHING THE BEST FITNESS FOR YOU!!
					</Typography>
					{matches ? (
						<Box display={"inline-grid"} position={"absolute"} bottom={0} right={5}>
							<FacebookShareButton url={"https://matcho.jp"}>
								<FacebookIcon size={32} round />
							</FacebookShareButton>
							<TwitterShareButton url={"https://matcho.jp"}>
								<TwitterIcon size={32} round />
							</TwitterShareButton>
						</Box>
					) : (
						<></>
					)}
					<img
						src={appImage}
						alt="Matcho sample"
						style={{
							position: "absolute",
							top: "258px",
							left: 0,
							right: -50,
							margin: "auto",
							zIndex: 1,
						}}
					/>
					<Box
						sx={{
							pl: 1,
							pr: 1,
							maxWidth: MatchoStyle.size.contentMaxWidth,
							position: "absolute",
							zIndex: 3,
							margin: "0 auto",
							left: 0,
							right: 0,
							bottom: -25,
						}}
					>
						<Box sx={{ maxWidth: 560, margin: "0 auto" }}>
							<Paper
								component="form"
								elevation={0}
								sx={{
									p: "2px 4px",
									display: "flex",
									alignItems: "center",
									borderStyle: "solid",
									borderColor: MatchoStyle.color.matchoOrange,
								}}
							>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder="トレーナ名、ジム名、最寄り駅など"
									inputProps={{ "aria-label": "search google maps" }}
									value={searchWord}
									onChange={handleChangeSearchWord}
								/>
								<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
								<IconButton
									type="submit"
									sx={{ p: "10px" }}
									aria-label="search"
									onClick={handleClickSearch}
								>
									<SearchIcon />
								</IconButton>
							</Paper>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						backgroundColor: MatchoStyle.color.matchoOrange,
						textAlign: "center",
						zIndex: 2,
						position: "relative",
						pt: 4,
					}}
				>
					<Typography
						variant="h6"
						color="white"
						sx={{ padding: MatchoStyle.size.space, fontWeight: "bold" }}
					>
						ジャンルから検索
					</Typography>
					<Box
						sx={{
							maxWidth: MatchoStyle.size.contentMaxWidth,
							margin: "0 auto",
							pb: 8,
							px: 1,
						}}
					>
						<GenreButtons onClick={handleClickGenreButton} />
					</Box>
				</Box>
				<Box
					sx={{
						backgroundColor: "white",
						textAlign: "center",
					}}
				>
					<Typography
						variant="h6"
						color={MatchoStyle.color.matchoOrange}
						sx={{ p: 8, fontWeight: "bold" }}
					>
						エリアから検索
					</Typography>
					<Box
						sx={{
							maxWidth: MatchoStyle.size.contentMaxWidth,
							margin: "0 auto",
							pb: 4,
							px: 1,
						}}
					>
						<RegionButtons onClickArea={handleClickArea} />
					</Box>
					<Box sx={{ pb: 8, px: 1, backgroundColor: "white" }}>
						<JapanMap />
					</Box>
				</Box>
				<Box
					sx={{
						backgroundColor: MatchoStyle.color.matchoPink,
						textAlign: "center",
					}}
				>
					<Typography
						variant="h6"
						color="white"
						sx={{ padding: MatchoStyle.size.space, fontWeight: "bold" }}
					>
						マップから検索
					</Typography>
				</Box>
				<Box sx={{ p: 1, backgroundColor: "white" }}>
					<Map height={window.innerHeight / 2} />
				</Box>
				<Box sx={{ p: 1, mt: 8 }}>
					<Grid container spacing={10}>
						<Grid item xs={12} md={4}>
							<Box textAlign={"center"} maxWidth={400} margin={"0 auto"}>
								<img src={feeFree} alt={"fee free"} height={200} />
								<Typography variant="h4" fontWeight={"bold"}>
									検索無料！掲載無料！
									<br />
									登録最短2分！
									<br />
								</Typography>
								<Typography variant="body1" mt={1}>
									無料でトレーナー、インストラクター、ジム、スポーツチーム、スタジオなどを検索！個人も施設、団体も掲載したい方も無料で掲載！登録は簡単！最短2分で完了！
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} md={4}>
							<Box textAlign={"center"} maxWidth={400} margin={"0 auto"}>
								<img src={people} alt={"people"} height={200} />
								<Typography variant="h4" fontWeight={"bold"}>
									日本最大級の
									<br />
									施設および個人紹介数！
								</Typography>
								<Typography variant="body1" mt={1}>
									トレーナー、インストラクター、コーチなど個人の掲載数は日本最大！個人だけでなく、ジム、教室、チームなどの施設・団体も掲載最大級！
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} md={4}>
							<Box textAlign={"center"} maxWidth={400} margin={"0 auto"}>
								<img src={pictgram} alt={"pictgram"} height={200} />
								<Typography variant="h4" fontWeight={"bold"}>
									日本最大級の
									<br />
									ジャンル数を掲載！
								</Typography>
								<Typography variant="body1" mt={1}>
									個人の健康の為に探したい、本気で大会出場を目指したい、趣味でスポーツを始めたい、子どもの習い事を探したい、一流の人に教わりたい、指導者になる資格を取りたいなど誰のニーズにも合致する幅広いジャンルを掲載！
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box
					sx={{
						backgroundColor: "white",
						textAlign: "center",
					}}
				>
					<Stack direction="column" justifyContent="space-between" alignItems="center">
						<img src={Logo} alt="Matcho logo" style={{ margin: 48 }} width={198} height={55} />
						<Grid container spacing={3} direction="row" sx={{ mb: 6 }} justifyContent={"center"}>
							<Grid item>
								<SnsIconButton sns="twitter" value="MATCHO_admi" size={24} />
							</Grid>
							<Grid item>
								<SnsIconButton sns="instagram" value="matcho_official_" size={24} />
							</Grid>
							<Grid item>
								<SnsIconButton sns="youTube" value="UC7iA8x8M5ZGHYjnEvGR83dg" size={24} />
							</Grid>
						</Grid>
						<Stack direction="row" spacing={3} sx={{ marginTop: "auto", mb: 8 }}>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="/inquiry.html"
								style={{ textDecoration: "none" }}
							>
								<Typography variant="overline" fontSize={9}>
									お問い合わせ
								</Typography>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="/company.html"
								style={{ textDecoration: "none" }}
							>
								<Typography variant="overline" fontSize={9}>
									会社概要
								</Typography>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="/termsofservice.html"
								style={{ textDecoration: "none" }}
							>
								<Typography variant="overline" fontSize={9}>
									利用規約
								</Typography>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="/privacypolicy.html"
								style={{ textDecoration: "none" }}
							>
								<Typography variant="overline" fontSize={9}>
									プライバシーポリシー
								</Typography>
							</a>
						</Stack>
					</Stack>
				</Box>
			</Box>
		</>
	);
};

export default Top;
