import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import MatchoStyle from "../Types/MatchoStyle";

type Props = {
	caption: string;
	dividerAtTop?: boolean;
	dividerAtBottom?: boolean;
	className?: string;
};

export const CaptionedItem: React.FC<Props> = (props) => {
	return (
		<>
			{props.dividerAtTop ? <Divider /> : <></>}
			<Box sx={{ mt: 1 }}>
			<Typography
				variant="caption"
				sx={{ color: MatchoStyle.color.matchoPink, fontWeight: "bold" }}
			>
				{props.caption}
			</Typography>
			</Box>
			<Box sx={{ mt: 1, mb: 2 }} className={props.className}>
				{props.children}
			</Box>
			{props.dividerAtBottom ? <Divider /> : <></>}
		</>
	);
};

CaptionedItem.defaultProps = {
	dividerAtTop: false,
	dividerAtBottom: false,
};
