import { Box, Chip } from "@mui/material";
import React from "react";

type Props = {};

export const MyPageMarker: React.FC<Props> = (props) => {
	return (
		<Box
			bgcolor={"rgba(0,0,0,0.08)"}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			p={1}
			my={2}
		>
			<Chip label="マイページ" variant="outlined" />
			<Box display={"flex"}>
                {props.children}
			</Box>
		</Box>
	);
};
