import React, { forwardRef, useImperativeHandle, useRef } from "react";
import ImageSelector from "../../Atoms/ImageSelector";
import { Notifier } from "../../Types/Notifier";
import { Validator } from "../../Types/Validator";
import { FormItem } from "./FormItem";

type Props = {
	onSelected: (index: number, base64: string) => void;
	onRemove: (index: number) => void;
	src?: (string | undefined)[];
};

const FormMultipleImageSelectorRef: React.ForwardRefRenderFunction<Validator, Props> = (
	{ onSelected, onRemove, src },
	ref
) => {
	const formItemRef = useRef<Notifier>(null);

	// 親から呼ばれる関数
	useImperativeHandle(
		ref,
		() => ({
			validate() {
				return true;
			},
		}),
		[]
	);

	return (
		<FormItem label="ギャラリー" ref={formItemRef}>
			<ImageSelector
				onSelect={(base64) => onSelected(0, base64)}
				onRemove={() => onRemove(0)}
				src={src && src[0]}
				variant="rounded"
			/>
			<ImageSelector
				onSelect={(base64) => onSelected(1, base64)}
				onRemove={() => onRemove(1)}
				src={src && src[1]}
				variant="rounded"
			/>
			<ImageSelector
				onSelect={(base64) => onSelected(2, base64)}
				onRemove={() => onRemove(2)}
				src={src && src[2]}
				variant="rounded"
			/>
			<ImageSelector
				onSelect={(base64) => onSelected(3, base64)}
				onRemove={() => onRemove(3)}
				src={src && src[3]}
				variant="rounded"
			/>
			<ImageSelector
				onSelect={(base64) => onSelected(4, base64)}
				onRemove={() => onRemove(4)}
				src={src && src[4]}
				variant="rounded"
			/>
			<ImageSelector
				onSelect={(base64) => onSelected(5, base64)}
				onRemove={() => onRemove(5)}
				src={src && src[5]}
				variant="rounded"
			/>
			<ImageSelector
				onSelect={(base64) => onSelected(6, base64)}
				onRemove={() => onRemove(6)}
				src={src && src[6]}
				variant="rounded"
			/>
			<ImageSelector
				onSelect={(base64) => onSelected(7, base64)}
				onRemove={() => onRemove(7)}
				src={src && src[7]}
				variant="rounded"
			/>
			<ImageSelector
				onSelect={(base64) => onSelected(8, base64)}
				onRemove={() => onRemove(8)}
				src={src && src[8]}
				variant="rounded"
			/>
			<ImageSelector
				onSelect={(base64) => onSelected(9, base64)}
				onRemove={() => onRemove(9)}
				src={src && src[9]}
				variant="rounded"
			/>
		</FormItem>
	);
};

export const FormMultipleImageSelector = forwardRef(FormMultipleImageSelectorRef);
