import React from "react";
import { useParams } from "react-router-dom";
import { Frame } from "../Components/Frame";
import { GymForm } from "../Components/GymForm";
import { NavigatorByAuthStatus } from "../Components/NavigatorByAuthStatus";
import { MatchoType } from "../Types/MatchoType";

type Props = {
	mode: "new" | "edit";
};

export const GymEditor: React.FC<Props> = (props) => {
	type Params = {
		gymId?: string;
	};

	const params: Params = useParams<Params>();

	return (
		<NavigatorByAuthStatus filter={MatchoType.GymOwner}>
			<Frame>
				<GymForm mode={props.mode} id={params.gymId} />
			</Frame>
		</NavigatorByAuthStatus>
	);
};
