import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HorizonalCard } from "../Atoms/HorizonalCard";
import { maxios } from "../MaxiosProvider";
import RegionGetResponseModel from "../Types/RegionGetResponseModel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { PrefectureChips } from "./PrefectureChips";

type Props = {
	onClick?: (regionId: string) => void;
	onClickPrefecture?: (prefectureId: string) => void;
	onClickArea?: (areaId: string) => void;
};

export const RegionButtons: React.FC<Props> = (props) => {
	const [regions, setRegions] = useState<RegionGetResponseModel[]>([]);
	const [state, setState] = useState({ open: false, selected: "" });

	useEffect(() => {
		let ignore = false;

		maxios.get<RegionGetResponseModel[]>(`/regions`).then((res) => {
			if (!ignore) setRegions(res.data);
		});

		return () => {
			ignore = true;
		};
	}, []);

	const handleClick = (regionId: string) => () => {
		if (props.onClick) {
			props.onClick(regionId);
		} else {
			setState({ open: true, selected: regionId });
		}
	};

	const handelClose = () => {
		setState({ open: false, selected: "" });
	};

	return (
		<>
			<Grid container spacing={1}>
				{regions.map((region, index) => {
					return (
						<Grid item key={index} xs={3} sm={2}>
							<HorizonalCard
								label={region.name}
								border="1px solid lightgray"
								size={40}
								onClick={handleClick(region.id)}
							/>
						</Grid>
					);
				})}
			</Grid>
			<Dialog open={state.open} onClose={handelClose}>
				<DialogTitle>
					<IconButton aria-label="back" onClick={handelClose}>
						<ArrowBackIosNewIcon />
					</IconButton>
					都道府県を選択
				</DialogTitle>
				<DialogContent>
					{state.selected && (
						<PrefectureChips
							regionId={state.selected}
							onClick={props.onClickPrefecture}
							onClickArea={props.onClickArea}
						/>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
};
