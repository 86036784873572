import { Box, Paper, Rating, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { Content } from "../Types/Content";
import { AreaChips } from "./AreaChips";
import { GenreChips } from "./GenreChips";
import { getMatchoImageSource } from "../Utilty";
import { CardImage } from "../Atoms/Card/CardImage";
import { RangeDisplay } from "./RangeDisplay";

type Props = {
	content: Content;
	onClick?: (content: Content) => void;
	sx?: SxProps<Theme>;
};

export const SearchResultItem: React.FC<Props> = (props) => {
	const handleClick = () => {
		if (props.onClick) props.onClick(props.content);
	};

	const getSizeNumber = (size?: "large" | "medium" | "small" | "xs") => {
		switch (size) {
			case "large":
				return 108;
			case "medium":
				return 96;
			case "small":
				return 64;
			case "xs":
				return 36;
			default:
				break;
		}
	};

	return (
		<Paper
			onClick={handleClick}
			sx={{
				...props.sx,
				pt: 1,
				pb: 1,
				":hover": {
					bgcolor: "#eeeeee",
					cursor: "pointer",
				},
				borderRadius: "16px",
			}}
			elevation={0}
		>
			<Box sx={{ p: 1 }}>
				<Box sx={{ display: "flex", overflow: "hidden", mb: 1 }}>
					<CardImage
						matchoType={props.content.type}
						matchoSubType={props.content.subType}
						image={props.content.image}
						size={getSizeNumber("medium")}
						alt={props.content.name}
					/>
					<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
						<Typography sx={{ fontWeight: "bold" }}>{props.content.name}</Typography>
						<Rating
							defaultValue={props.content.rating}
							precision={0.5}
							size="small"
							readOnly
							sx={{ my: 1 }}
						/>
						<Box display={"flex"}>
							{props.content.images.map((image, index) => {
								return (
									<img
										src={getMatchoImageSource(image, 70)}
										height={70}
										width={70}
										alt="gallery"
										key={index}
									/>
								);
							})}
						</Box>
					</Box>
				</Box>
				<Box>
					<Typography
						variant="caption"
						sx={{
							width: "100%",
							display: "-webkit-box",
							WebkitBoxOrient: "vertical",
							WebkitLineClamp: 2, // 行数指定
							overflow: "hidden",
						}}
					>
						{props.content.selfIntroduction}
					</Typography>
					<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
						<Typography
							variant="caption"
							sx={{ mr: 1, fontWeight: "bold", flexBasis: 55, flexShrink: 0 }}
						>
							エリア
						</Typography>
						<AreaChips areas={props.content.areas} size="small" />
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
						<Typography
							variant="caption"
							sx={{ mr: 1, fontWeight: "bold", flexBasis: 55, flexShrink: 0 }}
						>
							ジャンル
						</Typography>
						<GenreChips
							items={props.content.genres.map((genre) => {
								return { value: genre.id, label: genre.name };
							})}
							size="small"
						/>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
						<Typography
							variant="caption"
							sx={{ mr: 1, fontWeight: "bold", flexBasis: 55, flexShrink: 0 }}
						>
							サービス
						</Typography>
						<Typography
							variant="caption"
							sx={{
								width: "100%",
								display: "-webkit-box",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: 2, // 行数指定
								overflow: "hidden",
							}}
						>
							{props.content.service}
						</Typography>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
						<Typography variant="caption" sx={{ mr: 1, fontWeight: "bold", flexBasis: 55 }}>
							最寄り駅
						</Typography>
						<Typography variant="caption">{props.content.station}</Typography>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
						<Typography variant="caption" sx={{ mr: 1, fontWeight: "bold", flexBasis: 55 }}>
							体験料金
						</Typography>
						<RangeDisplay
							lowerLimit={
								props.content.trialFeeLowerLimit === null
									? undefined
									: props.content.trialFeeLowerLimit
							}
							upperLimit={
								props.content.trialFeeUpperLimit === null
									? undefined
									: props.content.trialFeeUpperLimit
							}
						/>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
						<Typography variant="caption" sx={{ mr: 1, width: 50, fontWeight: "bold" }}>
							一回
						</Typography>
						<RangeDisplay
							lowerLimit={
								props.content.feePerOnceLowerLimit === null
									? undefined
									: props.content.feePerOnceLowerLimit
							}
							upperLimit={
								props.content.feePerOnceUpperLimit === null
									? undefined
									: props.content.feePerOnceUpperLimit
							}
						/>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
						<Typography variant="caption" sx={{ mr: 1, width: 50, fontWeight: "bold" }}>
							月額料金
						</Typography>
						<RangeDisplay
							lowerLimit={
								props.content.feePerMonthLowerLimit === null
									? undefined
									: props.content.feePerMonthLowerLimit
							}
							upperLimit={
								props.content.feePerMonthUpperLimit === null
									? undefined
									: props.content.feePerMonthUpperLimit
							}
						/>
					</Box>
				</Box>
			</Box>
		</Paper>
	);
};
