import {
	Box,
	Divider,
	Button,
	Typography,
	Paper,
	Grid,
	Dialog,
	DialogContent,
	DialogActions,
} from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useCallback, useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { CaptionedItem } from "../Components/CaptionedItem";
import { Gallery } from "../Components/Gallery";
import { Headline } from "../Components/Headline";
import { Gym } from "../Types/Gym";
import { MatchoType } from "../Types/MatchoType";
import { ReviewDialog } from "./ReviewDialog";
import { ReviewViewer } from "./ReviewViewer";
import { GymLinkButton } from "../Components/GymLinkButton";
import { NamedImage } from "../Components/NamedImage";
import { CaptionedText } from "../Components/CaptionedText";
import { Frame } from "../Components/Frame";
import { LongTimeProcessingButton } from "../Components/LongTimeProcessingButton";
import { MAvatar } from "../Atoms/MAvatar";
import { getMatchoImageSource } from "../Utilty";
import { GenreChips } from "../Components/GenreChips";
import { ApiResponse } from "../Types/ApiResponse";
import { LongTimeProcessing } from "../Components/LongTimeProcessing";
import { ApiResponseStatus } from "../Types/ApiResponseStatus";
import { LabeledIconButton } from "../Atoms/LabeledIconButton";
import { MyPageMarker } from "../Atoms/MyPageMarker";
import { GymResponseModel, toGym } from "../Types/GymResponseModel";
import { FeeTable } from "../Components/FeeTable";
import { InstagramGallery } from "../Components/InstagramGallery";
import { LineInformation } from "../Components/LineInformation";
import { YouTubeGallery } from "../Components/YouTubeGallery";
import { Instagram } from "../Types/Instagram";
import { Youtube } from "../Types/YouTube";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import { Helmet } from "react-helmet-async";

type Props = {};

export const GymView: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const [gym, setGym] = useState<Gym>(null!);
	const [instagrams, setInstagrams] = useState<Instagram[]>();
	const [youTubes, setYouTubes] = useState<Youtube[]>([]);
	const ref = useRef<{ refresh: () => void }>(null);
	const [open, setOpen] = useState(false);

	type Params = {
		gymId?: string;
	};

	const params: Params = useParams<Params>();

	const getGym = useCallback(() => {
		maxios.get<Instagram[]>(`/gyms/${params.gymId}/instagrams`).then((res) => {
			setInstagrams(res.data);
		});

		maxios.get<Youtube[]>(`/gyms/${params.gymId}/youtubes`).then((res) => {
			setYouTubes(res.data);
		});

		return maxios.get<GymResponseModel>(`/gyms/${params.gymId}`).then((res) => {
			if (res.data) {
				setGym(toGym(res.data));
			} else {
				navigate("/notfound");
			}
		});
	}, [params.gymId, navigate]);

	const handleClick = () => {
		navigate(`/gymowners/me/gyms/${gym.id}/edit`);
	};

	const handleClickTrainer = (id: string) => () => {
		navigate(`/trainers/${id}`);
	};

	const handleReviewPosted = () => {
		ref.current?.refresh();
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickDelete = () => {
		return maxios.delete<ApiResponse>(`/gyms/${params.gymId}`).then((res) => {
			return {
				isError: res.data.status === ApiResponseStatus.Success ? false : true,
			};
		});
	};

	const callbackDelete = () => {
		navigate(`/gymowners/me`);
	};

	return (
		<Frame>
			<LongTimeProcessing processing={getGym}>
				{gym && (
					<>
						<Helmet>
							<title>{`${gym.name} - MATCHO`}</title>
        					{gym.introduction && <meta name="description" content={gym.introduction} />}
						</Helmet>
						{authContext.claim?.matchoId === gym.ownerId ? (
							<MyPageMarker>
								<LabeledIconButton label="編集" icon="edit" onClick={handleClick} />
								<LabeledIconButton label="ジムを削除" icon="delete" onClick={handleOpen} />
								<Dialog open={open} onClose={handleClose}>
									<DialogContent>本当に削除しますか？</DialogContent>
									<DialogActions>
										<LongTimeProcessingButton
											caption="削除する"
											onClick={handleClickDelete}
											callback={callbackDelete}
										/>
										<Button onClick={handleClose}>キャンセル</Button>
									</DialogActions>
								</Dialog>
							</MyPageMarker>
						) : (
							<></>
						)}
						<Headline
							matchoType={MatchoType.Gym}
							matchoSubType={gym.gymType}
							image={gym.image}
							name={gym.name}
							rating={gym.rating}
							numberOfComments={gym.numberOfComment}
							facebook={gym.facebook}
							twitter={gym.twitter}
							instagram={gym.instagram}
							website={gym.website}
							youTube={gym.youTube}
							line={gym.line}
							address={`${gym.area.name}${gym.town}`}
							phoneNumber={gym.phoneNumber}
						/>
						<Divider sx={{ mt: 2, mb: 2 }} />
						<Typography variant="body2" sx={{ whiteSpace: "pre-line", overflowWrap: "break-word" }}>
							{gym.introduction}
						</Typography>
						<Gallery
							medias={gym.gallery.map((image) => {
								return { data: getMatchoImageSource(image, 256), alt: undefined };
							})}
						/>
						{(gym.introduction || gym.gallery.length !== 0) && <Divider />}
						{gym.genres.length !== 0 && (
							<CaptionedItem caption="ジャンル" dividerAtBottom>
								<GenreChips
									items={gym.genres.map((genre) => {
										return { value: genre.id, label: genre.name };
									})}
								/>
							</CaptionedItem>
						)}
						<CaptionedText caption="サービス" text={gym.service} dividerAtBottom />
						<Grid container spacing={1}>
							<Grid item xs={6}>
								<CaptionedItem caption="授業方法" dividerAtBottom={false}>
									<Box display="flex" sx={{ color: gym.online ? undefined : "lightgray" }}>
										<DesktopWindowsIcon sx={{ mr: 1 }} />
										<Typography display="flex" alignItems="center" variant="body2">
											{gym.online ? "オンライン可" : "オンライン不可"}
										</Typography>
									</Box>
									<Box display="flex" sx={{ color: gym.onsite ? undefined : "lightgray" }}>
										<AirportShuttleIcon sx={{ mr: 1 }} />
										<Typography display="flex" alignItems="center" variant="body2">
											{gym.onsite ? "出張可" : "出張不可"}
										</Typography>
									</Box>
								</CaptionedItem>
							</Grid>
							<Grid item xs={6}>
								<FeeTable
									feePerOnceLowerLimit={gym.feePerOnceLowerLimit}
									feePerOnceUpperLimit={gym.feePerOnceUpperLimit}
									feePerMonthLowerLimit={gym.feePerMonthLowerLimit}
									feePerMonthUpperLimit={gym.feePerMonthUpperLimit}
									trialFeeLowerLimit={gym.trialFeeLowerLimit}
									trialFeeUpperLimit={gym.trialFeeUpperLimit}
								/>
							</Grid>
						</Grid>
						<Divider />
						<CaptionedText caption="最寄り駅" text={gym.station} dividerAtBottom />
						<CaptionedText caption="営業時間" text={gym.businessHours} dividerAtBottom />
						{gym.staffs.length !== 0 && (
							<CaptionedItem caption="スタッフ情報" dividerAtBottom>
								<Grid container spacing={1}>
									{gym.staffs.map((staff, index) => {
										return (
											<Grid item xs={12} sm={6} key={index}>
												<Paper>
													<Box sx={{ p: 1 }}>
														<Box display="flex">
															<MAvatar src={staff.image} sx={{ mr: 1 }} />
															<Typography
																display="flex"
																alignItems="center"
																sx={{ fontWeight: "bold" }}
															>
																{staff.name}
															</Typography>
														</Box>
														<CaptionedText
															caption="サービス"
															text={staff.introduction}
															dividerAtBottom={false}
														/>
														<CaptionedText
															caption="経歴"
															text={staff.career}
															dividerAtTop={false}
															dividerAtBottom={false}
														/>
														<CaptionedText
															caption="資格"
															text={staff.license}
															dividerAtBottom={false}
														/>
													</Box>
												</Paper>
											</Grid>
										);
									})}
								</Grid>
							</CaptionedItem>
						)}
						<CaptionedItem caption="このジムを拠点とするトレーナー" dividerAtBottom>
							<Box sx={{ display: "flex" }}>
								{gym.linkedTrainers.map((trainer, index) => (
									<NamedImage
										name={trainer.name}
										image={trainer.image}
										handleClick={handleClickTrainer(trainer.id)}
										key={index}
									/>
								))}
							</Box>
							{authContext.claim?.type === MatchoType.Trainer && (
								<GymLinkButton recipientId={gym.id} />
							)}
						</CaptionedItem>
						{instagrams && instagrams.length !== 0 && (
							<CaptionedItem caption="Instagram" dividerAtBottom>
								<InstagramGallery posts={instagrams} />
							</CaptionedItem>
						)}
						{youTubes && youTubes.length !== 0 && (
							<CaptionedItem caption="YouTube" dividerAtBottom>
								<YouTubeGallery posts={youTubes} />
							</CaptionedItem>
						)}
						{gym.line && (
							<CaptionedItem caption="LINE" dividerAtBottom>
								<LineInformation lineId={gym.line} />
							</CaptionedItem>
						)}
						<CaptionedItem caption="コメント" dividerAtBottom={false}>
							<ReviewViewer revieweeId={gym.id} ref={ref} />
							<Box sx={{ textAlign: "center" }}>
								<ReviewDialog revieweeId={gym.id} onPosted={handleReviewPosted} />
							</Box>
						</CaptionedItem>
					</>
				)}
			</LongTimeProcessing>
		</Frame>
	);
};
