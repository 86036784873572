import { Box, Typography } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card } from "../Atoms/Card/Card";
import { Frame } from "../Components/Frame";
import { LongTimeProcessing } from "../Components/LongTimeProcessing";
import { LongTimeProcessingButton } from "../Components/LongTimeProcessingButton";
import { MultipleTrainerLinkButton } from "../Components/MultipleTrainerLinkButton";
import { Gym } from "../Types/Gym";
import {
	ApplicationDestination,
	createApplicationDestinations,
	TrainerGymLinks,
} from "../Types/TrainerGymLinks";
import { ApiResponse } from "../Types/ApiResponse";
import { ApiResponseStatus } from "../Types/ApiResponseStatus";
import { NavigatorByAuthStatus } from "../Components/NavigatorByAuthStatus";
import { MatchoType } from "../Types/MatchoType";

type Props = {};

export const TrainerLinkEditor: React.FC<Props> = (props) => {
	type Params = {
		gymId?: string;
	};
	const params: Params = useParams<Params>();

	const [applicationDestinations, setApplicationDestinations] = useState<ApplicationDestination[]>(
		[]
	);

	const [gym, setGym] = useState<Gym>();

	const refresh = useCallback(() => {
		return maxios
			.get<TrainerGymLinks>(`/gymowners/me/gyms/${params.gymId}/trainerlinks`)
			.then((res) => {
				setApplicationDestinations(createApplicationDestinations(res.data));
			});
	}, [params.gymId]);

	useEffect(() => {
		maxios.get<Gym>(`/gyms/${params.gymId}`).then((res) => {
			setGym(res.data);
		});
	}, [params.gymId]);

	const handleClickApprove = (recipientId: string) => {
		return maxios
			.put<ApiResponse>(`/gymowners/me/gyms/${params.gymId}/trainerlinks/${recipientId}/approve`)
			.then((res) => {
				switch (res.data.status) {
					case ApiResponseStatus.Success:
						return { isError: false };
					default:
						return { isError: true };
				}
			});
	};

	const handleClickDeny = (recipientId: string) => {
		return maxios
			.put<ApiResponse>(`/gymowners/me/gyms/${params.gymId}/trainerlinks/${recipientId}/deny`)
			.then((res) => {
				switch (res.data.status) {
					case ApiResponseStatus.Success:
						return { isError: false };
					default:
						return { isError: true };
				}
			});
	};

	const handleClickUnlink = (recipientId: string) => {
		return maxios
			.delete<ApiResponse>(`/gymowners/me/gyms/${params.gymId}/trainerlinks/${recipientId}`)
			.then((res) => {
				switch (res.data.status) {
					case ApiResponseStatus.Success:
						return { isError: false };
					default:
						return { isError: true };
				}
			});
	};

	return (
		<NavigatorByAuthStatus filter={MatchoType.GymOwner}>
			<Frame>
				<LongTimeProcessing processing={refresh}>
					{gym && <MultipleTrainerLinkButton gym={gym} onClose={refresh} />}
					<Box sx={{ my: 2 }}>
						{applicationDestinations.map((l, i) => {
							return (
								<Card name={l.name} image={l.image} key={i} sx={{ mb: 1 }}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Typography sx={{ mt: "auto", mb: "auto" }}>{l.text}</Typography>
										<Box sx={{ ml: "auto" }}>
											{l.canApprove && (
												<LongTimeProcessingButton
													caption="リンク"
													onClick={() => handleClickApprove(l.id)}
													callback={refresh}
													variant="contained"
													sx={{ mr: 1, my: 1 }}
												/>
											)}
											{l.canDeny && (
												<LongTimeProcessingButton
													caption="ブロック"
													onClick={() => handleClickDeny(l.id)}
													callback={refresh}
													variant="contained"
													sx={{ mr: 1, my: 1 }}
												/>
											)}
											{l.canUnlink && (
												<LongTimeProcessingButton
													caption="リンク解除"
													onClick={() => handleClickUnlink(l.id)}
													callback={refresh}
													variant="contained"
													sx={{ mr: 1, my: 1 }}
												/>
											)}
										</Box>
									</Box>
								</Card>
							);
						})}
					</Box>
				</LongTimeProcessing>
			</Frame>
		</NavigatorByAuthStatus>
	);
};
