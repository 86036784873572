import { IconButton, Typography } from "@mui/material";
import React, {
	createRef,
	forwardRef,
	useCallback,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { FormTextInput } from "./FormTextInput";
import { Gender } from "../..//Types/Gender";
import { GymStaff } from "../../Types/GymStaff";
import { getCastedValue } from "../../Utilty";
import { Form } from "./Form";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Notifier } from "../../Types/Notifier";
import { Validator } from "../../Types/Validator";
import { FormItem } from "./FormItem";
import { FormImageSelector } from "./FormImageSelector";
import { FormGenderRadioButton } from "./FormGenderRadioButton";

type Props = {
	gymId?: string;
	staffs: GymStaff[];
	onChange: (staffs: GymStaff[]) => void;
	maxNumberOfStaffs: number;
};

export const FormGymStaffRef: React.ForwardRefRenderFunction<Validator, Props> = (
	{ gymId, staffs, onChange, maxNumberOfStaffs },
	ref
) => {
	const formItemRef = useRef<Notifier>(null);
	const [gymStaffs, setGymStaffs] = useState<GymStaff[]>(staffs);

	// refを動的に生成
	const validatorRefs = useRef(
		gymStaffs.map(() => {
			return createRef<Validator>();
		})
	);

	const handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
		let cloned = JSON.parse(JSON.stringify(gymStaffs)) as GymStaff[];

		let targetStaff = cloned.find((s, i) => i === index);

		let propertyName = event.target.name as
			| "name"
			| "introduction"
			| "license"
			| "career"
			| "gender";

		targetStaff![propertyName] = getCastedValue(event);

		setGymStaffs(cloned);
	};

	const updateImage = (index: number) => (base64: string) => {
		let cloned = JSON.parse(JSON.stringify(gymStaffs)) as GymStaff[];

		let targetStaff = cloned.find((s, i) => i === index);

		targetStaff!.image = base64;

		setGymStaffs(cloned);
	};

	const handleClickAdd = () => {
		if (gymStaffs.length >= maxNumberOfStaffs) return;

		validatorRefs.current.push(createRef<Validator>());

		setGymStaffs(
			gymStaffs.concat({
				id: undefined,
				gymId: gymId,
				name: "",
				gender: Gender.Other,
				image: undefined,
				introduction: "",
				license: "",
				career: "",
			})
		);
	};

	const handleClickRemove = (index: number) => () => {
		validatorRefs.current.splice(index, 1);

		let cloned = JSON.parse(JSON.stringify(gymStaffs)) as GymStaff[];
		let removed = cloned.filter((s, i) => i !== index);
		setGymStaffs(removed);
		onChange(removed);
	};

	const _validate = useCallback((doBlink: boolean) => {
		let valid = true;

		validatorRefs.current?.forEach((validator) => {
			if (!validator.current?.validate()) {
				valid = false;
			}
		});

		return valid;
	}, []);

	// 親から呼ばれる関数
	useImperativeHandle(
		ref,
		() => ({
			validate() {
				return _validate(true);
			},
		}),
		[_validate]
	);

	return (
		<FormItem label="ジムスタッフ" ref={formItemRef} bodyPadding={0}>
			<Form style={{ width: "100%" }} onBlur={() => onChange(gymStaffs)}>
				{gymStaffs.map((staff, index) => {
					return (
						<FormItem label={index.toString()} key={index} bodyPadding={0} border invisibleCaption>
							<Form style={{ width: "100%" }} ref={validatorRefs.current[index]}>
								<FormImageSelector onSelected={updateImage(index)} src={staff.image} captionWidth={60} required />
								<FormTextInput
									label="お名前"
									name="name"
									value={staff.name}
									onChange={handleChange(index)}
									sx={{ maxWidth: "20rem" }}
									maxLength={20}
									required
								/>
								<FormGenderRadioButton
									name="gender"
									value={staff.gender}
									onChange={handleChange(index)}
									required
								/>
								<FormTextInput
									label="自己紹介"
									name="introduction"
									value={staff.introduction}
									onChange={handleChange(index)}
									multiline
									maxLength={256}
								/>
								<FormTextInput
									label="資格"
									name="license"
									value={staff.license}
									onChange={handleChange(index)}
									multiline
									maxLength={256}
								/>
								<FormTextInput
									label="経歴"
									name="career"
									value={staff.career}
									onChange={handleChange(index)}
									multiline
									maxLength={256}
								/>
							</Form>
							<IconButton onClick={handleClickRemove(index)}>
								<DeleteIcon />
								<Typography>削除</Typography>
							</IconButton>
						</FormItem>
					);
				})}
			</Form>
			{gymStaffs.length < maxNumberOfStaffs && (
				<IconButton onClick={handleClickAdd}>
					<AddCircleOutlineIcon />
					<Typography>追加</Typography>
				</IconButton>
			)}
		</FormItem>
	);
};

export const FormGymStaff = forwardRef(FormGymStaffRef);
