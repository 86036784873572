import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useState } from "react";
import { JapanMapSVG } from "./JapanMapSVG";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { maxios } from "../MaxiosProvider";
import Area from "../Types/Area";
import { AreaChips } from "./AreaChips";

type Props = {};

export const JapanMap: React.FC<Props> = (props) => {
	const [open, setOpen] = useState(false);
	const [areas, setAreas] = useState<Area[] | undefined>(undefined);

	const openArea = (prefecture: string) => {
		maxios.get<Area[]>(`/Areas?prefectureId=${prefecture}`).then((res) => {
			setAreas(res.data);
		});
		setOpen(true);
	};

	const closeArea = () => {
		setOpen(false);
		setAreas(undefined);
	};

	return (
		<>
			<JapanMapSVG onClick={openArea} />
			<Dialog open={open} onClose={closeArea}>
				<DialogTitle>
					<IconButton aria-label="back" onClick={closeArea}>
						<ArrowBackIosNewIcon />
					</IconButton>
					エリアを選択
				</DialogTitle>
				<DialogContent>{areas && <AreaChips areas={areas} />}</DialogContent>
			</Dialog>
		</>
	);
};
