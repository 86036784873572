import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { MatchoType } from "../Types/MatchoType";
import { CaptionedIcon } from "../Atoms/CaptionedIcon";
import traineeIcon from "../Resources/trainee.svg";
import trainerIcon from "../Resources/trainer.svg";
import gymIcon from "../Resources/gym.svg";
import MatchoStyle from "../Types/MatchoStyle";

type Props = {
	onSelected: (matchoType: MatchoType) => void;
};

const TypeSelector: React.FC<Props> = (props) => {
	return (
		<Box sx={{ textAlign: "center" }}>
			<Typography sx={{ m: 5 }}>登録するタイプを選んでください</Typography>
			<Grid container spacing={2} justifyContent="center">
				<Grid item>
					<CaptionedIcon
						src={traineeIcon}
						caption="トレーニー"
						color={MatchoStyle.color.matchoBlue}
						onClick={() => {
							props.onSelected(MatchoType.Trainee);
						}}
					/>
				</Grid>
				<Grid item>
					<CaptionedIcon
						src={trainerIcon}
						caption="トレーナー"
						color={MatchoStyle.color.matchoPink}
						onClick={() => {
							props.onSelected(MatchoType.Trainer);
						}}
					/>
				</Grid>
				<Grid item>
					<CaptionedIcon
						src={gymIcon}
						caption="ジムオーナー"
						color={MatchoStyle.color.matchoOrange}
						onClick={() => {
							props.onSelected(MatchoType.GymOwner);
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default TypeSelector;
