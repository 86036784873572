import { Box } from "@mui/material";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import React, { useRef, useState } from "react";
import { FormTextInput } from "./Form/FormTextInput";
import { createErrorResult, createSuccessResult, LongTimeProcessingButton } from "./LongTimeProcessingButton";
import { Form } from "./Form/Form";
import { Validator } from "../Types/Validator";

type Props = {};

const EmailAndPasswordRegistration: React.FC<Props> = (props) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const ref = useRef<Validator>(null);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	const register = () => {
		if (!ref.current?.validate()) {
			return (async () => {
				return { isError: true };
			})();
		}

		// 認証状態が変更されることで親画面の副作用フックが実行される。そのため次画面へ遷移する処理は不要。
		return createUserWithEmailAndPassword(getAuth(), email, password)
			.then(() => {
				// return { isError: false };
				return createSuccessResult();
			})
			.catch((error: Error) => {
				if(error.message === "Firebase: Error (auth/email-already-in-use).") {
					return createErrorResult("このメールアドレスは既に登録されています。");
				}

				throw new Error();
			});
	};

	return (
		<Box sx={{ textAlign: "center" }}>
			<Form style={{ width: "100%" }} ref={ref}>
				<FormTextInput
					label="メールアドレス"
					name="email"
					type="email"
					value={email}
					onChange={handleChange}
					sx={{ maxWidth: "20rem" }}
					required
					maxLength={128}
					captionWidth={100}
				/>
				<FormTextInput
					label="パスワード"
					name="password"
					type="password"
					value={password}
					onChange={handleChangePassword}
					sx={{ maxWidth: "20rem" }}
					minLength={6}
					maxLength={32}
					required
				/>
			</Form>
			<LongTimeProcessingButton caption="登録" onClick={register} sx={{ mt: 1 }} variant="contained" />
		</Box>
	);
};

export default EmailAndPasswordRegistration;
