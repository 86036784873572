import { Box, Typography } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useCallback, useEffect, useState } from "react";
import { Card } from "../Atoms/Card/Card";
import { Frame } from "../Components/Frame";
import { LongTimeProcessingButton } from "../Components/LongTimeProcessingButton";
import { LongTimeProcessing } from "../Components/LongTimeProcessing";
import { MultipleGymLinkButton } from "../Components/MultipleGymLinkButton";
import { Trainer } from "../Types/Trainer";
import {
	ApplicationDestination,
	createApplicationDestinations,
	TrainerGymLinks,
} from "../Types/TrainerGymLinks";
import { NavigatorByAuthStatus } from "../Components/NavigatorByAuthStatus";
import { MatchoType } from "../Types/MatchoType";

type Props = {};

export const GymLinkEditor: React.FC<Props> = (props) => {
	const [applicationDestinations, setApplicationDestinations] = useState<ApplicationDestination[]>(
		[]
	);
	const [trainer, setTrainer] = useState<Trainer>();

	const refresh = useCallback(() => {
		return maxios.get<TrainerGymLinks>(`/trainers/me/gymlinks`).then((res) => {
			setApplicationDestinations(createApplicationDestinations(res.data));
		});
	}, []);

	useEffect(() => {
		maxios.get<Trainer>(`/Trainers/me`).then((res) => {
			setTrainer(res.data);
		});
	}, []);

	const handleClickApprove = (recipientId: string) => {
		return maxios.put(`/trainers/me/gymlinks/${recipientId}/approve`).then(() => {
			return { isError: false };
		});
	};

	const handleClickDeny = (recipientId: string) => {
		return maxios.put(`/trainers/me/gymlinks/${recipientId}/deny`).then(() => {
			return { isError: false };
		});
	};

	const handleClickUnlink = (recipientId: string) => {
		return maxios.delete(`/trainers/me/gymlinks/${recipientId}`).then(() => {
			return { isError: false };
		});
	};

	return (
		<NavigatorByAuthStatus filter={MatchoType.Trainer}>
			<Frame>
				<LongTimeProcessing processing={refresh}>
					{trainer && <MultipleGymLinkButton trainer={trainer} onClose={refresh} />}
					<Box sx={{ my: 2 }}>
						{applicationDestinations.map((l, i) => {
							return (
								<Card name={l.name} image={l.image} key={i} sx={{ mb: 1 }}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Typography sx={{ mt: "auto", mb: "auto" }}>{l.text}</Typography>
										<Box sx={{ ml: "auto" }}>
											{l.canApprove && (
												<LongTimeProcessingButton
													caption="リンク"
													onClick={() => handleClickApprove(l.id)}
													callback={refresh}
													variant="contained"
													sx={{ mr: 1, my: 1 }}
												/>
											)}
											{l.canDeny && (
												<LongTimeProcessingButton
													caption="ブロック"
													onClick={() => handleClickDeny(l.id)}
													callback={refresh}
													variant="contained"
													sx={{ mr: 1, my: 1 }}
												/>
											)}
											{l.canUnlink && (
												<LongTimeProcessingButton
													caption="リンク解除"
													onClick={() => handleClickUnlink(l.id)}
													callback={refresh}
													variant="contained"
													sx={{ mr: 1, my: 1 }}
												/>
											)}
										</Box>
									</Box>
								</Card>
							);
						})}
					</Box>
				</LongTimeProcessing>
			</Frame>
		</NavigatorByAuthStatus>
	);
};
