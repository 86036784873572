import { Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { maxios } from "../MaxiosProvider";
import { TrainerGymLinks } from "../Types/TrainerGymLinks";
import { AuthContext } from "../AuthContext";
import { MatchoType } from "../Types/MatchoType";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";

type Props = {
	recipientId: string;
};

export const GymLinkButton: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const [myTrainerGymLinks, setMyTrainerGymLinks] = useState<TrainerGymLinks>();
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		if (!authContext.claim) return;
		if (authContext.claim.type !== MatchoType.Trainer) return;

		refresh();
	}, [authContext.claim]);

	// 状態に関係なくリクエストがまったくなければ当該ボタンを表示します
	useEffect(() => {
		if (!myTrainerGymLinks) return;

		let sentRequests = myTrainerGymLinks?.sent.find((l) => l.matcho.id === props.recipientId);
		let receivedRequests = myTrainerGymLinks?.received.find(
			(l) => l.matcho.id === props.recipientId
		);

		if (sentRequests || receivedRequests) {
			setVisible(false);
		} else {
			setVisible(true);
		}
	}, [myTrainerGymLinks, props.recipientId]);

	const requestLink = () => {
		return maxios.post(`/trainers/me/gymlinks/${props.recipientId}/apply`).then(() => {
			return { isError: false, message: "リンクをリクエストしました。" };
		});
	};

	const refresh = () => {
		return maxios.get<TrainerGymLinks>(`/trainers/me/gymlinks`).then((res) => {
			setMyTrainerGymLinks(res.data);
		});
	};

	return (
		<>
			{visible && (
				<LongTimeProcessingButton onClick={requestLink} callback={refresh}>
					<AddLinkIcon sx={{ mr: 1 }} />
					<Typography variant="body2">リンクをリクエストする</Typography>
				</LongTimeProcessingButton>
			)}
		</>
	);
};
