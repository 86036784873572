import { Typography } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { Review } from "../Types/Review";
import { ReviewCard } from "./ReviewCard";

type Props = {
	revieweeId: string;
};

export const ReviewViewerRef: React.ForwardRefRenderFunction<{ refresh: () => void }, Props> = (
	props,
	ref
) => {
	const [reviews, setReviews] = useState<Review[]>();

	const refresh = useCallback(() => {
		maxios.get<Review[]>(`/reviews?RevieweeId=${props.revieweeId}`).then((res) => {
			setReviews(
				res.data.map((review) => ({
					...review,
					posted: new Date(review.posted),
				}))
			);
		});
	}, [props.revieweeId]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	// 親から呼ばれる関数
	useImperativeHandle(
		ref,
		() => ({
			refresh() {
				return refresh();
			},
		}),
		[refresh]
	);

	return (
		<>
			{reviews && reviews.length > 0 ? (
				reviews.map((review, index) => <ReviewCard key={index} review={review} />)
			) : (
				<Typography variant="body2">
					コメントはまだありません。最初のコメントを投稿しませんか？
				</Typography>
			)}
		</>
	);
};

export const ReviewViewer = forwardRef(ReviewViewerRef);
