import { IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { MAvatar } from "../Atoms/MAvatar";

type Props = {
	name: string;
	image?: string;
	handleClick: () => void;
};

export const NamedImage: React.FC<Props> = (props) => {
	return (
		<Tooltip title={props.name}>
			<IconButton onClick={props.handleClick} sx={{ display: "flex", flexDirection: "column" }}>
				<MAvatar src={props.image} size={64} />
				<div style={{ width: 72 }}>
					<Typography
						variant="caption"
						sx={{
							fontSize: 11,
							width: "100%",
							display: "-webkit-box",
							WebkitBoxOrient: "vertical",
							WebkitLineClamp: 2, // 行数指定
							overflow: "hidden",
						}}
					>
						{props.name}
					</Typography>
				</div>
			</IconButton>
		</Tooltip>
	);
};
