import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Rating } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useContext, useRef } from "react";
import { FormTextInput } from "../Components/Form/FormTextInput";
import { LongTimeProcessingButton } from "../Components/LongTimeProcessingButton";
import { ReviewPostModel } from "../Types/ReviewPostModel";
import { Validator } from "../Types/Validator";
import { Form } from "../Components/Form/Form";
import { AuthContext } from "../AuthContext";
import { RegistrationStatus } from "../Types/RegistrationStatus";
import { useNavigate } from "react-router-dom";

type Props = {
	revieweeId: string;
	onPosted: () => void;
};

export const ReviewDialog: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const ref = useRef<Validator>(null);

	const [open, setOpen] = React.useState(false);
	const [review, setReview] = React.useState<ReviewPostModel>({
		revieweeId: props.revieweeId,
		rating: 3,
		comment: "",
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setReview({ ...review, [event.target.name]: event.target.value });
	};

	const handelChangeRating = (
		event: React.SyntheticEvent<Element, Event>,
		value: number | null
	) => {
		setReview({ ...review, rating: value ?? 0 });
	};

	const handleClickOpen = () => {
		if(authContext.registrationStatus !== RegistrationStatus.Completed){
			navigate("/signin");
		}

		setOpen(true);
	};

	const handleClickPost = () => {
		if (!ref.current?.validate()) {
			return (async () => {
				return { isError: true };
			})();
		}

		return maxios.post("/Reviews", review).then(() => {
			return { isError: false, message: "登録しました！" };
		});
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCallback = () => {
		props.onPosted();
		setOpen(false);
	};

	return (
		<>
			<Button onClick={handleClickOpen}>コメントを書く</Button>
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
				<DialogTitle>コメントを書く</DialogTitle>
				<DialogContent sx={{ display: "flex", flexDirection: "column" }}>
					<Rating value={review.rating} precision={0.5} onChange={handelChangeRating} />
					<Form ref={ref}>
						<FormTextInput
							label=""
							name="comment"
							value={review.comment}
							onChange={handleChange}
							multiline
							maxLength={256}
							required
							sx={{ mt: 1 }}
							invisibleCaption
							variant="dialog"
						/>
					</Form>
				</DialogContent>
				<DialogActions>
					<LongTimeProcessingButton
						caption="登録"
						onClick={handleClickPost}
						callback={handleCallback}
						variant="contained"
						sx={{ mt: 1 }}
					/>
					<Button onClick={handleClose}>キャンセル</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
