import React, { useContext, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import { getCastedValue } from "../Utilty";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { Form } from "./Form/Form";
import { FormTextInput } from "./Form/FormTextInput";
import { TraineePostModel } from "../Types/TraineePostModel";
import { Trainee } from "../Types/Trainee";
import { FormAreaSelector } from "./Form/FormAreaSelector";
import { Validator } from "../Types/Validator";
import { FormImageSelector } from "./Form/FormImageSelector";
import { FormGenreSelector } from "./Form/FormGenreSelector";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";

type Props = {
	mode: "new" | "edit";
};

const TraineeForm: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const [trainee, setTrainee] = useState<TraineePostModel>({
		name: "",
		image: undefined,
		areaIds: [],
		genreIds: [],
		introduction: "",
	});
	const [loaded, setLoaded] = useState(false);
	const ref = useRef<Validator>(null);

	useEffect(() => {
		if (props.mode === "new") {
			setLoaded(true);
		} else if (props.mode === "edit") {
			maxios.get<Trainee>(`/Trainees/me`).then((res) => {
				if (res.data) {
					setTrainee({
						name: res.data.name,
						image: res.data.image,
						areaIds: res.data.areaIds.map((a) => a.id),
						genreIds: res.data.genreIds.map((g) => g.id),
						introduction: res.data.introduction ?? "",
					});
				} else {
					navigate("/notfound");
				}

				setLoaded(true);
			});
		}
	}, [navigate, props.mode]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTrainee({ ...trainee, [event.target.name]: getCastedValue(event) });
	};

	const handleChangeActivityAreas = (areaIds: string[]) => {
		setTrainee({ ...trainee, areaIds: areaIds });
	};

	const handleChangeActivityGenres = (genreIds: string[]) => {
		setTrainee({ ...trainee, genreIds: genreIds });
	};

	const updateImage = (base64: string) => {
		setTrainee({ ...trainee, image: base64 });
	};

	const register = () => {
		if (!ref.current?.validate()) {
			return (async () => {
				return { isError: true };
			})();
		}

		switch (props.mode) {
			case "new":
				return maxios.post("/Trainees", trainee).then(() => {
					return { isError: false, message: "登録しました" };
				});
			case "edit":
				return maxios.put("/Trainees", trainee).then(() => {
					return { isError: false, message: "登録しました" };
				});
			default:
				throw new Error("");
		}
	};

	const navigateToTop = () => {
		authContext.refresh();
		navigate("/");
	};

	return (
		<>
			{loaded ? (
				<Box sx={{ textAlign: "center" }}>
					<Form style={{ width: "100%" }} ref={ref}>
						<FormImageSelector onSelected={updateImage} src={trainee.image} />
						<FormTextInput
							label="ニックネーム"
							name="name"
							value={trainee.name}
							onChange={handleChange}
							sx={{ maxWidth: "20rem" }}
							minLength={1}
							maxLength={50}
							required
						/>
						<FormTextInput
							label="自己紹介"
							name="introduction"
							value={trainee.introduction}
							onChange={handleChange}
							maxLength={2000}
							multiline
						/>
						<FormAreaSelector
							areaIds={trainee.areaIds}
							onChange={handleChangeActivityAreas}
							maxAreas={3}
						/>
						<FormGenreSelector genreIds={trainee.genreIds} onChange={handleChangeActivityGenres} />
					</Form>
					<LongTimeProcessingButton
						caption="登録"
						onClick={register}
						callback={navigateToTop}
						variant="contained"
						sx={{ mt: 1 }}
					/>
				</Box>
			) : (
				<></>
			)}
		</>
	);
};

export default TraineeForm;
