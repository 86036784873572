import { Box, SxProps, Theme } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useEffect, useState } from "react";
import Selector from "../Atoms/Selector";
import Area from "../Types/Area";
import PrefectureGetResponseModel from "../Types/PrefectureGetResponseModel";
import SelectorItem from "../Types/SelectorItem";

type Props = {
	areaId?: string;
	onChange: (areaId?: string) => void;
	sx?: SxProps<Theme>;
};

const AreaSelector: React.FC<Props> = (props) => {
	const [state, setState] = useState<{
		prefectureItems: SelectorItem[];
		prefecture: string | undefined;
		areaItems: SelectorItem[];
		area: string | undefined;
		ready: boolean;
	}>({
		prefectureItems: [],
		prefecture: undefined,
		areaItems: [],
		area: undefined,
		ready: false,
	});

	useEffect(() => {
		maxios.get<PrefectureGetResponseModel[]>("/Prefectures").then((res) => {
			let prefectures = res.data.map((prefecture) => {
				return {
					value: prefecture.id,
					label: prefecture.name,
				};
			});

			let prefecture: string | undefined;
			let areaItems: SelectorItem[];

			if (props.areaId) {
				maxios
					.get<PrefectureGetResponseModel[]>(`/Prefectures?areaId=${props.areaId}`)
					.then((res) => {
						prefecture = res.data[0].id;

						maxios.get<Area[]>(`/Areas?prefectureId=${prefecture}`).then((res) => {
							areaItems = res.data.map((area) => {
								return {
									value: area.id,
									label: area.name,
								};
							});

							setState({
								prefectureItems: prefectures,
								prefecture: prefecture,
								areaItems: areaItems,
								area: props.areaId,
								ready: true,
							});
						});
					});
			} else {
				setState((previous) => {
					return { ...previous, prefectureItems: prefectures, ready: true };
				});
			}
		});
	}, [props.areaId]);

	const handleChangePrefecture = (value: string | undefined) => {
		if (!value) {
			setState({ ...state, prefecture: value, areaItems: [], area: undefined });
			props.onChange(undefined);
			return;
		}

		maxios.get<Area[]>(`/Areas?prefectureId=${value}`).then((res) => {
			let areas = res.data.map((area) => {
				return {
					value: area.id,
					label: area.name,
				};
			});

			setState({ ...state, prefecture: value, areaItems: areas, area: undefined });
			props.onChange(undefined);
		});
	};

	const handleChangeArea = (value: string | undefined) => {
		setState({ ...state, area: value });
		props.onChange(value);
	};

	return (
		<Box display="flex" sx={props.sx}>
			<Selector
				label="都道府県"
				items={state.prefectureItems ?? []}
				onChange={handleChangePrefecture}
				value={state.prefecture}
				sx={{ mr: 1, minWidth: 110 }}
			/>
			<Selector
				label="エリア"
				items={state.areaItems ?? []}
				onChange={handleChangeArea}
				value={state.area}
				sx={{ minWidth: 110 }}
				/>
		</Box>
	);
};

export default AreaSelector;
