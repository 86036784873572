import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { maxios } from "../MaxiosProvider";
import { TrainerGymLinks } from "../Types/TrainerGymLinks";
import { AuthContext } from "../AuthContext";
import { MatchoType } from "../Types/MatchoType";
import { Gym } from "../Types/Gym";
import { Card } from "../Atoms/Card/Card";
import { LongTimeProcessing } from "./LongTimeProcessing";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";
import axios from "axios";
import { ApiResponse } from "../Types/ApiResponse";
import { ApiResponseStatus } from "../Types/ApiResponseStatus";

type Props = {
	trainerId: string;
};

export const TrainerLinkButton: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const [gyms, setGyms] = useState<(Gym & { requestable: boolean })[]>([]);
	const [open, setOpen] = useState(false);

	const requestLink = (gymId: string) => () => {
		return maxios
			.post<ApiResponse>(`/gymowners/me/gyms/${gymId}/trainerlinks/${props.trainerId}/apply`)
			.then((res) => {
				switch (res.data.status) {
					case ApiResponseStatus.Success:
						return { isError: false };
					default:
						return { isError: true };
				}
			});
	};

	const getGymsWithRequestableStatus = useCallback(() => {
		if (!authContext.claim) return;
		if (authContext.claim.type !== MatchoType.GymOwner) return;

		let gyms: Gym[];
		let trainerLinksPerGym: TrainerGymLinks[];

		return axios
			.all([
				maxios.get<Gym[]>(`/gymowners/me/gyms`).then((res) => {
					gyms = res.data;
				}),
				maxios.get<TrainerGymLinks[]>(`/gymowners/me/trainerlinks`).then((res) => {
					trainerLinksPerGym = res.data;
				}),
			])
			.then(() => {
				setGyms(
					gyms.map((gym) => {
						// 対象トレーナに対し受信リクエストも送信リクエストもなければリクエスト可能です
						let trainerLinks = trainerLinksPerGym.find((links) => links.matcho.id === gym.id);

						let receivedlinkFromThisTrainer = trainerLinks?.received.find(
							(link) => link.matcho.id === props.trainerId
						);

						let sentlinkToThisTrainer = trainerLinks?.sent.find(
							(link) => link.matcho.id === props.trainerId
						);

						if (receivedlinkFromThisTrainer || sentlinkToThisTrainer) {
							return { ...gym, requestable: false };
						} else {
							return { ...gym, requestable: true };
						}
					})
				);
			});
	}, [authContext.claim, props.trainerId]);

	const openGymList = () => {
		setOpen(true);
	};

	const handleClose = () => setOpen(false);

	return (
		<>
			{authContext.claim?.type === MatchoType.GymOwner && (
				<>
					<IconButton onClick={openGymList}>
						<AddLinkIcon sx={{ mr: 1 }} />
						<Typography variant="body2">リンクをリクエストする</Typography>
					</IconButton>
					<Dialog open={open} onClose={handleClose}>
						<DialogContent>
							<LongTimeProcessing processing={getGymsWithRequestableStatus}>
								{gyms.map((gym, index) => {
									return (
										<Card image={gym.image} name={gym.name} size="small" sx={{ mb: 1 }} key={index}>
											<LongTimeProcessingButton
												caption="このジムからリクエストを送る"
												onClick={requestLink(gym.id)}
												callback={getGymsWithRequestableStatus}
												variant="contained"
												disabled={!gym.requestable}
												sx={{ mt: 0.5 }}
											/>
										</Card>
									);
								})}
							</LongTimeProcessing>
						</DialogContent>
					</Dialog>
				</>
			)}
		</>
	);
};
