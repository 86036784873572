import { Switch } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Notifier } from "../../Types/Notifier";
import { Validator } from "../../Types/Validator";
import { FormItem } from "./FormItem";

type Props = {
	label: string;
	name?: string;
	checked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const FormCheckboxRef: React.ForwardRefRenderFunction<Validator, Props> = (
	{
		label,
		name,
		checked,
		onChange,
	},
	ref
) => {
	const formItemRef = useRef<Notifier>(null);

	// 親から呼ばれる関数
	useImperativeHandle(
		ref,
		() => ({
			validate() {
				return true;
			},
		}),
		[]
	);

	return (
		<FormItem label={label} ref={formItemRef} sx={{ display: "flex" }}>
			<Switch
					name={name}
					checked={checked}
					onChange={onChange}
					inputProps={{ "aria-label": "controlled" }}
				/>
		</FormItem>
	);
};

export const FormCheckbox = forwardRef(FormCheckboxRef);
