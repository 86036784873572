import "./App.css";
import { AuthProvider } from "./AuthContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Registration from "./Pages/Registration";
import SignIn from "./Pages/SignIn";
import Top from "./Pages/Top";
import { SearchResult } from "./Pages/SearchResult";
import { TrainerView } from "./Pages/TrainerView";
import { GymOwnerView } from "./Pages/GymOwnerView";
import { GymView } from "./Pages/GymView";
import { TrainerEditor } from "./Pages/TrainerEditor";
import { GymOwnerEditor } from "./Pages/GymOwnerEditor";
import { GymEditor } from "./Pages/GymEditor";
import { TraineeEditor } from "./Pages/TraineeEditor";
import { TrainerLinkPerGym } from "./Pages/TrainerLinkPerGym";
import { TrainerLinkEditor } from "./Pages/TrainerLinkEditor";
import { GymLinkEditor } from "./Pages/GymLinkEditor";
import { MaxiosProvider } from "./MaxiosProvider";
import { NavigateToTrainersMe } from "./Components/NavigateToTrainersMe";
import { NavigateToGymOwnersMe } from "./Components/NavigateToGymOwnersMe";
import { Settings } from "./Pages/Settings";
import { Helmet, HelmetProvider } from "react-helmet-async";

const App = () => {
	return (
		<HelmetProvider>
			<Helmet>
				<title>{`MATCHO - トレーニングマッチングサービス`}</title>
				<meta name="description" content="自分にぴったりのパーソナルトレーナーやジムを見つけよう!ヨガやピラティス、ダイエット。理想のトレーニングが探せます！" />
			</Helmet>
			<BrowserRouter>
				<AuthProvider>
					<MaxiosProvider>
						<Routes>
							<Route path="/" element={<Top />} />
							<Route path="registration" element={<Registration />} />
							<Route path="signin" element={<SignIn />} />
							<Route path="matchos" element={<SearchResult />} />
							<Route path="settings" element={<Settings />} />
							<Route path="gyms">
								<Route path=":gymId" element={<GymView />} />
							</Route>
							<Route path="gymowners">
								<Route path="me" element={<NavigateToGymOwnersMe />} />
								<Route path="me/trainerlinks" element={<TrainerLinkPerGym />} />
								<Route path="me/edit" element={<GymOwnerEditor />} />
								<Route path="me/gyms/:gymId/trainerlinks" element={<TrainerLinkEditor />} />
								<Route path="me/gyms/:gymId/edit" element={<GymEditor mode="edit" />} />
								<Route path="me/gyms/new" element={<GymEditor mode="new" />} />
								<Route path=":id" element={<GymOwnerView />} />
							</Route>
							<Route path="trainers">
								<Route path="me" element={<NavigateToTrainersMe />} />
								<Route path="me/edit" element={<TrainerEditor />} />
								<Route path="me/gymlinks" element={<GymLinkEditor />} />
								<Route path=":id" element={<TrainerView />} />
							</Route>
							<Route path="trainees">
								<Route path="me/edit" element={<TraineeEditor />} />
							</Route>
							<Route path="*" element={<>お探しのページは見つかりません。</>} />
							<Route path="notfound" element={<>お探しのページは見つかりません。</>} />
							<Route path="error" element={<>エラーが発生しました。</>} />
						</Routes>
					</MaxiosProvider>
				</AuthProvider>
			</BrowserRouter>
		</HelmetProvider>
	);
};

export default App;
