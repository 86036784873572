import React, { useRef, useState } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from "@mui/material";
import { getCastedValue } from "../Utilty";
import { maxios } from "../MaxiosProvider";
import { MatchoType } from "../Types/MatchoType";
import { MatchoUser } from "../Types/MatchoUser";
import { Validator } from "../Types/Validator";
import { FormTextInput } from "./Form/FormTextInput";
import { Form } from "./Form/Form";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";
import { ApiResponse } from "../Types/ApiResponse";
import { ApiResponseStatus } from "../Types/ApiResponseStatus";

type Props = {
	onInvited: (invited: boolean, matchoType: MatchoType) => void;
	onCompleted: () => void;
};

const Invitation: React.FC<Props> = (props) => {
	const ref = useRef<Validator>(null);

	const [invitaion, setInvitation] = useState({
		instagram: "",
		invitaionCode: "",
	});
	const [redeemOpen, setRedeemOpen] = React.useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInvitation({ ...invitaion, [event.target.name]: getCastedValue(event) });
	};

	const handleClickRedeemOpen = () => setRedeemOpen(true);
	const handleClickRedeemClose = () => setRedeemOpen(false);

	const redeem = () => {
		if (!ref.current?.validate()) {
			return (async () => {
				return { isError: true };
			})();
		}

		return maxios
			.post<ApiResponse<MatchoUser>>("/Users/invite", {
				MatchoId: invitaion.instagram,
				InvitationCode: invitaion.invitaionCode,
			})
			.then((res) => {
				switch (res.data.status) {
					case ApiResponseStatus.Success:
						if (res.data.body.type != null) props.onInvited(true, res.data.body.type);
						return {
							isError: false,
							message: "招待の引換えが完了しました！",
						};
					default:
						return {
							isError: true,
							message: res.data.message,
						};
				}
			});
	};

	return (
		<>
			<Box sx={{ textAlign: "center" }}>
				<Typography>招待コードをお持ちですか？</Typography>
				<Button onClick={props.onCompleted}>いいえ</Button>
				<Button onClick={handleClickRedeemOpen}>はい</Button>
				<Dialog open={redeemOpen} onClose={handleClickRedeemClose}>
					<DialogTitle>招待コードの引き換え</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Matchoからお送りしたDMに記載の招待コードを入力してください。
						</DialogContentText>
						<Form style={{ width: "100%" }} ref={ref}>
							<FormTextInput
								label="Instagram"
								name="instagram"
								value={invitaion.instagram}
								onChange={handleChange}
								required
								maxLength={30}
								sx={{ maxWidth: "20rem" }}
								// 半角英数字、ピリオド、アンダーバー
								regExp={/^[a-zA-Z0-9._]+$/}
								variant="dialog"
							/>
							<FormTextInput
								label="招待コード"
								name="invitaionCode"
								value={invitaion.invitaionCode}
								onChange={handleChange}
								required
								maxLength={30}
								sx={{ maxWidth: "20rem" }}
								// regExp={/^[a-zA-Z0-9-]+$/}
								variant="dialog"
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<LongTimeProcessingButton
							caption="引き換え"
							onClick={redeem}
							callback={props.onCompleted}
							variant="contained"
							sx={{ mt: 1 }}
						/>
						<Button onClick={handleClickRedeemClose}>キャンセル</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</>
	);
};

export default Invitation;
