const MatchoStyle = {
    color: {
        matchoBlue: "#69d2fa",
        matchoOrange: "#ff8d1c",
        matchoPink: "#e70290",
        matchoLightPink: "#F387C9"
    },
    size: {
        contentMaxWidth: "800px",
        space: "50px",
    }
}

export default MatchoStyle;