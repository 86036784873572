import { Avatar, SxProps, Theme } from "@mui/material";
import React from "react";
import { getMatchoImageSource } from "../Utilty";

type Props = {
	src?: string;
	alt?: string;
	sx?: SxProps<Theme>;
	size?: number;
	variant?: "square" | "circular" | "rounded";
};

export const MAvatar: React.FC<Props> = (props) => {
	return (
		<Avatar
			src={getMatchoImageSource(props.src, props.size)}
			alt={props.alt}
			variant={props.variant}
			sx={{ ...props.sx, width: props.size, height: props.size }}
		/>
	);
};
