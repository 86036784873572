import { Card, Box, Typography, CardMedia } from "@mui/material";
import React, { useRef } from "react";

type Props = {
	label?: string;
	image?: string;
	border?: string;
	size?: number;
	onClick?: () => void;
};

export const HorizonalCard: React.FC<Props> = (props) => {
	const size = useRef(props.size ?? 60);

	return (
		<Card
			sx={{
				display: "flex",
				height: size.current,
				border: props.border,
				":hover": {
					bgcolor: "#eeeeee",
					cursor: "pointer",
				},
			}}
			elevation={0}
			onClick={props.onClick}
		>
			{props.image && (
				<CardMedia
					component="img"
					sx={{
						width: size.current,
						height: "auto",
						objectFit: "cover",
					}}
					image={props.image}
					alt="Live from space album cover"
				/>
			)}
			<Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
				<Typography variant="body2">{props.label}</Typography>
			</Box>
		</Card>
	);
};
