// https://zenn.dev/longbridge/articles/761d980297a62c
import React, { useContext } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

export const maxios = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	// timeout: 10000,
	headers: {
		"Content-Type": "application/json",
	},
});

export function MaxiosProvider({ children }: { children: React.ReactElement }) {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();

	React.useLayoutEffect(() => {
		// リクエスト インターセプター
		const requestInterceptors = maxios.interceptors.request.use(
			async (config: AxiosRequestConfig) => {
				let idToken = await authContext.getIdToken();

				if (idToken) {
					if (config.headers) {
						config.headers.authorization = `Bearer ${idToken}`;
					}
				}

				return config;
			}
		);

		// レスポンス インターセプター
		const responseInterceptor = maxios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response) {
					switch (error.response.status) {
						case 401:
							navigate("/signin");
							break;
						default:
							navigate("/error");
							break;
					}
				} else {
					if (error.message === "canceled") {
						return;
					}
				}

				return Promise.reject(error);
			}
		);

		// クリーンアップ
		return () => {
			maxios.interceptors.request.eject(requestInterceptors);
			maxios.interceptors.response.eject(responseInterceptor);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <>{children}</>;
}
