import * as React from "react";
import { styled, SxProps, Theme } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SelectorItem from "../Types/SelectorItem";
import { Select, SelectChangeEvent } from "@mui/material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		borderRadius: 4,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #ced4da",
		padding: "10px 26px 10px 12px",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 4,
			borderColor: "#80bdff",
			boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
		},
	},
}));

type Props = {
	label: string,
	items: SelectorItem[],
	onChange: (value: string | undefined) => void,
	value?: string,
	sx?: SxProps<Theme>
};

const Selector: React.FC<Props> = (props) => {
	const handleChange = (event: SelectChangeEvent<string>) => {
		props.onChange(event.target.value === "" ? undefined : event.target.value);
	};

	return (
		<Select
			native
			value={props.value ?? ""}
			onChange={handleChange}
			input={<BootstrapInput />}
			sx={props.sx}
		>
			<option aria-label="None" value="" />
			{props.items.map((item, index) => {
				return (
					<option key={index} value={item.value}>
						{item.label}
					</option>
				);
			})}
		</Select>
	);
};

export default Selector;
