import { ApplicationStatus } from "./ApplicationStatus";
import { Matcho } from "./Matcho";

export type TrainerGymLinks = {
	matcho: Matcho,
	sent: {
		matcho: Matcho,
		applicationStatus: ApplicationStatus
	}[],
	received: {
		matcho: Matcho,
		applicationStatus: ApplicationStatus
	}[],
};

export type ApplicationDestination = Matcho & {
	text: string;
	canApprove: boolean;
	canDeny: boolean;
	canUnlink: boolean;
};

const createApplicationDestination = (
	matcho: Matcho,
	text: string,
	canApprove: boolean,
	canDeny: boolean,
	canUnlink: boolean
) => {
	return {
		...matcho,
		text: text,
		canApprove: canApprove,
		canDeny: canDeny,
		canUnlink: canUnlink,
	};
};

export const createApplicationDestinations = (links: TrainerGymLinks) => {
	let applicationDestination: ApplicationDestination[] = [];

	applicationDestination = applicationDestination.concat(
		links.received.map((l) => {
			switch (l.applicationStatus) {
				case ApplicationStatus.Checking:
					return createApplicationDestination(
						l.matcho,
						"リンクリクエストが届いています",
						true,
						true,
						false
					);
				case ApplicationStatus.Approved:
					return createApplicationDestination(l.matcho, "リンク済み", false, true, false);
				case ApplicationStatus.Denied:
					return createApplicationDestination(l.matcho, "ブロックしています", true, false, false);
				default:
					throw new Error("");
			}
		})
	);

	applicationDestination = applicationDestination.concat(
		links.sent.map((l) => {
			switch (l.applicationStatus) {
				case ApplicationStatus.Checking:
					return createApplicationDestination(l.matcho, "リンク待ち", false, false, false);
				case ApplicationStatus.Approved:
					return createApplicationDestination(l.matcho, "リンク済み", false, false, true);
				case ApplicationStatus.Denied:
					return createApplicationDestination(l.matcho, "ブロックされました", false, false, false);
				default:
					throw new Error("");
			}
		})
	);

	return applicationDestination;
};
