export const getUrl = (route?: string) => {
	let prefix = `https://${process.env.REACT_APP_API_FQDN}:7210`;

	if (route) {
		return `${prefix}/${route}`;
	} else {
		return prefix;
	}
};

export const breakText = (text?: string) => {
	if (!text) return <></>;
	return text.split("\r\n").map((line, key) => (
		<span key={key}>
			{line}
			<br />
		</span>
	));
};

export const getCastedValue = (event: React.ChangeEvent<HTMLInputElement>) => {
	let value: any = undefined;

	switch (event.target.type) {
		case "text":
		case "textarea":
			value = event.target.value;
			break;
		case "radio":
			value = Number.parseInt(event.target.value);
			break;
		case "date":
			value = event.target.value ? new Date(event.target.value) : null;
			break;
		default:
			break;
	}

	return value;
};

export const formatDate = (value: Date | null) => {
	if (!value) return "";

	let y = value.getFullYear();
	let m = ("00" + (value.getMonth() + 1)).slice(-2);
	let d = ("00" + value.getDate()).slice(-2);

	return y + "-" + m + "-" + d;
};

export const formatDate2 = (value: Date | null) => {
	if (!value) return "";

	let y = value.getFullYear();
	let m = ("00" + (value.getMonth() + 1)).slice(-2);
	let d = ("00" + value.getDate()).slice(-2);
	let hh = ("00" + value.getHours()).slice(-2);
	let mm = ("00" + value.getMinutes()).slice(-2);

	return `${y}年${m}月${d}日 ${hh}:${mm}`;
};

export const isEmail = (value: string) => {
	let pattern = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

	if (pattern.test(value)) {
		return true;
	} else {
		return false;
	}
};

export const isUri = (value: string) => {
	let pattern = /^https?:\/\/[-_.!~*()a-zA-Z0-9;/?@&=+$,%#]+$/;

	if (pattern.test(value)) {
		return true;
	} else {
		return false;
	}
};

export const hasDuplicates = (values: any[]) => {
	let nonEmptyValues = values.filter((value) => value);
	return new Set(nonEmptyValues).size !== nonEmptyValues.length;
};

export const getMatchoImageUrl = (fileName: string | undefined, size?: number) => {
	if (!fileName) return undefined;

	let folderName = "";

	if (!size) {
		folderName = "sm";
	} else if (size <= 64) {
		folderName = "sm";
	} else if (size <= 128) {
		folderName = "md";
	} else {
		folderName = "lg";
	}

	return `${process.env.REACT_APP_IMAGE_STORAGE_URL}/${folderName}/${fileName}`;
};

export const getMatchoImageSource = (src?: string, size?: number) => {
	if (!src) return undefined;

	const regexp = /^data:image.*?base64,/;

	if (regexp.test(src)) {
		return src;
	} else {
		return getMatchoImageUrl(src, size);
	}
};

export const getGenreImageUrl = (fileName: string | undefined | null) => {
	if (!fileName) return undefined;

	let folderName = "genre";

	return `${process.env.REACT_APP_IMAGE_STORAGE_URL}/${folderName}/${fileName}`;
};
