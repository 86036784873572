import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React, {  } from "react";
import { Instagram } from "../Types/Instagram";
import { formatDate2 } from "../Utilty";
import instagramIcon from "../Resources/instagram.svg";
import { Splide, SplideSlide } from "@splidejs/react-splide";

type Props = {
	posts?: Instagram[]
};

export const InstagramGallery: React.FC<Props> = (props) => {
	return (
		<Splide
			options={{
				perMove: 1,
				gap: "1rem",
				rewind: false,
				pagination: false,
				fixedWidth: 300,
				speed: 500,
			}}
		>
			{props.posts?.map((post, index) => {
				return (
					<SplideSlide key={index}>
						{/* Cardのボーダーは影のためmbで隙間を入れないと境界がなくなる */}
						<Card sx={{ width: 300, mb: "2px" }}>
							<CardMedia
								component="img"
								alt={post.caption}
								height={300}
								image={post.thumbnailUrl}
							/>
							<CardContent>
								<Box display={"flex"} justifyContent={"space-between"}>
									<Box>
										<Typography variant="body2" fontWeight={600}>
											いいね！{post.likeCount}件
										</Typography>
										<Typography variant="overline" fontSize={"0.5rem"} color="text.secondary">
											{formatDate2(new Date(post.timestamp))}
										</Typography>
									</Box>
									<Box>
										<a href={post.permalink} target="_blank" rel="noopener noreferrer">
											<img
												src={instagramIcon}
												alt={"instagramIcon"}
												style={{ height: 30, width: 30 }}
											/>
										</a>
									</Box>
								</Box>
							</CardContent>
						</Card>
					</SplideSlide>
				);
			})}
		</Splide>
	);
};
