import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "../Atoms/BackIcon";
import MatchoStyle from "../Types/MatchoStyle";
import { Header } from "./Header";

type Props = {};

export const Frame: React.FC<Props> = (props) => {
    const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};

	return (
		<>
			<Header />
			<Box sx={{ maxWidth: MatchoStyle.size.contentMaxWidth, m: "50px auto", p: 1 }}>
				<BackIcon onClick={goBack} />
				{props.children}
			</Box>
		</>
	);
};
