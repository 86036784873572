import React, { PropsWithChildren, ReactElement, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { MatchoType } from "../Types/MatchoType";
import { MatchoUser } from "../Types/MatchoUser";
import { RegistrationStatus } from "../Types/RegistrationStatus";

type Props = {
	filter: MatchoType | RegistrationStatus;
};

export const NavigatorByAuthStatus: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);

	return (
		<>
			{authContext.isReady &&
				(authContext.isSignedIn ? (
					authContext.registrationStatus === RegistrationStatus.Completed ? (
						authContext.claim!.type === props.filter ||
						props.filter === RegistrationStatus.Completed ? (
							React.Children.map(props.children, (child, index) => {
								// 子要素を型付け
								let item = child as ReactElement<PropsWithChildren<{ claim: MatchoUser }>>;

								// 子要素のpropsを追加
								return React.cloneElement(item, { claim: authContext.claim! });
							})
						) : (
							<Navigate to="/" />
						)
					) : (
						<Navigate to="/registration" />
					)
				) : (
					<Navigate to="/signin" />
				))}
		</>
	);
};
