import { SxProps, Theme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toQueryString } from "../Types/SearchCondition";
import SelectorItem from "../Types/SelectorItem";
import { Chips } from "./Chips";

type Props = {
	items: SelectorItem[];
	size?: "small" | "medium";
	sx?: SxProps<Theme>;
};

export const GenreChips: React.FC<Props> = (props) => {
	const navigate = useNavigate();

	const handleClick = (genreId: string) => {
		navigate({
			pathname: "/matchos",
			search: toQueryString({
				genreIds: [genreId],
			}).toString(),
		});
	};

	return (
		<Chips
			items={props.items}
			onClick={handleClick}
			size={props.size}
			sx={props.sx}
			backgraoundColor={"black"}
			textColor={"white"}
		/>
	);
};
