import { Stack } from "@mui/material";
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import GenreSelector from "../../Pages/GenreSelector";
import { Notifier } from "../../Types/Notifier";
import { Validator } from "../../Types/Validator";
import { hasDuplicates } from "../../Utilty";
import { FormItem } from "./FormItem";

type Props = {
	genreIds: string[];
	onChange: (genreIds: string[]) => void;
	required?: boolean;
};

const FormGenreSelectorRef: React.ForwardRefRenderFunction<Validator, Props> = (
	{ genreIds, onChange, required },
	ref
) => {
	const formItemRef = useRef<Notifier>(null);

	const [values, setValues] = useState<(string | undefined)[]>(genreIds);

	const handleChange = (index: number, value?: string) => {
		let clonedValues = [...values];

		if (values.length === index) {
			if (!value) return;

			clonedValues.push(value);
		} else {
			clonedValues[index] = value;
		}

		setValues(clonedValues);
		onChange(clonedValues.filter((value) => value) as string[]);
	};

	const _validate = useCallback(
		(doBlink: boolean) => {
			let valid = false;

			if (required && values.filter((value) => value).length === 0) {
				formItemRef.current?.setErrorMessage("必須です");
			} else if (hasDuplicates(values)) {
				formItemRef.current?.setErrorMessage("重複しています");
			} else {
				formItemRef.current?.setErrorMessage("");
				valid = true;
			}

			if (doBlink && !valid) formItemRef.current?.blink();

			return valid;
		},
		[required, values]
	);

	useEffect(() => {
		_validate(false);
	}, [values, _validate]);

	// 親から呼ばれる関数
	useImperativeHandle(
		ref,
		() => ({
			validate() {
				return _validate(true);
			},
		}),
		[_validate]
	);

	return (
		<FormItem label="ジャンル" ref={formItemRef}>
			<Stack spacing={1}>
				<GenreSelector
					key="1"
					onChange={(genreId?: string) => {
						handleChange(0, genreId);
					}}
					id={values[0]}
				/>
				<GenreSelector
					key="2"
					onChange={(genreId?: string) => {
						handleChange(1, genreId);
					}}
					id={values[1]}
				/>
				<GenreSelector
					key="3"
					onChange={(genreId?: string) => {
						handleChange(2, genreId);
					}}
					id={values[2]}
				/>
			</Stack>
		</FormItem>
	);
};

export const FormGenreSelector = forwardRef(FormGenreSelectorRef);
