import { Box, Rating, Typography } from "@mui/material";
import React from "react";
import { MAvatar } from "../Atoms/MAvatar";
import { Review } from "../Types/Review";

type Props = {
	review: Review;
};

export const ReviewCard: React.FC<Props> = (props) => {
	return (
		<>
			<Box sx={{ display: "flex" }}>
				<MAvatar
					alt={props.review.reviewerId}
					src={props.review.reviewerImage}
					sx={{ mr: 1 }}
					size={36}
				/>
				<Box>
					<Typography variant="subtitle2">{props.review.reviewerName}</Typography>
					<Rating value={props.review.rating} precision={0.5} readOnly size="small" />
				</Box>
				<Typography variant="caption" sx={{ ml: "auto" }}>
					{(() => props.review.posted.toLocaleString())()}
				</Typography>
			</Box>
			<Typography variant="body2" sx={{ whiteSpace: "pre-line", overflowWrap: "break-word" }}>
				{props.review.comment}
			</Typography>
		</>
	);
};
