import { Box } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useEffect, useState } from "react";
import Selector from "../Atoms/Selector";
import GenreGetResponseModel from "../Types/GenreGetResponseModel";
import SelectorItem from "../Types/SelectorItem";

type Props = {
	id?: string;
	onChange: (id?: string) => void;
};

const GenreSelector: React.FC<Props> = (props) => {
	const [genre, setGenre] = useState<string | undefined>(props.id);
	const [items, setItems] = useState<SelectorItem[]>([]);

	useEffect(() => {
		maxios.get<GenreGetResponseModel[]>("/Genres").then((res) => {
			let genres = res.data.map((genre) => {
				return {
					value: genre.id,
					label: genre.name,
				};
			});

			setItems(genres);
		});
	}, []);

	const handleChange = (value?: string) => {
		setGenre(value);
		props.onChange(value);
	};

	return (
		<Box>
			<Selector label="ジャンル" items={items} onChange={handleChange} value={genre} />
		</Box>
	);
};

export default GenreSelector;
