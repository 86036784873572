import { Box, Chip, SxProps, Theme } from "@mui/material";
import React from "react";
import { GymType } from "../../Types/GymType";
import MatchoStyle from "../../Types/MatchoStyle";
import { getMatchoTypeName, MatchoType } from "../../Types/MatchoType";
import { TrainerType } from "../../Types/TrainerType";
import { MAvatar } from "../MAvatar";

type Props = {
	alt?: string;
	image?: string;
	size?: number;
	matchoType?: MatchoType;
	matchoSubType?: TrainerType | GymType;
	sx?: SxProps<Theme>;
};

export const CardImage: React.FC<Props> = (props) => {
	const getMatchoTypeColor = (matchoType: MatchoType) => {
		switch (matchoType) {
			case MatchoType.Trainer:
				return MatchoStyle.color.matchoPink;
			case MatchoType.Gym:
				return MatchoStyle.color.matchoOrange;
			case MatchoType.GymOwner:
				return MatchoStyle.color.matchoBlue;
			default:
				throw new Error();
		}
	};

	return (
		<Box sx={props.sx}>
			<Box sx={{ textAlign: "center", mr: 2, width: props.size }}>
				<MAvatar alt={props.alt} src={props.image} size={props.size} />
				{props.matchoType !== undefined && (
					<Chip
						label={getMatchoTypeName(props.matchoType, props.matchoSubType)}
						size="small"
						sx={{
							bgcolor: getMatchoTypeColor(props.matchoType),
							color: "white",
							mt: 1,
							fontSize: getMatchoTypeName(props.matchoType, props.matchoSubType).length > 7 ? "0.6rem" : "0.8rem"
						}}
					/>
				)}
			</Box>
		</Box>
	);
};
