import { IconButton, Typography } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
    label: string;
	onClick: () => void;
    icon: "edit" | "add" | "delete";
};

export const LabeledIconButton: React.FC<Props> = (props) => {
	return (
		<IconButton onClick={props.onClick} sx={{ display: "flex", alignItems: "center" }}>
			{props.icon === "edit" && <EditIcon />}
			{props.icon === "add" && <AddIcon />}
			{props.icon === "delete" && <DeleteIcon />}
			<Typography variant="body2">{props.label}</Typography>
		</IconButton>
	);
};
