import Area from "./Area";
import GenreGetResponseModel from "./GenreGetResponseModel";
import { Matcho } from "./Matcho";
import { Trainer } from "./Trainer";
import { TrainerPostModel } from "./TrainerPostModel";
import { TrainerType } from "./TrainerType";

export type TrainerResponseModel = {
	id: string,
	trainerType: TrainerType,
	name: string,
	image: string | null,
	activityAreas: Area[],
	activityGenres: GenreGetResponseModel[],
	selfIntroduction: string | null,
	rating: number,
	numberOfComment: number,
	career: string | null,
	license: string | null,
	service: string | null,
	online: boolean,
	onsite: boolean,
	feePerOnceLowerLimit: number | null;
	feePerOnceUpperLimit: number | null;
	feePerMonthLowerLimit: number | null;
	feePerMonthUpperLimit: number | null;
	trialFeeLowerLimit: number | null;
	trialFeeUpperLimit: number | null;
	station: string | null;
	facebook: string | null,
	twitter: string | null,
	instagram: string | null,
	website: string | null,
	youTube: string | null,
	line: string | null,
	gallery: string[],
	linkedGyms: Matcho[]
};

export const toTrainerPostModel = (source: TrainerResponseModel) => {
	let result: TrainerPostModel;

	result = {
		trainerType: source.trainerType,
		name: source.name,
		activityAreaIds: source.activityAreas.map((a) => a.id),
		activityGenreIds: source.activityGenres.map((g) => g.id),
		selfIntroduction: source.selfIntroduction ?? "",
		career: source.career ?? "",
		license: source.license ?? "",
		service: source.service ?? "",
		online: source.online,
		onsite: source.onsite,
		feePerOnceLowerLimit: source.feePerOnceLowerLimit ?? undefined,
		feePerOnceUpperLimit: source.feePerOnceUpperLimit ?? undefined,
		feePerMonthLowerLimit: source.feePerMonthLowerLimit ?? undefined,
		feePerMonthUpperLimit: source.feePerMonthUpperLimit ?? undefined,
		trialFeeLowerLimit: source.trialFeeLowerLimit ?? undefined,
		trialFeeUpperLimit: source.trialFeeUpperLimit ?? undefined,
		station: source.station ?? "",
		facebook: source.facebook ?? "",
		twitter: source.twitter ?? "",
		instagram: source.instagram ?? "",
		website: source.website ?? "",
		youTube: source.youTube ?? "",
		line: source.line ?? "",
		image: source.image ?? undefined,
		gallery: [],
	};

	return result;
};

export const toTrainer = (source: TrainerResponseModel) => {
	let result: Trainer;

	result = {
		id: source.id,
		trainerType: source.trainerType,
		name: source.name,
		image: source.image ?? undefined,
		activityAreas: source.activityAreas,
		activityGenres: source.activityGenres,
		selfIntroduction: source.selfIntroduction ?? "",
		rating: source.rating,
		numberOfComment: source.numberOfComment,
		career: source.career ?? "",
		license: source.license ?? "",
		service: source.service ?? "",
		online: source.online,
		onsite: source.onsite,
		feePerOnceLowerLimit: source.feePerOnceLowerLimit ?? undefined,
		feePerOnceUpperLimit: source.feePerOnceUpperLimit ?? undefined,
		feePerMonthLowerLimit: source.feePerMonthLowerLimit ?? undefined,
		feePerMonthUpperLimit: source.feePerMonthUpperLimit ?? undefined,
		trialFeeLowerLimit: source.trialFeeLowerLimit ?? undefined,
		trialFeeUpperLimit: source.trialFeeUpperLimit ?? undefined,
		station: source.station ?? undefined,
		facebook: source.facebook ?? "",
		twitter: source.twitter ?? "",
		instagram: source.instagram ?? "",
		website: source.website ?? "",
		youTube: source.youTube ?? "",
		line: source.line ?? "",
		gallery: source.gallery,
		linkedGyms: source.linkedGyms
	};

	return result;
};