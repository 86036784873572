import { Button } from "@mui/material";
import React, { useContext } from "react";
import { AuthContext } from "../AuthContext";
import { CaptionedItem } from "../Components/CaptionedItem";
import { Frame } from "../Components/Frame";
import UserRegistration from "../Components/UserRegistration";
import { NavigatorByAuthStatus } from "../Components/NavigatorByAuthStatus";
import { RegistrationStatus } from "../Types/RegistrationStatus";
import MatchoAssigner from "../Components/MatchoAssigner";

type Props = {};

export const Settings: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);

	const handleOpen = () => {
		authContext.withdrawal();
	};

	return (
		<NavigatorByAuthStatus filter={RegistrationStatus.Completed}>
			<Frame>
				<CaptionedItem caption="ユーザー情報" dividerAtBottom>
					<UserRegistration onCompleted={authContext.refresh} />
				</CaptionedItem>
				<CaptionedItem caption="退会" dividerAtBottom>
					<Button onClick={handleOpen}>退会する</Button>
				</CaptionedItem>
				{authContext.user?.email === process.env.REACT_APP_PRIVILEGE_USER && (
					<CaptionedItem caption="管理者メニュー" dividerAtBottom>
						<MatchoAssigner onCompleted={authContext.refresh} />
					</CaptionedItem>
				)}
			</Frame>
		</NavigatorByAuthStatus>
	);
};
