import {
	Box,
	Button,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import Logo from "../matcho-logo.png";
import { MatchoType } from "../Types/MatchoType";
import { RegistrationStatus } from "../Types/RegistrationStatus";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import EditIcon from "@mui/icons-material/Edit";
import { MAvatar } from "../Atoms/MAvatar";
import LinkIcon from "@mui/icons-material/Link";
import SettingsIcon from "@mui/icons-material/Settings";

type Props = {
	hiddenSignInButton?: boolean;
};

export const Header: React.FC<Props> = (props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const [openDrawer, setOpenDrawer] = useState(false);

	const handleClickSignIn = () => {
		navigate("/signin");
	};

	const navigateToTop = () => {
		navigate("/");
	};

	const toggleDrawer = (open: boolean) => (event: any) => {
		if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}

		setOpenDrawer(open);
	};

	const handleClickEdit = () => {
		if (authContext.registrationStatus !== RegistrationStatus.Completed) {
			navigate("/registration");
			return;
		}

		switch (authContext.claim?.type) {
			case MatchoType.GymOwner:
				navigate("/gymowners/me");
				break;
			case MatchoType.Trainee:
				navigate(`/trainees/me/edit`);
				break;
			case MatchoType.Trainer:
				navigate(`/trainers/${authContext.claim.matchoId}`);
				break;
			default:
		}
	};

	const handleClickLinkRequest = () => {
		if (authContext.registrationStatus !== RegistrationStatus.Completed) {
			navigate("/registration");
			return;
		}

		if (authContext.claim?.type === MatchoType.GymOwner) {
			navigate("/gymowners/me/trainerlinks");
		} else if (authContext.claim?.type === MatchoType.Trainer) {
			navigate("/trainers/me/gymlinks");
		}
	};

	const handleClickSettings = () => {
		navigate("/settings");
	};

	const handleClickSignOut = () => authContext.signOut();

	return (
		<Box sx={{ pt: 2, maxWidth: 1200, margin: "0 auto" }}>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				{/* レイアウトスライドを抑止するために高さ・幅を設定します。 */}
				<img
					alt="MATCHO logo"
					src={Logo}
					onClick={navigateToTop}
					style={{ margin: "8px", cursor: "pointer" }}
					width={198}
					height={55}
				/>
				<Box>
					{authContext.user ? (
						<>
							<IconButton onClick={toggleDrawer(true)}>
								<MAvatar alt={authContext.claim?.id} src={authContext.claim?.image} size={32} />
								{matches ? (
									<Typography
										sx={{
											maxWidth: 150,
											display: "-webkit-box",
											WebkitBoxOrient: "vertical",
											WebkitLineClamp: 1, // 行数指定
											overflow: "hidden",
										}}
									>
										{authContext.name}
									</Typography>
								) : (
									<></>
								)}
							</IconButton>
							<Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
								<Box
									sx={{ width: "auto" }}
									role="presentation"
									onClick={toggleDrawer(false)}
									onKeyDown={toggleDrawer(false)}
								>
									<List>
										<ListItem disablePadding>
											<ListItemButton onClick={handleClickEdit}>
												<ListItemIcon>
													<EditIcon />
												</ListItemIcon>
												<ListItemText primary="マイページ" />
											</ListItemButton>
										</ListItem>
										{authContext.claim?.type !== MatchoType.Trainee && (
											<ListItem disablePadding>
												<ListItemButton onClick={handleClickLinkRequest}>
													<ListItemIcon>
														<LinkIcon />
													</ListItemIcon>
													<ListItemText primary="リンクリクエスト" />
												</ListItemButton>
											</ListItem>
										)}
									</List>
									<Divider />
									<List>
										<ListItem disablePadding>
											<ListItemButton onClick={handleClickSettings}>
												<ListItemIcon>
													<SettingsIcon />
												</ListItemIcon>
												<ListItemText primary="設定" />
											</ListItemButton>
										</ListItem>
									</List>
									<Divider />
									<List>
										<ListItem disablePadding>
											<ListItemButton onClick={handleClickSignOut}>
												<ListItemIcon>
													<ExitToAppIcon />
												</ListItemIcon>
												<ListItemText primary="ログアウト" />
											</ListItemButton>
										</ListItem>
									</List>
								</Box>
							</Drawer>
						</>
					) : (
						!props.hiddenSignInButton && (
							<Button onClick={handleClickSignIn}>会員登録 / ログイン</Button>
						)
					)}
				</Box>
			</Stack>
		</Box>
	);
};
