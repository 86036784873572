import { Box, Typography, Button } from "@mui/material";
import React from "react";

type Props = {
	src: string;
	caption?: string;
	color?: string;
	size?: number;
    onClick?: () => void,
};

export const CaptionedIcon: React.FC<Props> = (props) => {
	return (
		<>
			<Button
				sx={{
					width: `${props.size}px`,
					height: `${props.size}px`,
					borderRadius: "50%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
                    border: "solid",
                    borderColor: "#f5f5f5",
                    ":hover":{
                        bgcolor: "#f5f5f5"
                    }
				}}
                onClick={props.onClick}
			>
				<Box sx={{ textAlign: "center" }}>
					<img
						src={props.src}
						style={{ width: `${props.size! / 2}px`, height: `${props.size! / 2}px` }}
						alt="label"
					/>
					<Typography sx={{ color: props.color, fontWeight: "bold" }}>{props.caption}</Typography>
				</Box>
			</Button>
		</>
	);
};

CaptionedIcon.defaultProps = {
	color: "#f5f5f5",
	size: 120,
};
