import React, { useContext, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import { getCastedValue } from "../Utilty";
import { GymOwnerPostModel } from "../Types/GymOwnerPostModel";
import { GymOwner } from "../Types/GymOwner";
import { Form } from "./Form/Form";
import { FormTextInput } from "./Form/FormTextInput";
import { Validator } from "../Types/Validator";
import { FormImageSelector } from "./Form/FormImageSelector";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";

type Props = {
	mode: "new" | "edit";
};

const GymOwnerForm: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const ref = useRef<Validator>(null);
	const [gymOwner, setGymOwner] = useState<GymOwnerPostModel>({
		name: "",
		image: undefined,
	});
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (props.mode === "new") {
			setLoaded(true);
		} else if (props.mode === "edit") {
			maxios.get<GymOwner>(`/gymowners/me`).then((res) => {
				if (res.data) {
					setGymOwner({
						name: res.data.name,
						image: res.data.image,
					});
				} else {
					navigate("/notfound");
				}

				setLoaded(true);
			});
		}
	}, [navigate, props.mode]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setGymOwner({ ...gymOwner, [event.target.name]: getCastedValue(event) });
	};

	const updateImage = (base64: string) => {
		setGymOwner({ ...gymOwner, image: base64 });
	};

	const register = () => {
		if (!ref.current?.validate()) {
			return (async () => {
				return { isError: true };
			})();
		}

		switch (props.mode) {
			case "new":
				return maxios.post("/gymowners", gymOwner).then(() => {
					return { isError: false, message: "登録しました！" };
				});
			case "edit":
				return maxios.put("/gymowners", gymOwner).then(() => {
					return { isError: false, message: "登録しました！" };
				});
			default:
				throw new Error("");
		}
	};

	const navigateToMe = () => {
		authContext.refresh()!.then(() => {
			navigate(`/gymowners/me`);
		});
	};

	return (
		<>
			{loaded && (
				<Box sx={{ textAlign: "center" }}>
					<Form style={{ marginLeft: "auto", marginRight: "auto" }} ref={ref}>
						<FormImageSelector onSelected={updateImage} src={gymOwner.image} />
						<FormTextInput
							label="ニックネーム"
							name="name"
							value={gymOwner.name}
							onChange={handleChange}
							sx={{ maxWidth: "20rem" }}
							required
							minLength={2}
							maxLength={128}
						/>
					</Form>
					<LongTimeProcessingButton
						caption="登録"
						onClick={register}
						callback={navigateToMe}
						variant="contained"
						sx={{ mt: 1 }}
					/>
				</Box>
			)}
		</>
	);
};

export default GymOwnerForm;
