import { Box, Chip, Grid, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import SelectorItem from "../Types/SelectorItem";

type Props = {
	caption?: string,
	captionWidth?: number,
	items: SelectorItem[],
	onClick: (value: string) => void,
	textColor?: string,
	backgraoundColor?: string,
	size?: "small" | "medium",
	sx?: SxProps<Theme>,
};

export const Chips: React.FC<Props> = (props) => {
	return (
		<Box sx={props.sx}>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				{/* <Typography variant="caption" sx={{ mr: 1, flexBasis: props.captionWidth }} noWrap>
					{props.caption}
				</Typography> */}
				<Grid container spacing={1}>
					{props.items.map((item, index) => {
						return (
							<Grid item key={index}>
								<Chip
									label={<Typography variant="caption">{item.label}</Typography>}
									onClick={(e) => {
										e.stopPropagation();
										if (props.onClick) props.onClick(item.value);
									}}
									size={props.size}
									// sx={{backgroundColor: props.backgraoundColor, color: props.textColor, height: "24px"}}
									// className="blue"
									variant="outlined"
								/>
							</Grid>
						);
					})}
				</Grid>
			</Box>
		</Box>
	);
};
