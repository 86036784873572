import {
	Chip,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { maxios } from "../MaxiosProvider";
import PrefectureGetResponseModel from "../Types/PrefectureGetResponseModel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { AreaChips } from "./AreaChips";

type Props = {
	regionId: string;
	onClick?: (prefectureId: string) => void;
	onClickArea?: (areaId: string) => void;
};

export const PrefectureChips: React.FC<Props> = (props) => {
	const [prefectures, setPrefectures] = useState<PrefectureGetResponseModel[]>([]);
	const [state, setState] = useState({ open: false, selected: "" });

	useEffect(() => {
		maxios
			.get<PrefectureGetResponseModel[]>(`/regions/${props.regionId}/prefectures`)
			.then((res) => {
				setPrefectures(res.data);
			});
	}, [props.regionId]);

	const handleClick = (prefectureId: string) => () => {
		if (props.onClick) {
			props.onClick(prefectureId);
		} else {
			setState({ open: true, selected: prefectureId });
		}
	};

	const handelClose = () => {
		setState({ open: false, selected: "" });
	};

	return (
		<>
			<Grid container spacing={1}>
				{prefectures.map((prefecture, index) => {
					return (
						<Grid item key={index}>
							<Chip
								label={<Typography variant="caption">{prefecture.name}</Typography>}
								onClick={handleClick(prefecture.id)}
								variant="outlined"
							/>
						</Grid>
					);
				})}
			</Grid>
			<Dialog open={state.open} onClose={handelClose}>
				<DialogTitle>
					<IconButton aria-label="back" onClick={handelClose}>
						<ArrowBackIosNewIcon />
					</IconButton>
					エリアを選択
				</DialogTitle>
				<DialogContent>
					{state.selected && <AreaChips prefectureId={state.selected} onClick={props.onClickArea} />}
				</DialogContent>
			</Dialog>
		</>
	);
};
