import { Button, Backdrop, Box, Typography, SxProps, Theme, IconButton } from "@mui/material";
import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
	callback?: () => Promise<void> | undefined | void;
	onClick?: () => Promise<{ isError: boolean; message?: string }>;
	caption?: string;
	variant?: "text" | "contained" | "outlined" | undefined;
	sx?: SxProps<Theme>;
	disabled?: boolean;
	className?: string;
};

export const LongTimeProcessingButton: React.FC<Props> = (props) => {
	const [state, setState] = useState<{
		open: boolean;
		processing: boolean;
		isError: boolean;
		message?: string;
	}>({
		open: false,
		processing: false,
		isError: true,
	});
	const [displayMessage, setDisplayMessage] = useState(false);

	const okButtonRef = React.useRef<HTMLButtonElement>(null);

	// OKボタンにフォーカスします。
	// フォーカスしない場合Enter連打で連続処理が行われてしまうための処置です。
	// useEffectの第2引数は指定していませんが軽量処理であるため問題なしとします。
	React.useEffect(() => {
		const node = okButtonRef.current;
		if (node) {
			node.focus();
		}
	});

	const handleClick = () => {
		if (!props.onClick) return;

		setState({ ...state, open: true, processing: true });

		props.onClick().then((result) => {
			setState({ open: true, processing: false, isError: result.isError, message: result.message });

			if (!result.message) {
				if (result.isError) {
					blink();
				}

				callback(result.isError);
			}
		});
	};

	const blink = () => {
		setDisplayMessage(true);
		setTimeout(() => {
			setDisplayMessage(false);
		}, 1000);
	};

	const callback = (isError: boolean) => {
		if (props.callback && !isError) {
			let promise = props.callback();

			if (promise) {
				promise.then(() => {
					// React 18からはアンマウント後のsetStateで警告が出ないとのこと
					setState({ ...state, open: false });
				});
			} else {
				// React 18からはアンマウント後のsetStateで警告が出ないとのこと
				setState({ ...state, open: false });
			}
		} else {
			// React 18からはアンマウント後のsetStateで警告が出ないとのこと
			setState({ ...state, open: false });
		}
	};

	return (
		<>
			{props.children ? (
				<IconButton onClick={handleClick}>{props.children}</IconButton>
			) : (
				<Button
					className={props.className}
					sx={props.sx}
					variant={props.variant}
					onClick={handleClick}
					disabled={props.disabled}
				>
					{props.caption}
				</Button>
			)}
			{displayMessage && (
				<Typography color="error" variant="body2">
					エラーがあります
				</Typography>
			)}
			<Backdrop open={state.open} sx={{ zIndex: 1 }}>
				{state.processing || !state.message ? (
					<Box sx={{ color: "white" }}>
						<CircularProgress color="inherit" />
					</Box>
				) : (
					<Box sx={{ bgcolor: "white", borderRadius: 2, p: 2, minWidth: 200 }}>
						<Typography style={{whiteSpace: 'pre-line'}}>{state.message}</Typography>
						<Button
							ref={okButtonRef}
							onClick={() => callback(state.isError)}
							variant="contained"
							sx={{ mt: 1, width: "100%", maxWidth: 200 }}
						>
							OK
						</Button>
					</Box>
				)}
			</Backdrop>
		</>
	);
};

export const createSuccessResult = (message?: string) => {
	return { isError: false, message: message };
};

export const createErrorResult = (message?: string) => {
	return { isError: true, message: message };
};
