import { Box, Divider, Grid, Typography } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useCallback, useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CaptionedItem } from "../Components/CaptionedItem";
import { Gallery } from "../Components/Gallery";
import { Headline } from "../Components/Headline";
import { MatchoType } from "../Types/MatchoType";
import { Trainer } from "../Types/Trainer";
import { ReviewDialog } from "./ReviewDialog";
import { ReviewViewer } from "./ReviewViewer";
import { AuthContext } from "../AuthContext";
import { CaptionedText } from "../Components/CaptionedText";
import { Frame } from "../Components/Frame";
import { TrainerLinkButton } from "../Components/TrainerLinkButton";
import { NamedImage } from "../Components/NamedImage";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import { LongTimeProcessing } from "../Components/LongTimeProcessing";
import { getMatchoImageSource } from "../Utilty";
import { GenreChips } from "../Components/GenreChips";
import { LabeledIconButton } from "../Atoms/LabeledIconButton";
import { MyPageMarker } from "../Atoms/MyPageMarker";
import { toTrainer, TrainerResponseModel } from "../Types/TrainerResponseModel";
import { AreaChips } from "../Components/AreaChips";
import { Youtube } from "../Types/YouTube";
import { InstagramGallery } from "../Components/InstagramGallery";
import { YouTubeGallery } from "../Components/YouTubeGallery";
import { Instagram } from "../Types/Instagram";
import { LineInformation } from "../Components/LineInformation";
import { FeeTable } from "../Components/FeeTable";
import {Helmet} from "react-helmet-async";

type Props = {};

export const TrainerView: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const [trainer, setTrainer] = useState<Trainer>(null!);
	const [instagrams, setInstagrams] = useState<Instagram[]>();
	const [youTubes, setYouTubes] = useState<Youtube[]>([]);
	const ref = useRef<{ refresh: () => void }>(null);

	type Params = {
		id?: string;
	};

	const params: Params = useParams<Params>();

	const getTrainer = useCallback(() => {
		maxios.get<Instagram[]>(`/Trainers/${params.id}/instagrams`).then((res) => {
			setInstagrams(res.data);
		});

		maxios.get<Youtube[]>(`/Trainers/${params.id}/youtubes`).then((res) => {
			setYouTubes(res.data);
		});

		return maxios.get<TrainerResponseModel>(`/Trainers/${params.id}`).then((res) => {
			if (!res.data) navigate("/notfound");
			setTrainer(toTrainer(res.data));
		});
	}, [params.id, navigate]);

	const handleClick = () => {
		navigate(`/trainers/me/edit`);
	};

	const handleClickGym = (id: string) => () => {
		navigate(`/gyms/${id}`);
	};

	const handleReviewPosted = () => {
		ref.current?.refresh();
	};

	return (
		<Frame>
			<LongTimeProcessing processing={getTrainer}>
				{trainer && (
					<>
						<Helmet>
							<title>{`${trainer.name} - MATCHO`}</title>
        					{trainer.selfIntroduction && <meta name="description" content={trainer.selfIntroduction} />}
						</Helmet>
						{authContext.claim?.matchoId === trainer.id && (
							<MyPageMarker>
								<LabeledIconButton label="編集" icon="edit" onClick={handleClick} />
							</MyPageMarker>
						)}
						<Headline
							matchoType={MatchoType.Trainer}
							matchoSubType={trainer.trainerType}
							image={trainer.image}
							name={trainer.name}
							rating={trainer.rating}
							numberOfComments={trainer.numberOfComment}
							facebook={trainer.facebook}
							twitter={trainer.twitter}
							instagram={trainer.instagram}
							website={trainer.website}
							youTube={trainer.youTube}
							line={trainer.line}
						/>
						<Divider sx={{ mt: 2, mb: 2 }} />
						{trainer.selfIntroduction && (
							<Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
								{trainer.selfIntroduction}
							</Typography>
						)}
						{trainer.gallery.length !== 0 && (
							<Gallery
								medias={trainer.gallery.map((image) => {
									return { data: getMatchoImageSource(image, 256), alt: undefined };
								})}
							/>
						)}
						{(trainer.selfIntroduction || trainer.gallery.length !== 0) && (
							<Divider sx={{ mt: 2, mb: 2 }} />
						)}
						{trainer.activityAreas.length !== 0 && (
							<CaptionedItem caption="エリア" dividerAtBottom>
								<AreaChips areas={trainer.activityAreas} size="small" />
							</CaptionedItem>
						)}
						{trainer.activityGenres.length !== 0 && (
							<CaptionedItem caption="ジャンル" dividerAtBottom>
								<GenreChips
									items={trainer.activityGenres.map((genre) => {
										return { value: genre.id, label: genre.name };
									})}
									size="small"
								/>
							</CaptionedItem>
						)}
						{(trainer.linkedGyms.length !== 0 ||
							authContext.claim?.type === MatchoType.GymOwner) && (
							<CaptionedItem caption="ジム" dividerAtBottom>
								<Box sx={{ display: "flex" }}>
									{trainer.linkedGyms.map((gym, index) => (
										<NamedImage
											name={gym.name}
											image={gym.image}
											handleClick={handleClickGym(gym.id)}
											key={index}
										/>
									))}
								</Box>
								{authContext.claim?.type === MatchoType.GymOwner && (
									<TrainerLinkButton trainerId={trainer.id} />
								)}
							</CaptionedItem>
						)}
						<CaptionedText caption="経歴" text={trainer.career} dividerAtBottom />
						<CaptionedText caption="資格" text={trainer.license} dividerAtBottom />
						<CaptionedText caption="サービス" text={trainer.service} dividerAtBottom />
						<CaptionedText caption="最寄り駅" text={trainer.station} dividerAtBottom />
						<Grid container spacing={1}>
							<Grid item xs={6}>
								<CaptionedItem caption="授業方法" dividerAtBottom={false}>
									<Box display="flex" sx={{ color: trainer.online ? undefined : "lightgray" }}>
										<DesktopWindowsIcon sx={{ mr: 1 }} />
										<Typography display="flex" alignItems="center" variant="body2">
											{trainer.online ? "オンライン可" : "オンライン不可"}
										</Typography>
									</Box>
									<Box display="flex" sx={{ color: trainer.onsite ? undefined : "lightgray" }}>
										<AirportShuttleIcon sx={{ mr: 1 }} />
										<Typography display="flex" alignItems="center" variant="body2">
											{trainer.onsite ? "出張可" : "出張不可"}
										</Typography>
									</Box>
								</CaptionedItem>
							</Grid>
							<Grid item xs={6}>
								<FeeTable
									feePerOnceLowerLimit={trainer.feePerOnceLowerLimit}
									feePerOnceUpperLimit={trainer.feePerOnceUpperLimit}
									feePerMonthLowerLimit={trainer.feePerMonthLowerLimit}
									feePerMonthUpperLimit={trainer.feePerMonthUpperLimit}
									trialFeeLowerLimit={trainer.trialFeeLowerLimit}
									trialFeeUpperLimit={trainer.trialFeeUpperLimit}
								/>
							</Grid>
						</Grid>
						<Divider />
						{instagrams && instagrams.length !== 0 && (
							<CaptionedItem caption="Instagram" dividerAtBottom>
								<InstagramGallery posts={instagrams} />
							</CaptionedItem>
						)}
						{youTubes && youTubes.length !== 0 && (
							<CaptionedItem caption="YouTube" dividerAtBottom>
								<YouTubeGallery posts={youTubes} />
							</CaptionedItem>
						)}
						{trainer.line && (
							<CaptionedItem caption="LINE" dividerAtBottom>
								<LineInformation lineId={trainer.line} />
							</CaptionedItem>
						)}
						<CaptionedItem caption="コメント" dividerAtBottom={false}>
							<ReviewViewer revieweeId={trainer.id} ref={ref} />
							<Box sx={{ textAlign: "center" }}>
								<ReviewDialog revieweeId={trainer.id} onPosted={handleReviewPosted} />
							</Box>
						</CaptionedItem>
					</>
				)}
			</LongTimeProcessing>
		</Frame>
	);
};
