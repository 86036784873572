import React from "react";
import { Frame } from "../Components/Frame";
import { NavigatorByAuthStatus } from "../Components/NavigatorByAuthStatus";
import TrainerForm from "../Components/TrainerForm";
import { MatchoType } from "../Types/MatchoType";

type Props = {};

export const TrainerEditor: React.FC<Props> = (props) => {
	return (
		<NavigatorByAuthStatus filter={MatchoType.Trainer}>
			<Frame>
				<TrainerForm mode={"edit"} />
			</Frame>
		</NavigatorByAuthStatus>
	);
};
