import { IconButton, Typography, Dialog, DialogContent, Box, Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { MatchoType } from "../Types/MatchoType";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { maxios } from "../MaxiosProvider";
import { TrainerGymLinks } from "../Types/TrainerGymLinks";
import { Content } from "../Types/Content";
import { Card } from "../Atoms/Card/Card";
import AreaSelector from "../Pages/AreaSelector";
import { Gym } from "../Types/Gym";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";
import { LongTimeProcessing } from "./LongTimeProcessing";
import { ApiResponse } from "../Types/ApiResponse";
import { ApiResponseStatus } from "../Types/ApiResponseStatus";

type Props = {
	gym: Gym;
	onClose: () => void;
};

export const MultipleTrainerLinkButton: React.FC<Props> = (props) => {
	const [open, setOpen] = useState(false);
	const [condition, setCondition] = useState<{ area: string | undefined; page: number }>({
		area: props.gym.area.id,
		page: 0,
	});
	const [visibleReadMore, setVisibleReadMore] = useState(true);
	const [links, setLinks] = useState<TrainerGymLinks>();
	const [trainers, setTrainers] = useState<Content[]>([]);

	const requestLink = (trainerId: string) => () => {
		return maxios
			.post<ApiResponse>(`/gymowners/me/gyms/${props.gym.id}/trainerlinks/${trainerId}/apply`)
			.then((res) => {
				switch (res.data.status) {
					case ApiResponseStatus.Success:
						return { isError: false };
					default:
						return { isError: true };
				}
			});
	};

	const getLinks = useCallback(() => {
		return maxios
			.get<TrainerGymLinks>(`/gymowners/me/gyms/${props.gym.id}/trainerlinks`)
			.then((res) => {
				setLinks(res.data);
			});
	}, [props.gym.id]);

	useEffect(() => {
		getLinks();
	}, [getLinks]);

	const getTrainers = useCallback(() => {
		if (!condition.area) {
			// エリアの指定がない場合サーバ側でエリア条件なしの検索となり全件取得されます。
			// そのため結果なしと扱い検索をスキップします。
			setTrainers([]);
			return;
		}

		return maxios
			.get<Content[]>(
				`/contents?ActitityAreaIds=${condition.area}&matchotype=${MatchoType.Trainer}&page=${condition.page}`
			)
			.then((res) => {
				if (res.data.length > 0) {
					setTrainers((previous) => previous.concat(res.data));
				} else {
					setVisibleReadMore(false);
				}
			});
	}, [condition]);

	let createTrainerElements = (trainers: Content[], links?: TrainerGymLinks) => {
		let trainerElement = (trainer: Content, key: number, requestable: boolean) => (
			<Card image={trainer.image} name={trainer.name} size="small" sx={{ mb: 1 }} key={key}>
				<LongTimeProcessingButton
					caption="リクエストを送る"
					onClick={requestLink(trainer.id)}
					callback={getLinks}
					variant="contained"
					disabled={!requestable}
					sx={{ mt: 1 }}
				/>
			</Card>
		);

		return trainers.map((trainer, index) => {
			let receivedlinkFromThisTrainer = links?.received.find(
				(link) => link.matcho.id === trainer.id
			);

			let sentlinkToThisTrainer = links?.sent.find((link) => link.matcho.id === trainer.id);

			if (receivedlinkFromThisTrainer || sentlinkToThisTrainer) {
				return trainerElement(trainer, index, false);
			} else {
				return trainerElement(trainer, index, true);
			}
		});
	};

	const openTrainerList = () => {
		setOpen(true);

		// 初期化
		setTrainers([]);
		setVisibleReadMore(true);
		setCondition({ ...condition, page: 0 });
	};

	const handleClose = () => {
		props.onClose();
		setOpen(false);
	};

	const handleChange = useCallback((areaId?: string) => {
		// 初期化
		setTrainers([]);
		setVisibleReadMore(true);
		setCondition({ area: areaId, page: 0 });
	}, []);

	const handleClickReadMore = () => {
		setCondition((previous) => {
			return { ...condition, page: previous.page! + 1 };
		});
	};

	return (
		<>
			<IconButton onClick={openTrainerList}>
				<AddLinkIcon sx={{ mr: 1 }} />
				<Typography variant="body2">リンクをリクエストする</Typography>
			</IconButton>
			<Dialog open={open} onClose={handleClose}>
				<DialogContent>
					<Box sx={{ mb: 2 }}>
						<AreaSelector areaId={condition.area} onChange={handleChange} />
					</Box>
					{createTrainerElements(trainers, links)}
					<LongTimeProcessing processing={getTrainers}>
						{visibleReadMore ? (
							<Button hidden={visibleReadMore} onClick={handleClickReadMore}>
								さらに読み込む
							</Button>
						) : trainers.length === 0 ? (
							<Typography variant="body2">このエリアにはトレーナーがいません</Typography>
						) : (
							<></>
						)}
					</LongTimeProcessing>
				</DialogContent>
			</Dialog>
		</>
	);
};
