import React from "react";
import { Frame } from "../Components/Frame";
import GymOwnerForm from "../Components/GymOwnerForm";
import { NavigatorByAuthStatus } from "../Components/NavigatorByAuthStatus";
import { MatchoType } from "../Types/MatchoType";

type Props = {};

export const GymOwnerEditor: React.FC<Props> = (props) => {
	return (
		<NavigatorByAuthStatus filter={MatchoType.GymOwner}>
			<Frame>
				<GymOwnerForm mode={"edit"} />
			</Frame>
		</NavigatorByAuthStatus>
	);
};
