import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import ImageSelector from "../../Atoms/ImageSelector";
import { Notifier } from "../../Types/Notifier";
import { Validator } from "../../Types/Validator";
import { FormItem } from "./FormItem";

type Props = {
	onSelected: (base64: string) => void;
	src?: string;
	required?: boolean;
	captionWidth?: number;
};

const FormImageSelectorRef: React.ForwardRefRenderFunction<Validator, Props> = (
	{ onSelected, src, required, captionWidth },
	ref
) => {
	const formItemRef = useRef<Notifier>(null);
	const isFirst = useRef(true);

	const _validate = useCallback(
		(doBlink: boolean) => {
			let valid = false;

			if (required && !src) {
				formItemRef.current?.setErrorMessage("必須です");
			} else if (!src) {
				formItemRef.current?.setErrorMessage("");
				valid = true;
			} else {
				formItemRef.current?.setErrorMessage("");
				valid = true;
			}

			if (doBlink && !valid) formItemRef.current?.blink();

			return valid;
		},
		[required, src]
	);

	// 親から呼ばれる関数
	useImperativeHandle(
		ref,
		() => ({
			validate() {
				return _validate(true);
			},
		}),
		[_validate]
	);

	useEffect(() => {
		if (isFirst.current) {
			isFirst.current = false;
		} else {
			_validate(false);
		}
	}, [_validate]);

	const handleSelect = (base64: string) => {
		onSelected(base64);
	};

	return (
		<FormItem label="プロフィール写真" ref={formItemRef} captionWidth={captionWidth ?? 100}>
			<ImageSelector onSelect={handleSelect} src={src} />
		</FormItem>
	);
};

export const FormImageSelector = forwardRef(FormImageSelectorRef);
