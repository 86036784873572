import { Box, Typography } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClickableCard } from "../Atoms/Card/ClickableCard";
import { Frame } from "../Components/Frame";
import { ApplicationStatus } from "../Types/ApplicationStatus";
import { Matcho } from "../Types/Matcho";
import { MatchoType } from "../Types/MatchoType";
import { TrainerGymLinks } from "../Types/TrainerGymLinks";
import { MAvatar } from "../Atoms/MAvatar";
import { NavigatorByAuthStatus } from "../Components/NavigatorByAuthStatus";

type Props = {};

type DisplayContent = Matcho & {
	numberOfCheckingRequest: number;
	linkedTrainers: Matcho[];
};

export const TrainerLinkPerGym: React.FC<Props> = (props) => {
	const navigate = useNavigate();

	const [displayContents, setDisplayContents] = useState<DisplayContent[]>();

	useEffect(() => {
		maxios.get<TrainerGymLinks[]>(`/gymOwners/me/trainerlinks`).then((res) => {
			setDisplayContents(res.data.map((links) => createDisplayContent(links)));
		});
	}, []);

	const createDisplayContent = (links: TrainerGymLinks) => {
		let numberOfCheckingRequest = links.received.filter(
			(l) => l.applicationStatus === ApplicationStatus.Checking
		).length;

		let linkedTrainers: Matcho[] = [];
		linkedTrainers = linkedTrainers.concat(
			links.received
				.filter((l) => l.applicationStatus === ApplicationStatus.Approved)
				.map((l) => l.matcho)
		);
		linkedTrainers = linkedTrainers.concat(
			links.sent
				.filter((l) => l.applicationStatus === ApplicationStatus.Approved)
				.map((l) => l.matcho)
		);

		let displayContent: DisplayContent = {
			...links.matcho,
			numberOfCheckingRequest,
			linkedTrainers,
		};

		return displayContent;
	};

	const handleClick = (gymId: string) => {
		navigate(`/gymowners/me/gyms/${gymId}/trainerlinks`);
	};

	return (
		<NavigatorByAuthStatus filter={MatchoType.GymOwner}>
			<Frame>
				{displayContents ? (
					displayContents.map((content, index) => {
						return (
							<ClickableCard
								handleClick={() => handleClick(content.id)}
								type={MatchoType.Gym}
								name={content.name}
								image={content.image}
								key={index}
							>
								<Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
									{content.numberOfCheckingRequest}件のリクエストが届いています。
								</Typography>
								<Box display="flex">
									{content.linkedTrainers.map((trainer, index) => {
										return <MAvatar src={trainer.image} key={index} />;
									})}
								</Box>
							</ClickableCard>
						);
					})
				) : (
					<></>
				)}
			</Frame>
		</NavigatorByAuthStatus>
	);
};
