import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import { getCastedValue } from "../Utilty";
import { Form } from "./Form/Form";
import { FormTextInput } from "./Form/FormTextInput";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";
import { Validator } from "../Types/Validator";

type Props = {
	onCompleted?: () => void;
};

const MatchoAssigner: React.FC<Props> = (props) => {
	const [matchoId, setMatchoId] = useState("");
	const ref = useRef<Validator>(null);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMatchoId(getCastedValue(event));
	};

	const register = () => {
		if (!ref.current?.validate()) {
			return (async () => {
				return { isError: true };
			})();
		}

		return maxios.put("/Users/me/matcho", {matchoId: matchoId}).then(() => {
			return { isError: false, message: "割り当てました。" };
		});
	};

	return (
		<Box sx={{ textAlign: "center" }}>
			<Form style={{ width: "100%" }} ref={ref}>
				<FormTextInput
					label="MATCHO ID"
					name="matchoId"
					value={matchoId}
					onChange={handleChange}
					sx={{ maxWidth: "20rem" }}
					minLength={3}
					maxLength={100}
					required
					captionWidth={100}
				/>
			</Form>
			<LongTimeProcessingButton
				caption="割り当て"
				onClick={register}
				callback={props.onCompleted}
				variant="contained"
				sx={{ mt: 1 }}
			/>
		</Box>
	);
};

export default MatchoAssigner;
