import { Box, ImageList, ImageListItem, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

type Props = {
	medias: {
		type?: "image" | "video";
		data?: string;
		alt?: string;
		text?: string;
		url?: string;
	}[];
};

export const Gallery: React.FC<Props> = (props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	const [size, setSize] = useState(0);

	const refFirstImage = useRef<HTMLImageElement>();
	const refFirstVideo = useRef<HTMLVideoElement>();

	const resize = (elm: HTMLElement | null) => {
		let width = elm?.clientWidth;
		if (width) setSize(width);
	};
	useEffect(() => {
		let timeoutId: NodeJS.Timeout;
		const resizeListener = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				if (refFirstImage.current) resize(refFirstImage.current);
				if (refFirstVideo.current) resize(refFirstVideo.current);
			}, 10);
		};
		window.addEventListener("resize", resizeListener);

		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, []);

	// const redirect = (url?: string) => () => {
	// 	window.open(url, "_blank");
	// };

	return (
		<>
			{props.medias.length !== 0 && (
				<ImageList cols={matches ? 3 : 2} variant="standard" gap={5}>
					{props.medias.map((media, index) =>
						!media.type || media.type === "image" ? (
							<ImageListItem key={index}>
								<img
									ref={(elm) => {
										// 画面サイズを変更しない限りリサイズイベントは発生しないため、
										// レンダー時にサイズを設定します。
										if (!elm || index !== 0) return;
										resize(elm);
										refFirstImage.current = elm;
									}}
									src={media.data}
									alt={media.alt}
									loading="lazy"
									style={{ height: size }}
									// onClick={redirect(media.url)}
								/>
							</ImageListItem>
						) : media.type === "video" ? (
							<Box overflow={"hidden"} width="100%" height={size} key={index}>
								<video
									ref={(elm) => {
										// 画面サイズを変更しない限りリサイズイベントは発生しないため、
										// レンダー時にサイズを設定します。
										if (!elm || index !== 0) return;
										resize(elm);
										refFirstVideo.current = elm;
									}}
									src={media.data}
									width="100%"
									autoPlay
									muted
									playsInline
								/>
							</Box>
						) : (
							<></>
						)
					)}
				</ImageList>
			)}
		</>
	);
};

Gallery.defaultProps = {
	medias: [],
};
