import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

type Props = {
	processing: () => Promise<void> | undefined;
};

export const LongTimeProcessing: React.FC<Props> = ({processing, children}) => {
	const [showProgress, setShowProgress] = useState(true);

	useEffect(() => {
		setShowProgress(true);

		let promise = processing && processing();
		if (promise) {
			promise.then(() => {
				setShowProgress(false);
			});
		} else {
			setShowProgress(false);
		}
	}, [processing]);

	return (
		<>
			{showProgress ? (
				<Box textAlign="center" m={2}>
					<CircularProgress color="inherit" />
				</Box>
			) : (
				children
			)}
		</>
	);
};
