import { Box, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BackIcon } from "../Atoms/BackIcon";
import { AuthContext } from "../AuthContext";
import MatchoStyle from "../Types/MatchoStyle";
import { MatchoType } from "../Types/MatchoType";
import { RegistrationStatus } from "../Types/RegistrationStatus";
import EmailAndPasswordRegistration from "../Components/EmailAndPasswordRegistration";
import EmailVerification from "../Components/EmailVerification";
import GymOwnerForm from "../Components/GymOwnerForm";
import Invitation from "../Components/Invitation";
import TraineeForm from "../Components/TraineeForm";
import TrainerForm from "../Components/TrainerForm";
import TypeSelector from "../Components/TypeSelector";
import UserRegistration from "../Components/UserRegistration";
import { Link } from "react-router-dom";
import { Header } from "../Components/Header";

type Props = {};

const steps = ["Email登録", "ユーザ情報入力", "マッチョ作成"];

const Registration: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	const [activeStep, setActiveStep] = useState(0);
	const [matchoType, setMatchoType] = useState<MatchoType | null>(null);
	const [invited, setInvited] = useState(false);

	useEffect(() => {
		switch (authContext.registrationStatus) {
			case RegistrationStatus.EmailUnverified:
				setActiveStep(1);
				break;
			case RegistrationStatus.MatchoUserUnregstered:
				setActiveStep(2);
				break;
			case RegistrationStatus.MatchoUnregstered:
				setActiveStep(3);
				break;
			case RegistrationStatus.Completed:
				setActiveStep(6);
				break;
		}
	}, [authContext.registrationStatus]);

	const forwardStep = (refresh: boolean) => () => {
		// Firebaseコンソールで直接ユーザを追加した場合でもメール検証が必要となります。
		// 既にMatchoデータは存在している場合、メール検証のみで終了したいので毎回認証状況のリフレッシュを行います。
		// （このシナリオは通常運用では発生しません。）
		// また、メールアドレス検証の結果を即時反映するために必要です。
		if (refresh) authContext.refresh();

		let nextStep = activeStep + 1;

		if (invited && nextStep === 4) nextStep++;

		setActiveStep(nextStep);
	};

	const backwardStep = () => {
		let previousStep = activeStep - 1;

		if (invited && previousStep === 4) previousStep--;

		setActiveStep(previousStep);
	};

	const setInvitedAndMatchoType = (invited: boolean, matchoType: MatchoType) => {
		setInvited(invited);
		setMatchoType(matchoType);
	};

	const handleSelectedTypeSelector = (matchoType: MatchoType) => {
		setMatchoType(matchoType);
		forwardStep(false)();
	};

	const convertStepForDisplay = (activeStep: number) => {
		if (activeStep <= 1) return 0;
		else if (activeStep <= 2) return 1;
		else if (activeStep <= 5) return 2;
		else return 3;
	};

	return (
		<>
			<Header hiddenSignInButton />
			<Box sx={{ maxWidth: MatchoStyle.size.contentMaxWidth, m: "50px auto", p: 1 }}>
				<Stepper activeStep={convertStepForDisplay(activeStep)}>
					{steps.map((label, index) => {
						return (
							<Step key={label} completed={convertStepForDisplay(activeStep) > index}>
								<StepLabel>{matches ? label : ""}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
				<Box sx={{ m: "50px auto" }}>
					{(!invited && activeStep === 4) || activeStep === 5 ? (
						<BackIcon onClick={backwardStep} />
					) : (
						<></>
					)}
					{
						{
							0: <EmailAndPasswordRegistration />,
							1: <EmailVerification onCompleted={forwardStep(true)} />,
							2: <UserRegistration onCompleted={forwardStep(true)} />,
							3: <Invitation onInvited={setInvitedAndMatchoType} onCompleted={forwardStep(true)} />,
							4: <TypeSelector onSelected={handleSelectedTypeSelector} />,
							5: (() => {
								switch (matchoType) {
									case MatchoType.GymOwner:
										return <GymOwnerForm mode="new" />;
									case MatchoType.Trainee:
										return <TraineeForm mode="new" />;
									case MatchoType.Trainer:
										return <TrainerForm mode="new" />;
									default:
										return <>マッチョタイプが指定されていません。</>;
								}
							})(),
							6: (
								<>
									<Typography variant="body2">
										登録は完了しています。
										<br />
										<Link to="/">トップ画面</Link>に移動してMATCHOをご利用ください！
										<br />
										<br />
										登録情報はマイページで確認できます。
									</Typography>
								</>
							),
						}[activeStep]
					}
				</Box>
			</Box>
		</>
	);
};

export default Registration;
