import { Box, Button } from "@mui/material";
import { maxios } from "../MaxiosProvider";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Content } from "../Types/Content";
import MatchoStyle from "../Types/MatchoStyle";
import { toQueryStringForApi, toSearchCondition } from "../Types/SearchCondition";
import { Header } from "../Components/Header";
import { SearchConditionView } from "./SearchConditionView";
import { SearchResultItem } from "../Components/SearchResultItem";
import { MatchoType } from "../Types/MatchoType";
import { LongTimeProcessing } from "../Components/LongTimeProcessing";

type Props = {};

export const SearchResult: React.FC<Props> = (props) => {
	const location = useLocation();
	const navigate = useNavigate();

	// クエリ文字列からオブジェクトに変換します。
	const [searchCondition, setSearchCondition] = useState(toSearchCondition(location.search));
	const [contents, setContents] = useState<Content[]>([]);
	const [visibleReadMore, setVisibleReadMore] = useState(true);
	const isFirst = useRef(true);
	const controllerRef = React.useRef<AbortController>();

	useEffect(() => {
		// ブラウザバックの連打で前画面の検索結果と重複して表示されることを回避します。
		// setContentsよりも前に中断する必要があると思われます。
		controllerRef.current?.abort();

		let searchCondition = toSearchCondition(location.search);
		setContents([]);
		setVisibleReadMore(true);
		setSearchCondition({ ...searchCondition, page: 0 });
	}, [location.search]);

	const search = useCallback(() => {
		// 初回は2重で実行されてしまう
		if (isFirst.current) {
			isFirst.current = false;
			return;
		}

		controllerRef.current = new AbortController();

		return maxios
			.get<Content[]>(`/contents?${toQueryStringForApi(searchCondition)}`, {
				signal: controllerRef.current.signal,
			})
			.then((res) => {
				// 中断した場合はundefinedになります。
				if (!res) return;

				if (res.data.length > 0) {
					setContents((c) => c.concat(res.data));
				} else {
					setVisibleReadMore(false);
				}
			});
	}, [searchCondition]);

	const handleClick = (content: Content) => {
		switch (content.type) {
			case MatchoType.Trainer:
				navigate(`/trainers/${content.id}`);
				break;
			case MatchoType.Gym:
				navigate(`/gyms/${content.id}`);
				break;
			default:
				break;
		}
	};

	const handleClickReadMore = () => {
		setSearchCondition((previous) => {
			return { ...searchCondition, page: previous.page! + 1 };
		});
	};

	return (
		<>
			<Header />
			<Box sx={{ maxWidth: MatchoStyle.size.contentMaxWidth, m: "50px auto", p: 1 }}>
				<SearchConditionView searchCondition={searchCondition} />
				{contents.map((content, index) => {
					return (
						<SearchResultItem
							sx={{ mb: 1 }}
							content={content}
							key={index}
							onClick={() => handleClick(content)}
						/>
					);
				})}
				<LongTimeProcessing processing={search}>
					{visibleReadMore && (
						<Button hidden={visibleReadMore} onClick={handleClickReadMore}>
							さらに読み込む
						</Button>
					)}
				</LongTimeProcessing>
			</Box>
		</>
	);
};
