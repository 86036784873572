import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { MatchoType } from "../Types/MatchoType";
import { NavigatorByAuthStatus } from "./NavigatorByAuthStatus";

type Props = {};

export const NavigateToGymOwnersMe: React.FC<Props> = (props) => {
	const authContext = useContext(AuthContext);
	
	return (
		<NavigatorByAuthStatus filter={MatchoType.GymOwner}>
			{authContext.claim && <Navigate to={`/gymowners/${authContext.claim.matchoId}`} />}
		</NavigatorByAuthStatus>
	);
};
