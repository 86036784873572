import React, { useEffect, useState } from "react";
import twitter from "../Resources/twitter.svg";
import line from "../Resources/line.svg";
import facebook from "../Resources/facebook.svg";
import youtube from "../Resources/youtube.svg";
import instagram from "../Resources/instagram.svg";
import website from "../Resources/website.svg";
import { isUri } from "../Utilty";
import { LineUrl } from "../Resources/Strings";

type Props = {
	size?: number;
	value?: string;
	sns: "twitter" | "line" | "facebook" | "youTube" | "instagram" | "website";
};

export const SnsIconButton: React.FC<Props> = (props) => {
	const [uri, setUri] = useState<string>();

	const getSvg = () => {
		switch (props.sns) {
			case "twitter":
				return twitter;
			case "line":
				return line;
			case "facebook":
				return facebook;
			case "youTube":
				return youtube;
			case "instagram":
				return instagram;
			case "website":
				return website;
		}
	};

	useEffect(() => {
		if (!props.value) {
			setUri(undefined);
			return;
		}

		let encoded = encodeURI(props.value);
		let uri: string;

		switch (props.sns) {
			case "twitter":
				uri = `https://twitter.com/${encoded}/`;
				break;
			case "line":
				uri = `${LineUrl}${encoded}`;
				break;
			case "facebook":
				uri = `https://www.facebook.com/${encoded}/`;
				break;
			case "youTube":
				uri = `https://www.youtube.com/channel/${encoded}/`;
				break;
			case "instagram":
				uri = `https://www.instagram.com/${encoded}/`;
				break;
			case "website":
				uri = encoded;
				break;
		}

		if (isUri(uri)) {
			setUri(uri);
		} else {
			setUri(undefined);
		}
	}, [props.sns, props.value]);

	return (
		<>
			{uri && (
				<a href={uri} target="_blank" rel="noopener noreferrer" style={{ margin: 5 }}>
					<img src={getSvg()} alt={props.sns} style={{ height: props.size, width: props.size }} />
				</a>
			)}
		</>
	);
};
