import { Typography } from "@mui/material";
import React from "react";
import MatchoStyle from "../Types/MatchoStyle";

type Props = {
	label: string;
	captionWidth?: number | string;
	bodyPadding?: number | string;
	border?: boolean;
	variant?: "dialog" | "page";
	invisibleCaption?: boolean;
};

export const TableItem: React.FC<Props> = (props) => {
	return (
		<tr>
			{!props.invisibleCaption && (
				<td
					style={{
						backgroundColor: props.variant === "dialog" ? "white" : MatchoStyle.color.matchoPink,
						color: props.variant === "dialog" ? "unset" : "white",
						padding: "10px",
						width: props.captionWidth,
					}}
				>
					<Typography variant="caption">{props.label}</Typography>
				</td>
			)}
			<td
				style={{
					backgroundColor: props.variant === "dialog" ? "white" : "#f5f5f5",
					padding: props.invisibleCaption ? "initial" : props.bodyPadding,
					borderTop: props.border ? "2px solid lightgrey" : "unset",
					borderRight: props.border ? "2px solid lightgrey" : "unset",
					borderBottom: props.border ? "2px solid lightgrey" : "unset",
				}}
			>
				{props.children}
			</td>
		</tr>
	);
};

TableItem.defaultProps = {
	bodyPadding: "10px",
	border: false,
};
