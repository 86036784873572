import { GymType } from "./GymType";
import { TrainerType } from "./TrainerType";

export enum MatchoType {
	Trainer,
	Trainee,
	Gym,
	GymOwner,
}

export const getMatchoTypeName = (
	matchoType: MatchoType,
	matchoSubType?: TrainerType | GymType
) => {
	switch (matchoType) {
		case MatchoType.Trainer:
			if (matchoSubType !== undefined) {
				switch (matchoSubType) {
					case TrainerType.Trainer:
						return "トレーナー";
					case TrainerType.Instructor:
						return "インストラクター";
					default:
						throw new Error("引数の組み合わせが不正です。");
				}
			}
			return "トレーナー／インストラクター";
		case MatchoType.Trainee:
			return "トレーニー";
		case MatchoType.Gym:
			if (matchoSubType !== undefined) {
				switch (matchoSubType) {
					case GymType.Gym:
						return "ジム";
					case GymType.Studio:
						return "スタジオ";
					default:
						throw new Error("引数の組み合わせが不正です。");
				}
			}
			return "ジム／スタジオ";
		case MatchoType.GymOwner:
			return "オーナー";
	}
};

export const getMatchoTypeByNumber = (number: number | string) => {
	if (typeof number === "string") number = parseInt(number);

	switch (number) {
		case 0:
			return MatchoType.Trainer;
		case 1:
			return MatchoType.Trainee;
		case 2:
			return MatchoType.Gym;
		case 3:
			return MatchoType.GymOwner;
	}
};
