import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { NumberFormatValues, NumericFormat, SourceInfo } from "react-number-format";

type Props = {
	lowerValue?: number;
	upperValue?: number;
	onChange?: (lowerValue: number | undefined, upperValue: number | undefined) => void;
	onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
};

export const Range: React.FC<Props> = (props) => {
	const [lowerValue, setLowerValue] = useState(props.lowerValue);
	const [upperValue, setUpperValue] = useState(props.upperValue);

	const handleChangeLowerValue = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
		let value = parseInt(values.value);
		setLowerValue(value);
		props.onChange && props.onChange(value, upperValue);
	};

	const handleChangeUpperValue = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
		let value = parseInt(values.value);
		setUpperValue(value);
		props.onChange && props.onChange(lowerValue, value);
	};

	const parseInt = (value: string) => {
		let parsedValue: number | undefined;

		if (value === undefined || value === null || value === "" || value === "-") {
			parsedValue = undefined;
		} else {
			if (isNaN(Number.parseInt(value))) {
				throw new TypeError("数字ではありません。");
			} else {
				parsedValue = Number.parseInt(value);
			}
		}

		return parsedValue;
	};

	return (
		<Box className="spaced-children horizonal">
			<NumericFormat
				value={lowerValue}
				suffix={"円"}
				allowLeadingZeros
				thousandSeparator=","
				decimalScale={0}
				onValueChange={handleChangeLowerValue}
				onBlur={props.onBlur}
				customInput={TextField}
				sx={{ backgroundColor: "white" }}
				isAllowed={(values) => {
					const { floatValue } = values;
					if (floatValue === undefined) return true;
					else return floatValue >= 0 && floatValue <= 999999;
				}}
			/>
			<Typography>～</Typography>
			<NumericFormat
				value={upperValue}
				suffix={"円"}
				allowLeadingZeros
				thousandSeparator=","
				decimalScale={0}
				onValueChange={handleChangeUpperValue}
				onBlur={props.onBlur}
				customInput={TextField}
				sx={{ backgroundColor: "white" }}
				isAllowed={(values) => {
					const { floatValue } = values;
					if (floatValue === undefined) return true;
					else return floatValue >= 0 && floatValue <= 999999;
				}}
			/>
		</Box>
	);
};
