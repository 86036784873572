import { Chip, Typography } from "@mui/material";
import React from "react";
import SelectorItem from "../Types/SelectorItem";

type Props = {
	item?: SelectorItem;
	onDelete?: (value: string) => void;
	textColor?: string;
	backgraoundColor?: string;
	variant?: "filled" | "outlined" | undefined;
};

export const DeletableChip: React.FC<Props> = (props) => {
	return (
		<>
			{props.item ? (
				<Chip
					label={<Typography variant="caption">{props.item.label}</Typography>}
					onDelete={(e) => {
						e.stopPropagation();
						if (props.onDelete) props.onDelete(props.item!.value);
					}}
					sx={{ color: props.textColor, backgroundColor: props.backgraoundColor, m: 0.5 }}
					variant={props.variant}
				/>
			) : (
				<></>
			)}
		</>
	);
};
