import { IconButton, Typography, Dialog, DialogContent, Box, Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { MatchoType } from "../Types/MatchoType";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { maxios } from "../MaxiosProvider";
import { TrainerGymLinks } from "../Types/TrainerGymLinks";
import { Content } from "../Types/Content";
import { Card } from "../Atoms/Card/Card";
import AreaSelector from "../Pages/AreaSelector";
import { Trainer } from "../Types/Trainer";
import { LongTimeProcessingButton } from "./LongTimeProcessingButton";
import { LongTimeProcessing } from "./LongTimeProcessing";

type Props = {
	trainer: Trainer;
	onClose: () => void;
};

export const MultipleGymLinkButton: React.FC<Props> = (props) => {
	const [open, setOpen] = useState(false);
	const [condition, setCondition] = useState({
		area: props.trainer.activityAreas.length > 0 ? props.trainer.activityAreas[0].id : undefined,
		page: 0,
	});
	const [visibleReadMore, setVisibleReadMore] = useState(true);
	const [links, setLinks] = useState<TrainerGymLinks>();
	const [gyms, setGyms] = useState<Content[]>([]);

	const requestLink = (gymId: string) => () => {
		return maxios.post(`/trainers/me/gymlinks/${gymId}/apply`).then(() => {
			return { isError: false };
		});
	};

	const getLinks = useCallback(() => {
		return maxios.get<TrainerGymLinks>(`/trainers/me/gymlinks`).then((res) => {
			setLinks(res.data);
		});
	}, []);

	useEffect(() => {
		getLinks();
	}, [getLinks]);

	const getGyms2 = useCallback(() => {
		if (!condition.area) {
			// エリアの指定がない場合サーバ側でエリア条件なしの検索となり全件取得されます。
			// そのため結果なしと扱い検索をスキップします。
			setGyms([]);
			return;
		}

		return maxios
			.get<Content[]>(
				`/contents?ActitityAreaIds=${condition.area}&matchotype=${
					MatchoType.Gym
				}&page=${condition.page}`
			)
			.then((res) => {
				if (res.data.length > 0) {
					setGyms((previous) => previous.concat(res.data));
				} else {
					setVisibleReadMore(false);
				}
			});
	}, [condition]);

	let createGymElements = (gyms: Content[], links?: TrainerGymLinks) => {
		let gymElement = (gym: Content, key: number, requestable: boolean) => (
			<Card image={gym.image} name={gym.name} size="small" key={key}>
				<LongTimeProcessingButton
					caption="リクエストを送る"
					onClick={requestLink(gym.id)}
					callback={getLinks}
					variant="contained"
					disabled={!requestable}
					sx={{ mt: 1 }}
				/>
			</Card>
		);

		return gyms.map((gym, index) => {
			let receivedlinkFromThisGym = links?.received.find((link) => link.matcho.id === gym.id);

			let sentlinkToThisGym = links?.sent.find((link) => link.matcho.id === gym.id);

			if (receivedlinkFromThisGym || sentlinkToThisGym) {
				return gymElement(gym, index, false);
			} else {
				return gymElement(gym, index, true);
			}
		});
	};

	const openGymList = () => {
		setOpen(true);

		// 初期化
		setGyms([]);
		setVisibleReadMore(true);
		setCondition({ ...condition, page: 0 });
	};

	const handleClose = () => {
		props.onClose();
		setOpen(false);
	};

	const handleChange = (areaId?: string) => {
		// 初期化
		setGyms([]);
		setVisibleReadMore(true);
		setCondition({ area: areaId, page: 0 });
	};

	const handleClickReadMore = () => {
		setCondition((previous) => {
			return { ...condition, page: previous.page! + 1 };
		});
	};

	return (
		<>
			<IconButton onClick={openGymList}>
				<AddLinkIcon sx={{ mr: 1 }} />
				<Typography variant="body2">リンクをリクエストする</Typography>
			</IconButton>
			<Dialog open={open} onClose={handleClose}>
				<DialogContent>
					<Box sx={{ mb: 2 }}>
						<AreaSelector areaId={condition.area} onChange={handleChange} />
					</Box>
					{createGymElements(gyms, links)}
					<LongTimeProcessing processing={getGyms2}>
						{visibleReadMore ? (
							<Button hidden={visibleReadMore} onClick={handleClickReadMore}>
								さらに読み込む
							</Button>
						) : gyms.length === 0 ? (
							<Typography variant="body2">このエリアにはジムがありません</Typography>
						) : (
							<></>
						)}
					</LongTimeProcessing>
				</DialogContent>
			</Dialog>
		</>
	);
};
