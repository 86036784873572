import Area from "./Area";
import GenreGetResponseModel from "./GenreGetResponseModel";
import { Gym } from "./Gym";
import { GymRegistrationModel } from "./GymRegistrationModel";
import { GymStaff } from "./GymStaff";
import { GymType } from "./GymType";
import { Matcho } from "./Matcho";

export type GymResponseModel = {
	id: string;
	ownerId: string;
	gymType: GymType,
	name: string;
	image: string | null;
	area: Area;
	town: string;
	phoneNumber: string | null;
	introduction: string | null;
	latitude: number;
	longitude: number;
	genres: GenreGetResponseModel[];
	gallery: string[];
	service: string | null;
	online: boolean;
	onsite: boolean;
	feePerOnceLowerLimit: number | null;
	feePerOnceUpperLimit: number | null;
	feePerMonthLowerLimit: number | null;
	feePerMonthUpperLimit: number | null;
	trialFeeLowerLimit: number | null;
	trialFeeUpperLimit: number | null;
	station: string | null;
	businessHours: string | null;
	facebook: string | null;
	twitter: string | null;
	instagram: string | null;
	website: string | null;
	youTube: string | null;
	line: string | null;
	rating: number;
	numberOfComment: number;
	linkedTrainers: Matcho[];
	staffs: GymStaff[];
};

export const toGymRegistrationModel = (responseModel: GymResponseModel) => {
	let result: GymRegistrationModel;

	result = {
		id: responseModel.id,
		ownerId: responseModel.ownerId,
		gymType: responseModel.gymType,
		name: responseModel.name,
		image: responseModel.image ?? undefined,
		areaId: responseModel.area.id,
		town: responseModel.town,
		phoneNumber: responseModel.phoneNumber ?? "",
		introduction: responseModel.introduction ?? "",
		genreIds: responseModel.genres.map((g) => g.id),
		service: responseModel.service ?? "",
		online: responseModel.online,
		onsite: responseModel.onsite,
		feePerOnceLowerLimit: responseModel.feePerOnceLowerLimit ?? undefined,
		feePerOnceUpperLimit: responseModel.feePerOnceUpperLimit ?? undefined,
		feePerMonthLowerLimit: responseModel.feePerMonthLowerLimit ?? undefined,
		feePerMonthUpperLimit: responseModel.feePerMonthUpperLimit ?? undefined,
		trialFeeLowerLimit: responseModel.trialFeeLowerLimit ?? undefined,
		trialFeeUpperLimit: responseModel.trialFeeUpperLimit ?? undefined,
		station: responseModel.station ?? "",
		businessHours: responseModel.businessHours ?? "",
		facebook: responseModel.facebook ?? "",
		twitter: responseModel.twitter ?? "",
		instagram: responseModel.instagram ?? "",
		website: responseModel.website ?? "",
		youTube: responseModel.youTube ?? "",
		line: responseModel.line ?? "",
		gallery: responseModel.gallery,
		staffs: responseModel.staffs,
	};

	return result;
};

export const toGym = (responseModel: GymResponseModel) => {
	let result: Gym;

	result = {
		id: responseModel.id,
		ownerId: responseModel.ownerId,
		gymType: responseModel.gymType,
		name: responseModel.name,
		image: responseModel.image ?? undefined,
		area: responseModel.area,
		town: responseModel.town,
		phoneNumber: responseModel.phoneNumber ?? "",
		introduction: responseModel.introduction ?? "",
		latitude: responseModel.latitude,
		longitude: responseModel.longitude,
		genres: responseModel.genres,
		service: responseModel.service ?? "",
		online: responseModel.online,
		onsite: responseModel.onsite,
		feePerOnceLowerLimit: responseModel.feePerOnceLowerLimit ?? undefined,
		feePerOnceUpperLimit: responseModel.feePerOnceUpperLimit ?? undefined,
		feePerMonthLowerLimit: responseModel.feePerMonthLowerLimit ?? undefined,
		feePerMonthUpperLimit: responseModel.feePerMonthUpperLimit ?? undefined,
		trialFeeLowerLimit: responseModel.trialFeeLowerLimit ?? undefined,
		trialFeeUpperLimit: responseModel.trialFeeUpperLimit ?? undefined,
		station: responseModel.station ?? "",
		businessHours: responseModel.businessHours ?? "",
		facebook: responseModel.facebook ?? "",
		twitter: responseModel.twitter ?? "",
		instagram: responseModel.instagram ?? "",
		website: responseModel.website ?? "",
		youTube: responseModel.youTube ?? "",
		line: responseModel.line ?? "",
		gallery: responseModel.gallery,
		rating: responseModel.rating,
		numberOfComment: responseModel.numberOfComment,
		linkedTrainers: responseModel.linkedTrainers,
		staffs: responseModel.staffs,
	};

	return result;
};
