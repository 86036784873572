import React, {
	createRef,
	forwardRef,
	PropsWithChildren,
	ReactElement,
	ReactNode,
	useImperativeHandle,
	useRef,
} from "react";
import { Validator } from "../../Types/Validator";

type Props = {
	style?: React.CSSProperties;
	onBlur?: () => void;
	children: ReactNode;
};

const FormRef: React.ForwardRefRenderFunction<Validator, Props> = (
	{ style, onBlur, children },
	ref
) => {
	// refを動的に生成
	const refs = useRef(
		React.Children.map(children, () => {
			return createRef<Validator>();
		})
	);

	// 親から呼ばれる関数
	useImperativeHandle(ref, () => ({
		validate() {
			if (!refs.current) return true;

			let errors = refs.current.filter((ref) => !ref.current?.validate());

			if (errors.length > 0) {
				return false;
			} else {
				return true;
			}
		},
	}));

	return (
		<table style={{ ...style, textAlign: "left" }} onBlur={onBlur}>
			<tbody>
				{React.Children.map(children, (child, index) => {
					// 子要素を型付け
					let item = child as ReactElement<
						PropsWithChildren<{
							ref: React.RefObject<{
								validate: () => boolean;
							}>;
						}>
					>;

					// 子要素のpropsを追加（ここではref）
					return React.cloneElement(item, { ref: refs.current![index] });
				})}
			</tbody>
		</table>
	);
};

export const Form = forwardRef(FormRef);
