import { createSearchParams } from "react-router-dom";
import { getMatchoTypeByNumber, MatchoType } from "./MatchoType";

type SearchCondition = {
	areaIds?: string[];
	genreIds?: string[];
	type?: MatchoType;
	searchWord?: string;
	page?: number;
	feePerOnceLowerLimit?: number;
	feePerOnceUpperLimit?: number;
	feePerMonthLowerLimit?: number;
	feePerMonthUpperLimit?: number;
	trialFeeLowerLimit?: number;
	trialFeeUpperLimit?: number;
	online?: boolean;
	onsite?: boolean;
};

export const toQueryString = (searchCondition: SearchCondition) => {
	let query: any = {};
	if (searchCondition.areaIds) query["areaIds"] = searchCondition.areaIds;
	if (searchCondition.genreIds) query["genreIds"] = searchCondition.genreIds;
	if (searchCondition.page) query["page"] = searchCondition.page;
	if (searchCondition.searchWord) query["searchWord"] = searchCondition.searchWord;
	if (searchCondition.type !== undefined) query["type"] = searchCondition.type.toString();
	if (searchCondition.feePerOnceLowerLimit !== undefined)
		query["feePerOnceLowerLimit"] = searchCondition.feePerOnceLowerLimit.toString();
	if (searchCondition.feePerOnceUpperLimit !== undefined)
		query["feePerOnceUpperLimit"] = searchCondition.feePerOnceUpperLimit.toString();
	if (searchCondition.feePerMonthLowerLimit !== undefined)
		query["feePerMonthLowerLimit"] = searchCondition.feePerMonthLowerLimit.toString();
	if (searchCondition.feePerMonthUpperLimit !== undefined)
		query["feePerMonthUpperLimit"] = searchCondition.feePerMonthUpperLimit.toString();
	if (searchCondition.trialFeeLowerLimit !== undefined)
		query["trialFeeLowerLimit"] = searchCondition.trialFeeLowerLimit.toString();
	if (searchCondition.trialFeeUpperLimit !== undefined)
		query["trialFeeUpperLimit"] = searchCondition.trialFeeUpperLimit.toString();
	if (searchCondition.online !== undefined) query["online"] = searchCondition.online.toString();
	if (searchCondition.onsite !== undefined) query["onsite"] = searchCondition.onsite.toString();

	return createSearchParams(query).toString();
};

export const toQueryStringForApi = (searchCondition: SearchCondition) => {
	let query: any = {};
	if (searchCondition.areaIds) query["ActitityAreaIds"] = searchCondition.areaIds;
	if (searchCondition.genreIds) query["ActitityGenreIds"] = searchCondition.genreIds;
	if (searchCondition.page) query["page"] = searchCondition.page;
	if (searchCondition.searchWord) query["searchWord"] = searchCondition.searchWord;
	if (searchCondition.type !== undefined) query["matchotype"] = searchCondition.type;
	if (searchCondition.feePerOnceLowerLimit !== undefined)
		query["feePerOnceLowerLimit"] = searchCondition.feePerOnceLowerLimit;
	if (searchCondition.feePerOnceUpperLimit !== undefined)
		query["feePerOnceUpperLimit"] = searchCondition.feePerOnceUpperLimit;
	if (searchCondition.feePerMonthLowerLimit !== undefined)
		query["feePerMonthLowerLimit"] = searchCondition.feePerMonthLowerLimit;
	if (searchCondition.feePerMonthUpperLimit !== undefined)
		query["feePerMonthUpperLimit"] = searchCondition.feePerMonthUpperLimit;
	if (searchCondition.trialFeeLowerLimit !== undefined)
		query["trialFeeLowerLimit"] = searchCondition.trialFeeLowerLimit;
	if (searchCondition.trialFeeUpperLimit !== undefined)
		query["trialFeeUpperLimit"] = searchCondition.trialFeeUpperLimit;
	if (searchCondition.online !== undefined) query["online"] = searchCondition.online;
	if (searchCondition.onsite !== undefined) query["onsite"] = searchCondition.onsite;

	return createSearchParams(query).toString();
};

export const toSearchCondition = (queryString: string) => {
	let query = new URLSearchParams(queryString);
	let result: SearchCondition = {};

	let areaIdOrNull = query.getAll("areaIds");
	let searchWordOrNull = query.get("searchWord");
	let genreIdsOrNull = query.getAll("genreIds");
	let pageOrNull = query.get("page");
	let typeOrNull = query.get("type");
	let feePerOnceLowerLimitOrNull = query.get("feePerOnceLowerLimit");
	let feePerOnceUpperLimitOrNull = query.get("feePerOnceUpperLimit");
	let feePerMonthLowerLimitOrNull = query.get("feePerMonthLowerLimit");
	let feePerMonthUpperLimitOrNull = query.get("feePerMonthUpperLimit");
	let trialFeeLowerLimitOrNull = query.get("trialFeeLowerLimit");
	let trialFeeUpperLimitOrNull = query.get("trialFeeUpperLimit");
	let onlineOrNull = query.get("online");
	let onsiteOrNull = query.get("onsite");

	result.areaIds = areaIdOrNull ?? undefined;
	result.searchWord = searchWordOrNull ?? undefined;
	result.genreIds = genreIdsOrNull;
	result.page = pageOrNull ? parseInt(pageOrNull) : 0;
	result.type = typeOrNull !== null ? getMatchoTypeByNumber(typeOrNull) : undefined;
	result.feePerOnceLowerLimit = feePerOnceLowerLimitOrNull
		? parseInt(feePerOnceLowerLimitOrNull)
		: undefined;
	result.feePerOnceUpperLimit = feePerOnceUpperLimitOrNull
		? parseInt(feePerOnceUpperLimitOrNull)
		: undefined;
	result.feePerMonthLowerLimit = feePerMonthLowerLimitOrNull
		? parseInt(feePerMonthLowerLimitOrNull)
		: undefined;
	result.feePerMonthUpperLimit = feePerMonthUpperLimitOrNull
		? parseInt(feePerMonthUpperLimitOrNull)
		: undefined;
	result.trialFeeLowerLimit = trialFeeLowerLimitOrNull
		? parseInt(trialFeeLowerLimitOrNull)
		: undefined;
	result.trialFeeUpperLimit = trialFeeUpperLimitOrNull
		? parseInt(trialFeeUpperLimitOrNull)
		: undefined;

	// 条件がtrueの場合のみ検索条件をセットします
	try {
		let online = onlineOrNull && JSON.parse(onlineOrNull.toLowerCase());
		result.online = online === true ? true : undefined;
	} catch {
		result.online = undefined;
	}

	// 条件がtrueの場合のみ検索条件をセットします
	try {
		let onsite = onsiteOrNull && JSON.parse(onsiteOrNull.toLowerCase());
		result.onsite = onsite === true ? true : undefined;
	} catch {
		result.onsite = undefined;
	}

	return result;
};

export default SearchCondition;
